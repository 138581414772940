import { React, useState } from "react";
import classes from "./nav_add_good.module.css";
import { Form, Row, Col, Button, Modal, InputGroup, FormControl, Table } from "react-bootstrap";

import PlusIcon from "../../images/plus-icon.svg";
import PriceSellIcon from "../../images/sell-product.svg";
import EditIcon from "../../images/edit.svg";
import CheckedIcon from "../../images/checked.svg";
import CancelIcon from "../../images/cancel.svg";
import SearchIcon from "../../images/search-icon.svg";
import FolderIcon from "../../images/folder-icon.svg";
import BackIcon from "../../images/back-icon.svg";
import { DISPLAY_MODAL, HIDE_MODAL } from "../../actions/checks";
import { useAddGoods } from "./hooks/add_goods_hooks";
import Loading from "../Loading";
import { formatDate, formatMoney } from "../../helpers/dataFormatter";

function NavAddGood(props) {
  const {
    handleAddProductToCheck,
    handleCategory,
    modalShow,
    setModalShow,
    productsList,
    showLoader,
    hideAnyModal,
    dispatch,
    trigSearch,
    delay,
    setSearchByBarcode,
    noTreeView,
    setNoTreeView,
    changePrice
  } = useAddGoods();

  const [editRowPrice, setEditRowPrice] = useState({ id: null, price: 0 });

  const handlePriceChange = (e, productId) => {
    e.preventDefault();
    console.log('handlePriceChange', e);
    //setProductCount(e.target.value);
    setEditRowPrice({ id: productId, price: e.target.value });
  };

  return (
    <div>
      <Button
        className={`me-md-2 ${classes.button}`}
        size="sm"
        onClick={(e) => {
          e.target.parentElement.focus();
          e.target.parentElement.blur();
          setModalShow(true);
          dispatch({ type: DISPLAY_MODAL });
        }}
      >
        <img className={classes.nav_icon} src={props.priceEdit ? PriceSellIcon : PlusIcon} alt="add good" />
      </Button>
      <Modal
        show={modalShow}
        onHide={() => {
          dispatch({ type: HIDE_MODAL });
          setModalShow(false);
        }}
        size="lg"
        aria-labelledby="good-modal-btn"
        centered
        scrollable={true}
        contentClassName={classes.modal_content}
      >
        <Modal.Header closeButton>
          <InputGroup>
            <FormControl
              placeholder="Пошук товару"
              onChange={delay(trigSearch, 500)}
              aria-describedby="good-modal-btn"
            />
            <Button variant="outline-secondary">
              <img
                className={classes.search_icon}
                src={SearchIcon}
                alt="search"
              />
            </Button>
          </InputGroup>
        </Modal.Header>
        <Modal.Body>
          {/* <Loading /> uncommit  */}
          {console.log("show Loader ", showLoader)}
          {console.log("show Loader products", productsList)}
          {showLoader === false &&
            Object.keys(productsList.currentCategory).length > 0 && (
              <div
                className={classes.back}
                onClick={(e) => handleCategory(productsList.parentCategory.id)}
                key={productsList.parentCategory.id}
              >
                <img className={classes.back_icon} src={BackIcon} alt="" />{" "}
                {productsList.currentCategory.name}
              </div>
            )}

          <ul className={classes.category_list}>
            {showLoader === false &&
              Object.keys(productsList).length > 0 &&
              Object.keys(productsList.categories).map((key) => {
                const categoryCur = productsList.categories[key];
                return (
                  <li
                    className={classes.category}
                    onClick={(e) => handleCategory(categoryCur.id)}
                    key={productsList.parentCategory.id}
                  >
                    <img
                      className={classes.category_icon}
                      src={FolderIcon}
                      alt=""
                    />
                    {categoryCur.name}
                  </li>
                );
              })}
          </ul>

          <Table
            // className={
            //   showLoader === false &&
            //     Object.keys(productsList.categories).length == 0
            //     ? ""
            //     : "d-none"
            // }

            hover
          >
            <thead className={classes.table_head}>
              <tr>
                <th>Код</th>
                <th>Назва</th>
                <th className="text-end">Ціна, грн.</th>
              </tr>
            </thead>
            <tbody className={classes.table_body}>
              {showLoader === false &&
                Object.keys(productsList).length > 0 &&
                Object.keys(productsList.products).map((key) => {
                  const productCur = productsList.products[key];
                  return (
                    <tr
                      key={productCur.id}
                      onClick={(e) => {
                        if (!props.priceEdit) { handleAddProductToCheck(productCur.id) }
                      }
                      }
                      className={classes.tr}
                    >
                      <td className="col-1">{productCur.weightCode}</td>
                      <td>{productCur.name}</td>
                      {props.priceEdit && (
                        <td className="col-5 text-end">
                          <Row className='justify-content-center align-items-center' key={productCur.id}>
                            <Col className='text-end'>
                              {editRowPrice.id !== productCur.id &&
                                <Button
                                  variant="outline-secondary"
                                  onClick={() => {
                                    setEditRowPrice({ id: productCur.id, price: productCur.price });
                                    const editElement = document.getElementById(`edit_${productCur.id}`)
                                    editElement.focus();
                                  }}
                                >
                                  <img
                                    className={classes.search_icon}
                                    src={EditIcon}
                                    alt="edit"
                                  />
                                </Button>}
                              {editRowPrice.id === productCur.id &&
                                <Button
                                  variant="outline-secondary"
                                  onClick={(e) => {
                                    setEditRowPrice({ id: null, price: null });
                                    const editElement = document.getElementById(`edit_${productCur.id}`)
                                    editElement.blur();
                                    editElement.parentElement.focus();
                                    editElement.parentElement.blur();
                                  }}
                                >
                                  <img
                                    className={classes.search_icon}
                                    src={CancelIcon}
                                    alt="cancel"
                                  />
                                </Button>}
                            </Col>
                            <Form as={Col} key={productCur.id}>
                              <Form.Group>
                                <Form.Control
                                  // ref={countRef}
                                  id={`edit_${productCur.id}`}
                                  className='text-center'
                                  type='text'
                                  onChange={(e) => handlePriceChange(e, productCur.id)}
                                  value={editRowPrice.id === productCur.id ? editRowPrice.price : productCur.price}
                                  inputMode='numeric'
                                  autoComplete='off'
                                  onFocus={(e) => {
                                    e.target.select();
                                  }}
                                  readOnly={editRowPrice.id !== productCur.id}
                                // onKeyDown={(e) => {
                                //   if (e.key === 'Enter') {
                                //     e.preventDefault();
                                //     onSubmit();
                                //   }
                                // }}
                                //readOnly={true}
                                />
                              </Form.Group>
                            </Form>
                            <Col className='text-start col-3'>
                              <Button
                                variant="outline-secondary"
                                disabled={editRowPrice.id !== productCur.id}
                                onClick={(e) => {
                                  changePrice(productCur.id, editRowPrice.price, () => {
                                    setEditRowPrice({ id: null, price: null });
                                    handleCategory(productsList.currentCategory.id, null, null, null, false);
                                  })
                                }}>
                                <img
                                  className={classes.search_icon}
                                  src={CheckedIcon}
                                  alt="confirm"
                                />
                              </Button>
                            </Col>
                          </Row>
                          {/* {formatMoney(productCur.price)} */}
                        </td>)
                      }
                      {!props.priceEdit && (
                        <td className="col-1 text-end">
                          {formatMoney(productCur.price)}
                        </td>)}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {showLoader === false &&
            Object.keys(productsList.currentCategory).length > 0 && (
              <div
                className={classes.back}
                onClick={(e) => handleCategory(productsList.parentCategory.id)}
              >
                <img className={classes.back_icon} src={BackIcon} alt="" />{" "}
                {productsList.currentCategory.name}
              </div>
            )}
          {/* <ul className={classes.product_list}>
            {showLoader === false &&
              Object.keys(productsList).length > 0 &&
              Object.keys(productsList.products).map((key) => {
                const productCur = productsList.products[key];
                return (
                  <li
                    onClick={(e) => handleAddProductToCheck(productCur.barcode)}
                  >
                    {productCur.name} - {productCur.price}
                  </li>
                );
              })}
          </ul> */}
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default NavAddGood;
