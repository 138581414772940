import { config } from '../config';
import { store } from '../App';

export const handleResponse = async (response) => {
  const text = await response.text();
  const data = text && JSON.parse(text);
  console.log('handle st', response.ok);
  console.log('handle response', response);
  if (!response.ok) {
    throw Error(data ? data.message : data);
  }
  //  if (response.status === 401) {
  //       dispatcher(logout());
  //       window.location.reload();
  //     }
  //     const error = (data && data.message) || response.statusText;
  //     console.log('errror 14', error);
  //   }
  return data;
};

/**
 *
 * @param url
 * @return data
 */
export const fetcherAsync = async (url, method, body = {}, returnRaw = false) => {
  const hasBody = Object.keys(body).length;
  const user = store.getState().users.user;
  const headers =
    method == 'POST' || method == 'DELETE'
      ? {
        Authorization: 'Bearer ' + user.token,
        //'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
      }
      : {
        Authorization: 'Bearer ' + user.token,
        'Content-Type': 'application/json',
      };
  console.log('headers ', headers);
  const requestOptions = {
    method: method,
    headers: headers,
    //body: hasBody ? formData : null,
    //body: hasBody ? JSON.stringify(reqData) : null,
    body: hasBody ? JSON.stringify(body) : null,
  };
  let resData;
  try {
    resData = await fetch(config.apiUrl + url, requestOptions);
  } catch (error) { }
  //resData;
  //   if re
  //   return fetch(config.apiUrl + url, requestOptions).then((response) =>
  //     handleResponse(response, store.dispatch)
  //   );
  //console.log('pdf_data_start', resData);
  if (returnRaw) {
    return resData;
  }
  return await handleResponse(resData);
};
