import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button, Modal, Table, Form, Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import { useFRsControls } from '../../modals/hooks/useFRsControls';
import { FiArrowLeftCircle, FiSave } from 'react-icons/fi';
// import ModalCashInOut from '../../modals/modal_cash_in_out';
import ModalCashInOut from '../../modals/modal_cash_in_out_keyboard';
import { formatDate, formatMoney } from '../../../helpers/dataFormatter';

const FiscalRegistrarElem = ({ elemEditId, setElemEditId }) => {
    const [shiftData, isLoading, getShiftData, openShift, closeShift, xReport, frData, getFrData, createUpdateFrData] = useFRsControls(null);
    const [modalFrInOut, setModalFrInOut] = useState({
        isActive: false,
        type: '',
    });
    const [newFrData, setNewFrData] = useState({});
    const [detailsFrData, setDetailsFrData] = useState(null);

    useEffect(() => {
        getFrData(elemEditId);
    }, []);

    useEffect(() => {
        if (!modalFrInOut.isActive) { setTimeout(() => { getFrData(elemEditId) }, 500); }
    }, [modalFrInOut]);

    console.log('frData', frData);

    const saveDataHandler = async () => {
        if (Object.keys(newFrData).length > 0) {
            const updResult = createUpdateFrData(newFrData);
            if (updResult.is_error) {
                setNewFrData({})
            } else {

            }
        }
    }

    useEffect(()=> {
        console.log("asfhsdhfdjs");
        if (shiftData?.shift?.fiscalRegistrars?.length) {
            console.log("asfhsdhfdjs2", elemEditId);
            console.log("asfhsdhfdjs2fr", shiftData.shift.fiscalRegistrars);
            console.log("asfhsdhfdjs2fr0", shiftData.shift.fiscalRegistrars[0]);
            const curElem = shiftData.shift.fiscalRegistrars.find((elem)=> elem.id == elemEditId);
            console.log("asfhsdhfdjs2_curElem", curElem);
            if (curElem) {
                setDetailsFrData(curElem);
            }
        }
    }, [shiftData]);

    console.log('asfhsdhfdjs_detailsFrData', detailsFrData);
   
    return (
        <Fragment>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand onClick={() => { setElemEditId(null) }}><FiArrowLeftCircle size={24} /></Navbar.Brand>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {/* <Nav.Link href=""><FiSave size={24} /></Nav.Link> */}
                            <Nav.Link
                                onClick={saveDataHandler}
                                disabled={Object.keys(newFrData).length === 0} ><FiSave size={24} />
                            </Nav.Link>
                            {/* <Nav.Link href="#link">/Nav.Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {frData &&
                <Container>
                    <Row>
                        <Col>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Name"
                                        defaultValue={frData.name}
                                        readOnly={true}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicType">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Type"
                                        defaultValue={frData.frType}
                                        readOnly={true}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicModel">
                                    <Form.Label>Model</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Model"
                                        defaultValue={frData.frModel}
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicIP">
                                <Form.Label>IP</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="IP"
                                    defaultValue={frData.ip}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            setNewFrData({ ...newFrData, id: elemEditId, ip: e.target.value, name: frData.name });
                                        }
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPort">
                                <Form.Label>Port</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Port"
                                    defaultValue={frData.port}
                                    readOnly={true}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicCurrentCash">
                                <Form.Label>Сума готівки, грн</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Сума готівки"
                                    // defaultValue={formatMoney(frData.currentCash)}
                                    value={formatMoney(frData.currentCash)}
                                    readOnly={true}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            {(detailsFrData?.frData?.cash_shift_info) && (
                               <Form.Group className="mb-3" controlId="formBasicCurrentCash">
                                    <Form.Label>Сума в прро, грн</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Сума прро"
                                        // defaultValue={formatMoney(frData.currentCash)}
                                        value={formatMoney(detailsFrData.frData.cash_shift_info.cash_balance / 100)}
                                        readOnly={true}
                                    />
                                </Form.Group> 
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="outline-secondary"
                                onClick={(e) => {
                                    e.target.blur();
                                    setModalFrInOut({
                                        isActive: true,
                                        type: 'IN',
                                    });
                                }}>Внесення</Button>{' '}{' '}
                            <Button variant="outline-secondary"
                                onClick={(e) => {
                                    e.target.blur();
                                    setModalFrInOut({
                                        isActive: true,
                                        type: 'OUT',
                                    });
                                }}>Вилучення</Button>{' '}{' - коригування данних'} {detailsFrData?.frData?.cash_shift_info ? '(' +  formatMoney(detailsFrData.frData.cash_shift_info.cash_balance / 100 - frData.currentCash) + ')' : formatMoney(0)  }
                        </ Col>
                        <Col>
                        </Col>
                    </Row>
                </Container>
            }
            {modalFrInOut.isActive && (
                <ModalCashInOut
                    shiftData={{ shift: { fiscalRegistrars: [frData], currentCash: frData ? frData.currentCash : 0 } }}
                    opType={modalFrInOut.type}
                    correctSum={true}
                    closeModal={() => {
                        setModalFrInOut({
                            isActive: false,
                            type: '',
                        });
                    }}
                />
            )}
        </Fragment>
    );
}

export default FiscalRegistrarElem;