import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ModalClearProducts(props) {
  return (
    <>
      <Modal {...props}>
        <Modal.Body>Очистити товари в чеку?</Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={props.onConfirm}>
            Так
          </Button>
          <Button variant='outline-secondary' onClick={props.onCancel}>
            Ні
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalClearProducts;
