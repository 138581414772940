import { React, useRef, useState, useEffect } from 'react';
import classes from './nav_add_good_by_barcode.module.css';
import { Button, Modal, InputGroup, FormControl, Table, Container, Row, Col } from 'react-bootstrap';

import PlusIcon from '../../images/barcode-icon.svg';
import SearchIcon from '../../images/search-icon.svg';
import FolderIcon from '../../images/folder-icon.svg';
import BackIcon from '../../images/back-icon.svg';
import { DISPLAY_MODAL, HIDE_MODAL } from '../../actions/checks';
import { useAddGoods } from './hooks/add_goods_hooks';
import Loading from '../Loading';
import { formatDate, formatMoney } from '../../helpers/dataFormatter';
import NumericKeyboard from '../ui/NumericKeyboard';

function NavAddGoodByBarcodeKeyboard() {
  const {
    handleAddProductToCheck,
    handleCategory,
    modalShow,
    setModalShow,
    productsList,
    showLoader,
    hideAnyModal,
    dispatch,
    trigSearch,
    delay,
    setSearchByBarcode,
  } = useAddGoods();
  const [value, setValue] = useState('');
  const refBarcode = useRef('');

  const handleSubmit = () => {
    if (Object.keys(productsList).length > 0 && productsList.products.length === 1) {
      handleAddProductToCheck(productsList.products[0].id);
    }
  }

  useEffect(() => {
    if (value.length >= 4) {
      { setTimeout(() => { trigSearch({ target: { value } }) }, 300) }
    }
  }, [value]);

  return (
    <div>
      <Button
        className={`me-md-2 ${classes.button}`}
        size='sm'
        onClick={(e) => {
          setValue('');
          setModalShow(true);
          setSearchByBarcode(true);
          dispatch({ type: DISPLAY_MODAL });
        }}
      >
        <img className={classes.nav_icon} src={PlusIcon} alt='add good' />
      </Button>
      <Modal
        show={modalShow}
        onHide={() => {
          dispatch({ type: HIDE_MODAL });
          setModalShow(false);
        }}
        size='lg'
        aria-labelledby='good-modal-btn'
        centered
        scrollable={true}
        contentClassName={classes.modal_content}
      >
        <Modal.Header closeButton>
          <InputGroup>
            <FormControl
              //aria-describedby='good-modal-btn'
              ref={refBarcode}
              onChange={delay(trigSearch, 500)}
              placeholder='Штрихкод'
              type='number'
              min='0'
              inputMode='numeric'
              autoComplete='off'
              readOnly
              value={value}
            />
          </InputGroup>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col sm={8}>
                <Table
                  hover
                >
                  <thead className={classes.table_head}>
                    <tr>
                      <th>Код</th>
                      <th>Назва</th>
                      <th className='text-end'>Ціна, грн.</th>
                    </tr>
                  </thead>
                  <tbody className={classes.table_body}>
                    {showLoader === false &&
                      Object.keys(productsList).length > 0 &&
                      Object.keys(productsList.products).map((key) => {
                        const productCur = productsList.products[key];
                        return (
                          <tr
                            key={key}
                            onClick={(e) => handleAddProductToCheck(productCur.id)}
                            className={classes.tr}
                          >
                            <td>{productCur.weightCode}</td>
                            <td>{productCur.name}</td>
                            <td className='col-3 text-end'>
                              {formatMoney(productCur.price)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
              <Col sm={4}>
                <NumericKeyboard setValue={setValue} handleSubmit={handleSubmit} />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default NavAddGoodByBarcodeKeyboard;
