// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NumericKeyboard_search_icon__2cZ43 {\n  width: var(--icon-button-size);\n  /* margin: 0px;\n  padding: 0px; */\n}\n.NumericKeyboard_wrap__v-m5u {\n  margin-top: 0rem !important;\n  width: 100%;\n}\n\n.NumericKeyboard_btnGroup__84dzP {\n  width: 28vh;\n}\n\n.NumericKeyboard_btnElem__P-j8- {\n  width: 9.33vh;\n  font-size: var(--font-size-rm1);\n}\n\n.NumericKeyboard_btnElem3__csEYt {\n  width: 28vh;\n  /* max-width: 99.9999999%; */\n  max-width: 100%;\n  font-size: var(--font-size-rm1);\n}\n\n.NumericKeyboard_btnSpecial__-ngTZ {\n  width: 9.33vh;\n  border: 1px solid grey;\n  font-size: var(--font-size-rm1);\n}", "",{"version":3,"sources":["webpack://./src/components/ui/NumericKeyboard.module.css"],"names":[],"mappings":"AAEA;EACE,8BAA8B;EAC9B;iBACe;AACjB;AACA;EACE,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,4BAA4B;EAC5B,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC","sourcesContent":["@import '../../indexVars.css';\n\n.search_icon {\n  width: var(--icon-button-size);\n  /* margin: 0px;\n  padding: 0px; */\n}\n.wrap {\n  margin-top: 0rem !important;\n  width: 100%;\n}\n\n.btnGroup {\n  width: 28vh;\n}\n\n.btnElem {\n  width: 9.33vh;\n  font-size: var(--font-size-rm1);\n}\n\n.btnElem3 {\n  width: 28vh;\n  /* max-width: 99.9999999%; */\n  max-width: 100%;\n  font-size: var(--font-size-rm1);\n}\n\n.btnSpecial {\n  width: 9.33vh;\n  border: 1px solid grey;\n  font-size: var(--font-size-rm1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search_icon": "NumericKeyboard_search_icon__2cZ43",
	"wrap": "NumericKeyboard_wrap__v-m5u",
	"btnGroup": "NumericKeyboard_btnGroup__84dzP",
	"btnElem": "NumericKeyboard_btnElem__P-j8-",
	"btnElem3": "NumericKeyboard_btnElem3__csEYt",
	"btnSpecial": "NumericKeyboard_btnSpecial__-ngTZ"
};
export default ___CSS_LOADER_EXPORT___;
