import React, { useState, useEffect, useRef } from 'react';
import classes from './weightcode_keyboard.module.css';
import { Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import BarcodeIcon from '../../images/scales-icon.svg';
// import SearchIcon from '../../images/search-icon.svg';
import {
  addProductToCheckByWeighCode,
  DISPLAY_MODAL,
  HIDE_MODAL,
} from '../../actions/checks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Loading from '../Loading';
import NumericKeyboard from '../ui/NumericKeyboard';

function WeightCodeKeyboard() {
  const [showAddProductBarCode, setShowAddProductBarCode] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const hideAnyModal = useSelector((state) => state.actions.hideAnyModal);
  const isShowLoader = useSelector(
    (state) => state.actions.isShowLoader,
    shallowEqual
  );
  const handleSubmit = () => {
    if (value.length > 0) {
      dispatch(addProductToCheckByWeighCode(value));
      setValue('');
    } else {
      //alert('to short!');
    }
  };
  const refWeightCode = useRef('');

  // listen that data should be updated and got response 200(ok) for hidding modal
  // useEffect(() => {
  //   if (hideAnyModal === true) {
  //     setShowAddProductBarCode(false);
  //   }
  // }, [hideAnyModal]);

  return (
    <div>

      <InputGroup className='mb-3'>
        <FormControl
          ref={refWeightCode}
          type='number'
          min='0'
          inputMode='numeric'
          autoComplete='off'
          onFocus={(e) => {
            e.target.select();
          }}
          // placeholder='код товару'
          aria-label='код товару'
          aria-describedby='barcode-search-btn'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          // onKeyDown={(e) => {
          //   if (e.key === 'Enter') {
          //     handleSubmit(e);
          //   }
          // }}
          readOnly
          className={classes.input}
        />
        {/* <Button
              id='barcode-search-btn'
              onClick={(e) => handleSubmit(e)}
              variant='outline-secondary'
            >
              <img
                className={classes.search_icon}
                src={SearchIcon}
                alt='search'
              />
            </Button> */}
      </InputGroup>
      <NumericKeyboard setValue={setValue} handleSubmit={handleSubmit} />
    </div>
  );
}

export default WeightCodeKeyboard;
