import { React, Fragment, useState } from "react";
import classes from "./ModalAddExciseStamp.module.css";
import { Form, Row, Col, Button, Modal, InputGroup, FormControl, Table, Alert } from "react-bootstrap";
import EditIcon from "../../images/edit.svg";
import CheckedIcon from "../../images/checked.svg";
import CancelIcon from "../../images/cancel.svg";
import RemoveIcon from "../../images/remove-icon.svg";
import { DISPLAY_MODAL, HIDE_MODAL } from "../../actions/checks";
import Loadin0g from "../Loading";
import { formatDate, formatMoney } from "../../helpers/dataFormatter";
import { useExciseCodes } from './hooks/useExciseCodes';
import { useScanExsiceStamp } from "../../syst/useScanExsiceStamp";


function ModalAddExciseStamp(props) {
  const [productRow, addExciseCode, deleteExciseCode, setExciseCodes, getCheckData] = useExciseCodes(props.productRow);
  const [editRowMode, setEditRowMode] = useState({ rowId: null });
  useScanExsiceStamp(10, (editRowMode.rowId === null), [], 
    { mthd: (scannedCode)=> {
        addExciseCode(productRow.receiptId, productRow.productId, scannedCode);
        setTimeout(() => {
          if (productRow && productRow.count === productRow.exciseCodes.length) {
            props.onHide();
          }
        }, 1000);
      } 
    });

  return (
    <div>
      <Modal
        show={true}
        onHide = {props.onHide}
        size="lg"
        aria-labelledby="good-modal-btn"
        centered
        scrollable={true}
        contentClassName={classes.modal_content}
      >
        <Modal.Header closeButton>
          <Modal.Title>Акцизні марки</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label><b>{productRow?.name}</b></Form.Label>
          <Row className="mb-3">
            <Form.Group as={Col} md="4">
              <Form.Label>Акцизних марок: <b>{productRow?.exciseCodes.length}</b></Form.Label>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>Кількість товару: <b>{productRow?.count}</b></Form.Label>
            </Form.Group>  
          </Row>
          <Form>
            {!(productRow && productRow.count <= productRow.exciseCodes.length) &&
              <Alert variant={'warning'}> 
                <p className="text-center">  {'<ВІДСКАНУЙТЕ АКЦИЗНУ МАРКУ> або '} 
                  <Button
                    variant='success' 
                    onClick={() => {
                      setEditRowMode({rowId: 'newRow'});
                      setTimeout(()=> { document.getElementById('edit_newRow').focus(); }, 100)
                    }}
                    disabled={productRow && productRow.count <= productRow.exciseCodes.length}
                    size='xs'
                  >
                    {` + ДОДАЙТЕ ВРУЧНУ`}{' '}
                  </Button>
                </p>
              </Alert>
            }
            {productRow && productRow.count === productRow.exciseCodes.length &&
              <Alert variant={'success'}><p className="text-center"> {'<АКЦИЗНІ МАРКИ ВІДСКАНОВАНО>'} </p> </Alert>
            }
            {productRow && productRow.count < productRow.exciseCodes.length &&
              <Alert variant={'danger'}><p className="text-center"> {'<ВИДАЛІТЬ АКЦИЗНІ МАРКИ ПОВЕРНУТОГО ТОВАРУ>'} </p> </Alert>
            }
            <Table size='sm'>
              <thead>
                <tr key={'pay-list-head'} className={classes.head}>
                <th className='col-6'>Акцизна марка</th>
                <th className='text-end text-nowrap'>Редагування</th>
              </tr>
            </thead>
            <tbody>
            {productRow?.productId && editRowMode.rowId &&
              <tr className={classes.row}>
                <td colSpan={1}>
                  <Form.Group>
                    <Form.Control
                      id={'edit_newRow'}
                      type='text'
                      //onChange={(e) => { }}
                      //value={''}
                      inputMode='numeric'
                      maxLength={10}
                      autoComplete='off'
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      //readOnly={true}
                      className={classes.input}
                    />
                  </Form.Group>
                </td>
                <td colSpan={1} className={classes.rightTextElem}>
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      const editElement = document.getElementById('edit_newRow');
                      if (editElement) {
                        const newExciseValue = editElement.value.trim().toUpperCase();
                        console.log(newExciseValue);
                        if (newExciseValue.length === 10) {
                          addExciseCode(productRow.receiptId, productRow.productId, newExciseValue);
                        }
                      }
                      setEditRowMode({rowId: null});
                    }}
                  >
                    <img className={classes.search_icon}
                      src={CheckedIcon}
                      alt="check"
                    />
                  </Button>{' '}
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      setEditRowMode({rowId: null});
                    }}
                  >
                    <img className={classes.search_icon}
                      src={CancelIcon}
                      alt="cancel"
                    />
                  </Button>
                </td>
              </tr>}
              {productRow?.exciseCodes?.length > 0 &&
                productRow?.exciseCodes.map(
                  (exsiceCode) => {
                    return (
                      <Fragment key={exsiceCode}>
                        <tr className={classes.row}>
                          <td colSpan={1}>
                            <p className='mb-1'>{exsiceCode}</p>
                          </td>
                          <td colSpan={1} className={classes.rightTextElem}>
                            <Button
                              variant="outline-secondary"
                              onClick={() => {
                                deleteExciseCode(productRow.receiptId, productRow.productId, exsiceCode);
                              }}
                            >
                              <img className={classes.search_icon}
                                src={RemoveIcon}
                                alt="check"
                              />
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  }
                )}
            </tbody>
          </Table>
        </Form>
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default ModalAddExciseStamp;
