import { useState, useEffect } from 'react';
import { fetcherAsync } from '../../../helpers/fetcherAsync';
import { configApiLinks } from '../../../configApiLinks';

export const usePrintLabels = () => {
    const [productIds, setProductIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {

    // }, []);

    /**
    * Function that add, delete elems or set productIds state array
    * @author  Stream
    * @param   {Integer or Array} productIdOrIdsArray productId or array of productIds
    * @param   {String} metod method to 'add' / 'del' / 'set' productId elem or array of productIds
    */
    const addSetProductIds = async (productIdOrIdsArray, method = 'add') => {
        //console.log('addSetProductIds', { productIdOrIdsArray, method });
        switch (method) {
            case 'add':
                if (!productIds.find(elemProductId => elemProductId === productIdOrIdsArray)) {
                    let productIdsNew = [...productIds];
                    productIdsNew.push(productIdOrIdsArray);
                    setProductIds(productIdsNew);
                }
                break;
            case 'del':
                if (productIds.length === 1) {
                    setProductIds([]);
                } else {
                    let elemIndex = productIds.findIndex(elemProductId => elemProductId === productIdOrIdsArray);
                    if (elemIndex > 0) {
                        let productIdsNew = [...productIds];
                        productIdsNew.splice(elemIndex, 1);
                        setProductIds(productIdsNew);
                    }
                }
                break;
            case 'set':
                if (Array.isArray(productIdOrIdsArray)) {
                    setProductIds(productIdOrIdsArray);
                }
                break;
            default:
                console.log(`unknown method ${method}.`);
        }
    };

    const printProductLabels = async () => {
        setIsLoading(true);
        try {
            //console.log('pdf_data_start');
            const responseData = await fetcherAsync(configApiLinks.printProductLabels, 'POST', { productIds }, true);
            //console.log('pdf_data_responseBody', responseData);
            const file = new Blob([await responseData.blob()], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        } catch (error) {
            console.log('printError', error.message);
        }
        setIsLoading(false);
    };

    return [productIds, isLoading, addSetProductIds, printProductLabels];
};
