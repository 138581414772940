import {LOGGED_SUCCESS,LOGGED_OUT, SET_ERROR, NOT_LOGGED, LOGGED_IN} from '../actions/users';

const initialState = {
    isShowLoader: false,
    isLogged: null,
    user: {},
    error: ""
}

export default function usersActions(state = initialState, action) {
    switch (action.type) {
        case LOGGED_SUCCESS:
            console.log("action.user", action.user);
            return {
                user: action.user,
                isLogged: true,
                isAdmin: action.user.rolesAdmin
            }
            break;

        case LOGGED_OUT:
            return {
                user: {},
                isLogged: false
            }
            break;

        case NOT_LOGGED:
            return {
                ...state,
                isLogged: false
            }
         break;

        case SET_ERROR:
            return {
                error: action.error,
                user: {},
                isLogged: false
            }
            break;

        case LOGGED_IN:
            return {
                ...state,
                user: action.json,
                isLogged: true
            }
            break;

        default:
            return state;

    }

}