import { React, useRef } from 'react';
import classes from './nav_add_good_by_barcode.module.css';
import { Button, Modal, InputGroup, FormControl, Table } from 'react-bootstrap';

import PlusIcon from '../../images/barcode-icon.svg';
import SearchIcon from '../../images/search-icon.svg';
import FolderIcon from '../../images/folder-icon.svg';
import BackIcon from '../../images/back-icon.svg';
import { DISPLAY_MODAL, HIDE_MODAL } from '../../actions/checks';
import { useAddGoods } from './hooks/add_goods_hooks';
import Loading from '../Loading';
import { formatDate, formatMoney } from '../../helpers/dataFormatter';

function NavAddGoodByBarcode() {
  const {
    handleAddProductToCheck,
    handleCategory,
    modalShow,
    setModalShow,
    productsList,
    showLoader,
    hideAnyModal,
    dispatch,
    trigSearch,
    delay,
    setSearchByBarcode,
  } = useAddGoods();
  const refBarcode = useRef('');

  return (
    <div>
      <Button
        className={`me-md-2 ${classes.button}`}
        size='sm'
        onClick={(e) => {
          e.target.parentElement.focus();
          e.target.parentElement.blur();
          setModalShow(true);
          setSearchByBarcode(true);
          dispatch({ type: DISPLAY_MODAL });
          setTimeout(() => {
            if (refBarcode.current) {
              refBarcode.current.focus();
              refBarcode.current.select();
              console.log('refBarcode.current', refBarcode.current);
            }
          }, 300);
        }}
      >
        <img className={classes.nav_icon} src={PlusIcon} alt='add good' />
      </Button>
      <Modal
        show={modalShow}
        onHide={() => {
          dispatch({ type: HIDE_MODAL });
          setModalShow(false);
        }}
        size='lg'
        aria-labelledby='good-modal-btn'
        centered
        scrollable={true}
        contentClassName={classes.modal_content}
      >
        <Modal.Header closeButton>
          <InputGroup>
            <FormControl
              //aria-describedby='good-modal-btn'
              ref={refBarcode}
              onChange={delay(trigSearch, 500)}
              placeholder='Штрихкод'
              type='number'
              min='0'
              inputMode='numeric'
              autoComplete='off'
              onFocus={(e) => {
                e.target.select();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (e.target.value >= 12) {
                    handleAddProductToCheck(e.target.value, true);
                  }
                }
              }}
            />
            {/* <Button variant='outline-secondary'>
              <img
                className={classes.search_icon}
                src={SearchIcon}
                alt='search'
              />
            </Button> */}
          </InputGroup>
        </Modal.Header>
        <Modal.Body>
          {/* <Loading /> uncommit  */}
          {console.log('show Loader ', showLoader)}
          {console.log('show Loader products', productsList)}
          <Table
            className={
              showLoader === false &&
                Object.keys(productsList.categories).length == 0
                ? ''
                : 'd-none'
            }
            hover
          >
            <thead className={classes.table_head}>
              <tr>
                <th>Код</th>
                <th>Назва</th>
                <th className='text-end'>Ціна, грн.</th>
              </tr>
            </thead>
            <tbody className={classes.table_body}>
              {showLoader === false &&
                Object.keys(productsList).length > 0 &&
                Object.keys(productsList.products).map((key) => {
                  const productCur = productsList.products[key];
                  return (
                    <tr
                      key={key}
                      onClick={(e) => handleAddProductToCheck(productCur.id)}
                      className={classes.tr}
                    >
                      <td>{productCur.weightCode}</td>
                      <td>{productCur.name}</td>
                      <td className='col-3 text-end'>
                        {formatMoney(productCur.price)}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default NavAddGoodByBarcode;
