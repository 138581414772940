import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
import { useGetWeight } from './hooks/useGetWeight';
import classes from './modal_count_edit.module.css';
import NumericKeyboard from '../ui/NumericKeyboard';

const ModalCountEditKeyboard = (props) => {
  const [productCount, setProductCount] = useState(props.count);
  const [manualEdit, setManualEdit] = useState(!props.getWeightFromScales);
  const countRef = useRef('');
  const [weightData, getWeightData, startStopScalesUpdate, updIntervalId] =
    useGetWeight(props.getWeightFromScales);

  // const focusSelectCount = () => {
  //   if (countRef.current) {
  //     countRef.current.focus();
  //     countRef.current.select();
  //     //console.log('countRef.current', countRef.current);
  //   }
  // };

  // useEffect(() => {
  //   if (props.isWeightProduct && !props.getWeightFromScales) {
  //     setTimeout(() => {
  //       focusSelectCount();
  //     }, 100);
  //   }
  //   return () => {
  //     clearInterval(updIntervalId);
  //   };
  // }, []);

  useEffect(() => {
    if (!manualEdit && weightData && weightData.result !== productCount) {
      setProductCount(weightData.result);
    }
  }, [weightData]);

  const handleCount = (e, action) => {
    e.preventDefault();
    console.log('handleCount', e);
    if (action === 'set') {
      setProductCount(e.target.value);
    } else if (action === '+') {
      setProductCount(Number(productCount) + 1);
    } else if (action === '-') {
      setProductCount(Math.max(Number(productCount) - 1, 0));
    }
  };

  const onSubmit = () => {
    clearInterval(updIntervalId);
    props.onSubmit({
      productId: props.productId,
      count: productCount,
    });
  };

  return (
    <Modal
      show={true}
      onHide={() => {
        props.onHide();
        clearInterval(updIntervalId);
      }}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Кількість</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.isWeightProduct && (
          <Row className='justify-content-center align-items-center' style={{ margin: '0 0 1vh 10vh' }}>
            <Form.Check
              // inline
              label='Ввести вагу вручну'
              name='group1'
              type={'checkbox'}
              id={`inline-checkbox-1`}
              onChange={() => {
                // if (!manualEdit) {
                //   setTimeout(() => {
                //     focusSelectCount();
                //   }, 50);
                // }
                startStopScalesUpdate(manualEdit);
                setManualEdit(!manualEdit);
              }}
              checked={manualEdit}
            />
          </Row>
        )}
        <Row className='justify-content-center align-items-center'>
          <Col className='text-end'>
            <Button
              onClick={(e) => handleCount(e, '-')}
              variant='secondary'
              size='lg'
              style={{ width: '5vh' }}
            >
              -
            </Button>
          </Col>
          <Form as={Col} style={{ display: 'flex', justifyContent: 'center' }}>
            <Form.Group>
              <Form.Control
                ref={countRef}
                className={`text-center ${classes.input}`}
                type='text'
                onChange={(e) => handleCount(e, 'set')}
                value={productCount}
                inputMode='numeric'
                autoComplete='off'
                onFocus={(e) => {
                  e.target.select();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    onSubmit();
                  }
                }}
                readOnly={props.isWeightProduct && !manualEdit}
              />
            </Form.Group>
          </Form>
          <Col>
            <Button
              onClick={(e) => handleCount(e, '+')}
              variant='secondary'
              size='lg'
              style={{ width: '5vh' }}
            >
              +
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-md-center" style={{ margin: '10px 0 0 0', justifyContent: 'center' }}>
          <div className={classes.numericKeyboard} style={{ maxWidth: '70%', maxHeight: '50%' }}>
            <NumericKeyboard setValue={(keyboardValue) => { setProductCount(keyboardValue === '' ? 0 : keyboardValue); }} handleSubmit={null} floatPoint={true} />
          </div>
        </Row>
        <Row className={'mt-3 justify-content-center'}>
          <Button onClick={onSubmit} className={'w-50'} variant='success'>
            {'OK'}
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCountEditKeyboard;
