import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button, Modal, Table, Form, Row, Col } from 'react-bootstrap';
import classes from './FiscalRegistrars.module.css';
// import { DISPLAY_MODAL, HIDE_MODAL, getCheckData } from '../../actions/checks';
// import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { formatDate, formatMoney } from '../../../helpers/dataFormatter';
import { useFRsControls } from '../../modals/hooks/useFRsControls';

function FiscalRegistrarsList({ elemEditId, setElemEditId }) {
    const [fiscalRegistrarId, setFiscalRegistrarId] = useState(0);
    const [shiftData, isLoading, getShiftData, openShift, closeShift, xReport] = useFRsControls(null);
    //const dispatch = useDispatch();
    useEffect(() => {
        getShiftData();
    }, []);
    const EditIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
        </svg>
    }
    return (
        <Form>
            <Table size='sm'>
                <thead>
                    <tr key={'pay-list-head'} className={classes.head}>
                        <th className='col-6'>Фіскальний реєстратор</th>
                        <th className='col-1'>IP</th>
                        <th className={`col-2 ${classes.headerTextEnd}`} colSpan={1}>В касі, грн</th>
                        {/* <th className={`text-end text-nowrap ${classes.rightTextElem}`}>Зміна</th> */}
                        <th className={classes.headerTextEnd}>Зміна</th>
                    </tr>
                </thead>
                <tbody>
                    {shiftData &&
                        shiftData.shift &&
                        shiftData.shift.fiscalRegistrars &&
                        shiftData.shift.fiscalRegistrars.length > 0 &&
                        shiftData.shift.fiscalRegistrars.map(
                            (fiscalRegistrar) => {
                                return (
                                    <Fragment key={fiscalRegistrar.id + 'pay-list-fr'}>
                                        <tr className={classes.row}>
                                            <td colSpan={1}>

                                                <p className='mb-1'>
                                                    <Button 
                                                    variant='outline-secondary' 
                                                    size='sm'
                                                    onClick={()=>{setElemEditId(fiscalRegistrar.id)}}
                                                    >
                                                        <EditIcon />
                                                    </Button>
                                                    {' '}{fiscalRegistrar.name}</p>
                                            </td>
                                            <td colSpan={1}>
                                                <p className='mb-1'>
                                                    {fiscalRegistrar.ip}</p>
                                            </td>
                                            <td colSpan={1} className={classes.rightTextElem}>
                                                <p className='mb-1'>
                                                    {formatMoney(fiscalRegistrar.currentCash)}</p>
                                            </td>
                                            <td colSpan={1} className={classes.rightTextElem}>
                                                {!(
                                                    fiscalRegistrar.frData &&
                                                    fiscalRegistrar.frData.cash_shift_info &&
                                                    fiscalRegistrar.frData.cash_shift_info.active
                                                ) && (
                                                        <i
                                                            className='bi bi-door-closed'
                                                            style={{ fontSize: 28, color: 'grey' }}
                                                        ></i>
                                                    )}
                                                {fiscalRegistrar.frData &&
                                                    fiscalRegistrar.frData.cash_shift_info &&
                                                    fiscalRegistrar.frData.cash_shift_info.active && (
                                                        <i
                                                            className='bi bi-door-open'
                                                            style={{ fontSize: 28, color: 'orange' }}
                                                        ></i>
                                                    )}
                                            </td>
                                        </tr>
                                        {(fiscalRegistrar.updOpenShiftIsError ||
                                            fiscalRegistrar.updCloseShiftIsError ||
                                            fiscalRegistrar.updXReportIsError) && (
                                                <tr className={classes.row}>
                                                    <td colSpan={2}>
                                                        <p className='mb-1 text-danger'>{fiscalRegistrar.errorMessage}</p>
                                                    </td>
                                                </tr>
                                            )}
                                        <tr className={classes.row}>
                                            <td colSpan={8}>
                                                {/* <p className='mb-1'>{fiscalRegistrar.name}</p> */}
                                                <Button
                                                    variant='secondary'
                                                    onClick={() => {
                                                        openShift(fiscalRegistrar.frData);
                                                    }}
                                                    disabled={
                                                        (fiscalRegistrar.frData &&
                                                            fiscalRegistrar.frData.cash_shift_info &&
                                                            fiscalRegistrar.frData.cash_shift_info.active) || (isLoading && shiftData.updOpenShift)
                                                    }
                                                    size='sm'
                                                >
                                                    Відкрити зміну
                                                </Button>{' '}
                                                <Button
                                                    variant='success'
                                                    onClick={() => {
                                                        closeShift(fiscalRegistrar);
                                                    }}
                                                    disabled={
                                                        !(
                                                            fiscalRegistrar.frData &&
                                                            fiscalRegistrar.frData.cash_shift_info &&
                                                            fiscalRegistrar.frData.cash_shift_info.active
                                                        ) || (isLoading && shiftData.updCloseShift)
                                                    }
                                                    size='sm'
                                                >
                                                    {`${(isLoading && shiftData.updCloseShift) ? '...' : ''}Z-звіт`}
                                                </Button>{' '}
                                                <Button
                                                    variant='info'
                                                    onClick={() => {
                                                        xReport(fiscalRegistrar);
                                                    }}
                                                    disabled={
                                                        !(
                                                            fiscalRegistrar.frData &&
                                                            fiscalRegistrar.frData.cash_shift_info &&
                                                            fiscalRegistrar.frData.cash_shift_info.active
                                                        ) || (isLoading && shiftData.updXReport)
                                                    }
                                                    size='sm'
                                                >
                                                    {`${(isLoading && shiftData.updXReport) ? '...' : ''}Х-звіт`}
                                                </Button>
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            }
                        )}
                </tbody>
            </Table>
        </Form>
    );
}

export default FiscalRegistrarsList;
