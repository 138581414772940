import { HIDE_MODAL, updateProductCount } from '../../actions/checks';
import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

export default function ModalCount({ curProduct, setCurProduct }) {
  const dispatch = useDispatch();

  const handleCount = (e, product, action) => {
    const clonedProduct = Object.assign({}, product);
    if (action === 'set') {
      clonedProduct.count = e.target.value;
    } else if (action === '+') {
      clonedProduct.count++;
    } else if (action === '-') {
      clonedProduct.count--;
    }
    setCurProduct(clonedProduct);
  };

  const submitNewCount = () => {
    dispatch(updateProductCount(curProduct));
  };

  return (
    <div>
      {Object.keys(curProduct).length > 0 && (
        <div id={'modal'}>
          <input
            type={'text'}
            name={'modal-count'}
            value={curProduct.count}
            onChange={(e) => handleCount(e, curProduct, 'set')}
            onFocus={(e) => {
              console.log('focus');
            }}
          />
          <button onClick={(e) => handleCount(e, curProduct, '+')} id={'plus'}>
            +
          </button>
          <button onClick={(e) => handleCount(e, curProduct, '-')} id={'minus'}>
            -
          </button>
          <button onClick={(e) => submitNewCount(e, curProduct)}>{'Ok'}</button>
          <button onClick={(event) => dispatch({ type: HIDE_MODAL })}>
            {'close'}
          </button>
        </div>
      )}
    </div>
  );
}
