import { React, Fragment } from 'react';
import { Col } from 'react-bootstrap';
import classes from './footer_total.module.css';
import { useSelector, shallowEqual } from 'react-redux';
import { formatDate, formatMoney } from '../../helpers/dataFormatter';

function FooterTotal({ checkData }) {
  const isShowLoader = useSelector(
    (state) => state.actions.isShowLoader,
    shallowEqual
  );

  return (
    // <Col xs={6}>
    <Fragment>
      <div className={classes.textBlockRow}>
        <span className=' fw-bold'>Позицій:</span>{' '}
        {!isShowLoader && checkData && checkData.receipt
          ? Object.keys(checkData.receipt.products).length
          : 0}
      </div>
      <div className={classes.textBlockRow}>
        <span className=' fw-bold'>Знижка: </span>
        {!isShowLoader &&
          checkData &&
          formatMoney(
            checkData.receipt ? checkData.receipt.sumTotalDiscount : 0
          )}{' '}
        грн.
      </div>
      <div className={classes.textBlockRow}>
        <span className=' fw-bold '>Всього:</span>{' '}
        <span className={classes.total}>
          {!isShowLoader &&
            checkData &&
            formatMoney(
              checkData.receipt ? checkData.receipt.sumTotal : 0
            )}{' '}
          грн.
        </span>
      </div>
    </Fragment>
  );
}

export default FooterTotal;
