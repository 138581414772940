import React from "react";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";

function ModalChecklistReturn(props) {
  return (
    <>
      <Modal {...props}>
        <Modal.Header closeButton>
          <Modal.Title>Введіть номер чеку</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <FormControl type="text" />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success">Ок</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalChecklistReturn;
