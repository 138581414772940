// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_total_total__6fyvF{\n    font-size: var(--font-size-fs16);\n    font-weight: 700;\n    white-space: nowrap;\n    color: green;\n\n}\n.footer_total_textBlockRow__p1EU- {\n    width: max-content;\n}\n\n@media (min-width: 768px){\n    .footer_total_total__6fyvF{\n        font-size: var(--font-size-fs18);\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/Footer/footer_total.module.css"],"names":[],"mappings":"AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;;AAEhB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,gCAAgC;IACpC;AACJ","sourcesContent":["@import '../../indexVars.css';\n\n.total{\n    font-size: var(--font-size-fs16);\n    font-weight: 700;\n    white-space: nowrap;\n    color: green;\n\n}\n.textBlockRow {\n    width: max-content;\n}\n\n@media (min-width: 768px){\n    .total{\n        font-size: var(--font-size-fs18);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"total": "footer_total_total__6fyvF",
	"textBlockRow": "footer_total_textBlockRow__p1EU-"
};
export default ___CSS_LOADER_EXPORT___;
