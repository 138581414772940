
import { Provider } from 'react-redux';
import configureStore from './store/store.js';

import React  from 'react';
import Routing from "./Routing";
export const store = configureStore();

function App() {
    return (
        <Provider store={store}>
            <Routing />
        </Provider>
    );
}
export default App;