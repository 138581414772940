import React, { useState, Fragment } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import classes from './footer_fr_controls.module.css';
import ModalFrsControls from '../modals/modal_frs';
//import FrIcon from '../../images/cashier-machine.svg';
import FrIconGreen from '../../images/cashier-machine-green.svg';
import FrIconRed from '../../images/cashier-machine-red.svg';
import FrSuccess from '../../images/checked-fr.svg';
import FrError from '../../images/error-fr.svg';
//import {useDispatch, useSelector,shallowEqual} from "react-redux";
import { formatMoney } from '../../helpers/dataFormatter';
import { useWindowSize } from '../hooks/useWindowSize';

function FooterFrControls({ checkData }) {
  const [modalFrControls, setModalFrControls] = useState({
    isActive: false,
    type: '',
  });
  const { isNonMobile } = useWindowSize();
  //const dispatch = useDispatch();

  return (
    <Fragment>
      {isNonMobile && (<div
        className={classes.foot_icon_success}
        onClick={() => {
          setModalFrControls({
            isActive: true,
            type: '',
          });
        }}
      >
        <img src={checkData.shift?.frStatusTotal?.active ? FrSuccess : FrError} alt='' style={{ fill: '#0d6efd' }} />
        <img src={checkData.shift?.frStatusTotal?.active ? FrIconGreen : FrIconRed} alt='' style={{ fill: '#0d6efd' }} />
      </div>)}

      {!isNonMobile && (
        <div className={classes.foot_icon_success_mobile} onClick={() => {
          setModalFrControls({
            isActive: true,
            type: '',
          });
        }}>
          <Col sx={12}>
            <img src={checkData.shift?.frStatusTotal?.active ? FrSuccess : FrError} alt='' style={{ fill: '#0d6efd' }} />
            <img src={checkData.shift?.frStatusTotal?.active ? FrIconGreen : FrIconRed} alt='' style={{ fill: '#0d6efd' }} />
          </Col>
          {/* <Col sx={3}></Col> */}
        </div>
      )}
      {modalFrControls.isActive && (
        <ModalFrsControls
          shiftData={checkData}
          closeModal={() => {
            setModalFrControls({
              isActive: false,
              type: '',
            });
            // dispatch(getCheckData());
          }}
        />
      )}
    </Fragment>
  );
}

export default FooterFrControls;
