// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nav_discount_nav_icon__P1dS\\+{\n    width: var(--icon-button-size-26);\n}\n.nav_discount_button__g1zzK{\n    background: none;\n    border: 0;\n}\n.nav_discount_button__g1zzK:active,\n.nav_discount_button__g1zzK:focus,\n.nav_discount_button__g1zzK:hover,\n.nav_discount_button__g1zzK:active:focus{\n    background-color: transparent;\n    border-color: transparent;\n    border: 0;\n    box-shadow: none;\n}", "",{"version":3,"sources":["webpack://./src/components/Header/nav_discount.module.css"],"names":[],"mappings":"AAEA;IACI,iCAAiC;AACrC;AACA;IACI,gBAAgB;IAChB,SAAS;AACb;AACA;;;;IAII,6BAA6B;IAC7B,yBAAyB;IACzB,SAAS;IACT,gBAAgB;AACpB","sourcesContent":["@import '../../indexVars.css';\n\n.nav_icon{\n    width: var(--icon-button-size-26);\n}\n.button{\n    background: none;\n    border: 0;\n}\n.button:active,\n.button:focus,\n.button:hover,\n.button:active:focus{\n    background-color: transparent;\n    border-color: transparent;\n    border: 0;\n    box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav_icon": "nav_discount_nav_icon__P1dS+",
	"button": "nav_discount_button__g1zzK"
};
export default ___CSS_LOADER_EXPORT___;
