import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { addProductToCheck, SET_CHECK_DATA } from '../../../actions/checks';
import { configApiLinks } from '../../../configApiLinks';
import { fetcher } from '../../../helpers/fetcher';
import { fetcherAsync } from '../../../helpers/fetcherAsync';

export const useAddGoods = (autoUpdateOnShowHide = true) => {
  const [modalShow, setModalShow] = useState(false);
  const [productsList, setProductsList] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [searchByBarcode, setSearchByBarcode] = useState(false);
  const [noTreeView, setNoTreeView] = useState(false);
  const hideAnyModal = useSelector((state) => state.actions.hideAnyModal);
  const dispatch = useDispatch();

  const trigSearch = (event, priceDate = null) => {
    console.log('trigSearch->event', { event, priceDate });
    if (event && event.target && event.target.value) {
      setShowLoader(true);
      let filterData = { sphrase: event.target.value };
      if (priceDate) {
        filterData.priceDate = priceDate;
      }
      fetcher(
        searchByBarcode
          ? configApiLinks.findProductsBarcode
          : configApiLinks.findProducts,
        'POST',
        filterData
      ).then((data) => {
        setProductsList(data);
        setShowLoader(false);
      });
    }
  };

  const delay = (callback, ms) => {
    //console.log('delay');
    let timer = 0;
    // return function because keyup require function
    return function () {
      let context = this;
      let args = arguments;
      // console.log('delay_params', { context, args });
      // if we put something again clear timer (do not wait for result function below) amd start timer again
      clearTimeout(timer);
      timer = setTimeout(function () {
        // runs function on key up ajax send format url etc...
        callback.apply(context, args);
      }, ms);
    };
  };

  useEffect(() => {
    //reset data when modal show again
    console.log('useAddGoods->autoUpdateOnShowHide', autoUpdateOnShowHide);
    if (modalShow && autoUpdateOnShowHide) {
      fetcher(configApiLinks.findProducts, 'POST', {}).then((data) => {
        setProductsList(data);
        setShowLoader(false);
      });
    }
  }, [modalShow]);

  const handleCategory = (categoryId = null, priceDate = null, sphrase = null, inNoTreeView = null, showLoaderUntilQuery = true) => {
    console.log('handleCategory->params', { categoryId, priceDate, sphrase, noTreeView });
    if (showLoaderUntilQuery) { setShowLoader(true); }
    fetcher(searchByBarcode && sphrase
      ? configApiLinks.findProductsBarcode
      : configApiLinks.findProducts, 'POST', {
      categoryId,
      priceDate,
      sphrase,
      noTreeView: inNoTreeView === null ? noTreeView : inNoTreeView
    }).then((data) => {
      setProductsList(data);
      if (showLoaderUntilQuery) { setShowLoader(false); }
    });
  };

  useEffect(() => {
    if (hideAnyModal === true) {
      setModalShow(false);
    }
  }, [hideAnyModal]);

  const handleAddProductToCheck = (id, byBarcode = false) => {
    if (byBarcode) {
      dispatch(addProductToCheck(id));
    } else {
      fetcher(configApiLinks.addProductById, 'POST', { productId: id }).then(
        (data) => {
          dispatch({ type: SET_CHECK_DATA, data });
        }
      );
    }
  };

  const changePrice = async (productId, price, callbackFn) => {

    try {
      const responseData = await fetcherAsync(configApiLinks.productsEditPrice, 'POST', {
        id: productId,
        price
      });
      if (!responseData.isError) {
        callbackFn();
      }
    } catch (error) {
      console.log('changePriceError', error.message);
    }
  };



  return {
    handleAddProductToCheck,
    handleCategory,
    modalShow,
    setModalShow,
    productsList,
    showLoader,
    hideAnyModal,
    dispatch,
    trigSearch,
    delay,
    setSearchByBarcode,
    noTreeView,
    setNoTreeView,
    changePrice
  };
};
