import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button, Modal, Table, Form, Row, Col, Container } from 'react-bootstrap';
import classes from './modal_cash_in_out.module.css';
import { DISPLAY_MODAL, HIDE_MODAL } from '../../actions/checks';
import { useFooterMaking } from '../Footer/hooks/footer_making';
import { formatDate, formatMoney } from '../../helpers/dataFormatter';
import NumericKeyboard from '../ui/NumericKeyboard';
import { useWindowSize } from '../hooks/useWindowSize';

function ModalCashInOut({ shiftData, opType, closeModal, correctSum }) {
  const {
    puttedCash,
    setPuttedCash,
    fiscalRegistrarId,
    setFiscalRegistrarId,
    putCash,
    modalShow,
    setModalShow,
    dispatch,
  } = useFooterMaking(opType === 'IN', (correctSum === true));
  const { isNonMobile } = useWindowSize();
  const cashRef = useRef();

  useEffect(() => {
    if (cashRef.current) {
      // cashRef.current.focus();
    }
    if (
      shiftData &&
      shiftData.shift &&
      shiftData.shift.fiscalRegistrars &&
      shiftData.shift.fiscalRegistrars.length === 1
    ) {
      setFiscalRegistrarId(shiftData.shift.fiscalRegistrars[0].id);
    }
  }, []);

  return (
    <Modal
      show={true}
      onHide={() => {
        // setModalShow(false);
        // dispatch({ type: HIDE_MODAL });
        closeModal();
      }}
      size='lg'
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Готівки в касі -{' '}
          {Object.keys(shiftData).length > 0 &&
            formatMoney(shiftData.shift.currentCash)}{' '}
          грн.

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row xs={12} style={{ justifyContent: 'center' }}>
            <Col xs={isNonMobile ? 7 : 12} style={{ justifyContent: 'center', marginBottom: '1.2vh' }}>
              <Form>
                <Table size='sm'>
                  <thead>
                    <tr key={'pay-list-head'} className={classes.head}>
                      <th className='col-6'>Фіскальний реєстратор</th>
                      <th className='text-end text-nowrap'>В касі,₴</th>
                      {shiftData?.cashierWorkplace?.enableTestReceipt > 0 &&
                        <th className='text-end text-nowrap'>Tecт,₴</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      //!isShowLoader &&
                      shiftData &&
                      shiftData.shift &&
                      shiftData.shift.fiscalRegistrars &&
                      shiftData.shift.fiscalRegistrars.length > 0 &&
                      shiftData.shift.fiscalRegistrars.map((fiscalRegistrar) => {
                        return (
                          <Fragment key={fiscalRegistrar.id + 'pay-list-fr'}>
                            <tr className={classes.row}>
                              <td
                                colSpan={1}
                                onClick={() => {
                                  setFiscalRegistrarId(fiscalRegistrar.id);
                                  // cashRef.current.focus();
                                }}
                              >
                                {/* <p className='mb-1'>{fiscalRegistrar.name}</p> */}
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    name={`flexRadioDe${fiscalRegistrar.id}`}
                                    id={`radio-id-${fiscalRegistrar.id}`}
                                    checked={
                                      fiscalRegistrar.id === fiscalRegistrarId
                                    }
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={`radio-id-${fiscalRegistrar.id}`}
                                  >
                                    {fiscalRegistrar.name}
                                  </label>
                                </div>
                              </td>
                              <td colSpan={1}>
                                <p className='mb-1 text-end text-nowrap'>
                                  {formatMoney(fiscalRegistrar.currentCash)}
                                </p>
                              </td>
                              {shiftData?.cashierWorkplace?.enableTestReceipt > 0 &&
                                <td colSpan={1}>
                                  <p className='mb-1 text-end text-nowrap'>
                                    {formatMoney(fiscalRegistrar.currentCashTest)}
                                  </p>
                                </td>
                              }
                            </tr>
                          </Fragment>
                        );
                      })
                    }
                  </tbody>
                </Table>
                <Form.Group as={Row}>
                  <Form.Label className='fw-bold' column xs='5'>
                    Сума
                  </Form.Label>
                  <Col xs='7'>
                    <Form.Control
                      ref={cashRef}
                      onChange={(e) => {
                        const eCash = parseFloat(e.target.value);
                        if (eCash) {
                          setPuttedCash(eCash);
                        }
                      }}
                      //value={puttedCash}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          if (fiscalRegistrarId !== 0 && puttedCash !== 0) {
                            putCash();
                            closeModal();
                          }
                        }
                      }}
                      // type='number'
                      readOnly
                      placeholder='0'
                    />
                  </Col>

                </Form.Group>
              </Form>
            </Col>
            <Col xs={isNonMobile ? 5 : 9} className="justify-content-md-center" style={{ maxWidth: '70%', maxHeight: '50%', display: 'flex', justifyContent: 'center' }}>
              <div className={classes.numericKeyboard}>
                <NumericKeyboard setValue={(keyboardValue) => {
                  console.log({ keyboardValue });
                  cashRef.current.value = keyboardValue;
                  const eCash = parseFloat(keyboardValue);
                  if (eCash) {
                    setPuttedCash(eCash);
                  }
                }} handleSubmit={null} floatPoint={true} />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-secondary'
          onClick={() => {
            closeModal();
          }}
        >
          Закрити
        </Button>
        <Button
          onClick={() => {
            putCash();
            closeModal();
          }}
          variant='success'
          disabled={fiscalRegistrarId === 0 || puttedCash === 0}
        >
          {opType === 'IN' ? 'Внести' : 'вилучити'}
        </Button>
        {shiftData?.cashierWorkplace?.enableTestReceipt > 0 &&
          <Button
            onClick={() => {
              putCash(true);
              closeModal();
            }}
            variant='warning'
            disabled={fiscalRegistrarId === 0 || puttedCash === 0}
          >
            {opType === 'IN' ? 'тест' : 'тест'}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCashInOut;
