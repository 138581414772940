import { React, useState } from 'react';
import { returnReceipt, setReceiptPrintCopy } from '../../actions/checks';
import classes from './nav_receiptlist.module.css';
import {
  Button,
  Modal,
  Table,
  Accordion,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import ChecklistIcon from '../../images/list-icon.svg';
import Loader from '../../images/loader.gif';
import SearchIcon from '../../images/search-icon.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useReceiptList } from './hooks/receipt_list_hooks';
import { usePayProcessReceipt } from '../modals/hooks/usePayProcessReceipt';
import { formatDate, formatMoney } from '../../helpers/dataFormatter';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';


const NavReceiptlist = (props) => {
  const dispatch = useDispatch();
  const [checkedList, setCheckedList] = useState({ id: null, products: [] });
  const {
    delay,
    triggerSearchByDate,
    triggerSearch,
    modalShow,
    checkDataHistory,
    showLoader,
    searchText,
    startDate,
    endDate,
    setModalShow,
  } = useReceiptList();

  const { showReceiptPDF } = usePayProcessReceipt();

  const uncheckAll = () => { };

  const isProductChecked = (receiptId, productId) => {
    console.log(
      'isProductChecked',
      receiptId && checkedList.products.includes(productId)
    );
    return (checkedList.id =
      receiptId && checkedList.products.includes(productId));
  };

  const checkUncheckProduct = (elemId, receiptId, productId) => {
    // elem.target.checked = false;
    //console.log('parent_b_e', elemId);
    const chbElem = document.getElementById(elemId);
    console.log('elem', chbElem);
    console.log('elemActive', document.activeElement === chbElem);
    if (document.activeElement !== chbElem) {
      chbElem.checked = !chbElem.checked;
    }
    const isChecked = chbElem.checked;
    console.log('elemChecked', chbElem.checked);
    let curCheckedList = checkedList;
    let lCheckbox;
    if (curCheckedList.id !== receiptId) {
      if (curCheckedList.id && curCheckedList.products.length) {
        curCheckedList.products.map((prodId) => {
          lCheckbox = document.getElementById(
            `chb_${curCheckedList.id}_${prodId}`
          );
          console.log('lCheckbox', lCheckbox);
          if (lCheckbox) {
            lCheckbox.checked = false;
          }
        });
      }
      curCheckedList.id = receiptId;
      curCheckedList.products = isChecked ? [productId] : [];
    } else {
      const prIndex = curCheckedList.products.indexOf(productId);
      if (!isChecked && prIndex > -1) {
        curCheckedList.products.splice(prIndex);
      } else if (isChecked && prIndex === -1) {
        curCheckedList.products.push(productId);
      }
    }
    console.log('curCheckedList', curCheckedList);
    setCheckedList(curCheckedList);
  };

  const handleReturnReceipt = () => {
    if (checkedList.id && checkedList.products.length > 0) {
      console.log('handleReturnReceipt->checkedList', checkedList);
      dispatch(returnReceipt(checkedList.id, checkedList.products));
    }
  };

  return (
    <div>
      <Button
        className={`me-md-2 ${classes.button}`}
        size='sm'
        onClick={(e) => {
          e.target.parentElement.focus();
          e.target.parentElement.blur();
          setModalShow(true)
        }}
      >
        <img className={classes.nav_icon} src={ChecklistIcon} alt='checklist' />
      </Button>
      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size='lg'
          centered
          scrollable={true}
        >
          {showLoader === true && (
            <img
              className='position-absolute top-50 start-50 translate-middle'
              src={Loader}
              style={{ width: '100px' }}
              alt=''
            />
          )}
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Продажі
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={classes.modal_body}>
            <Container>
              <Row>
                <Col>
                  <InputGroup>
                    <FormControl
                      placeholder='№ чека'
                      aria-describedby='checklist-search-btn'
                      onChange={delay(triggerSearch, 500)}
                    />

                    <Button
                      id='checklist-search-btn'
                      variant='outline-secondary'
                    >
                      <img
                        className={classes.search_icon}
                        src={SearchIcon}
                        alt='search'
                      />
                    </Button>
                  </InputGroup>
                </Col>
                <Col>
                  <DatePicker
                    className='form-control'
                    dateFormat='dd.MM.yyyy'
                    selected={startDate}
                    onChange={(date) => {
                      triggerSearchByDate(date, endDate);
                    }}
                  />
                </Col>
                <Col>
                  <DatePicker
                    className='form-control'
                    dateFormat='dd.MM.yyyy'
                    selected={endDate}
                    onChange={(date) => {
                      triggerSearchByDate(startDate, date);
                    }}
                  />
                </Col>
              </Row>
            </Container>

            <Accordion className='mt-1' flush>
              {checkDataHistory && Object.keys(checkDataHistory).length > 0 && (
                <Accordion.Item eventKey={'history-total'}>
                  <Accordion.Header className={classes.header} as='div'>
                    Чеків{' '}
                    {checkDataHistory.total.countSale +
                      checkDataHistory.total.countReturn}{' '}
                    На суму:{' '}
                    {formatMoney(
                      checkDataHistory.total.sumSaleCash +
                      checkDataHistory.total.sumSaleCard -
                      (checkDataHistory.total.sumReturnCard +
                        checkDataHistory.total.sumReturnCash)
                    )}{' '}
                    грн
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table className={classes.table} size='sm'>
                      <thead>
                        <tr>
                          <th className='col-1'>Операція</th>
                          <th className='col-1'>Готівка</th>
                          <th className='col-1'>Карта</th>
                          <th className='col-1'>Всього</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Продажа - {checkDataHistory.total.countSale}</td>
                          <td className={classes.price}>
                            {formatMoney(checkDataHistory.total.sumSaleCash)}
                          </td>
                          <td className={classes.price}>
                            {formatMoney(checkDataHistory.total.sumSaleCard)}
                          </td>
                          <td className={classes.price}>
                            {formatMoney(
                              checkDataHistory.total.sumSaleCash +
                              checkDataHistory.total.sumSaleCard
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>(знижка)</td>
                          <td className={classes.price}>
                            {formatMoney(
                              checkDataHistory.total.sumDiscountSaleCash
                            )}
                          </td>
                          <td className={classes.price}>
                            {formatMoney(
                              checkDataHistory.total.sumDiscountSaleCard
                            )}
                          </td>
                          <td className={classes.price}>
                            {formatMoney(
                              checkDataHistory.total.sumDiscountSaleCash +
                              checkDataHistory.total.sumDiscountSaleCard
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Повернення - {checkDataHistory.total.countReturn}
                          </td>
                          <td className={classes.price}>
                            {formatMoney(checkDataHistory.total.sumReturnCash)}
                          </td>
                          <td className={classes.price}>
                            {formatMoney(checkDataHistory.total.sumReturnCard)}
                          </td>
                          <td className={classes.price}>
                            {formatMoney(
                              checkDataHistory.total.sumReturnCash +
                              checkDataHistory.total.sumReturnCard
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>(знижка)</td>
                          <td className={classes.price}>
                            {formatMoney(
                              checkDataHistory.total.sumDiscountReturnCash
                            )}
                          </td>
                          <td className={classes.price}>
                            {formatMoney(
                              checkDataHistory.total.sumDiscountReturnCard
                            )}
                          </td>
                          <td className={classes.price}>
                            {formatMoney(
                              checkDataHistory.total.sumDiscountReturnCash +
                              checkDataHistory.total.sumDiscountReturnCard
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.totalCaptionBold}>
                            Всього
                          </td>
                          <td className={classes.priceB}>
                            {formatMoney(checkDataHistory.total.sumSaleCash - checkDataHistory.total.sumReturnCash)}
                          </td>
                          <td className={classes.priceB}>
                            {formatMoney(checkDataHistory.total.sumSaleCard - checkDataHistory.total.sumReturnCard)}
                          </td>
                          <td className={classes.priceB}>
                            {formatMoney(
                              checkDataHistory.total.sumSaleCash +
                              checkDataHistory.total.sumSaleCard -
                              (checkDataHistory.total.sumReturnCard +
                                checkDataHistory.total.sumReturnCash)
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              )}

              {checkDataHistory &&
                Object.keys(checkDataHistory).length > 0 &&
                checkDataHistory.receipts.length > 0 &&
                checkDataHistory.receipts.map((item) => {
                  //const item = checkDataHistory.receipts[itm];

                  return (
                    <Accordion.Item eventKey={'history-' + item.id} key={'history-' + item.id}>
                      <Accordion.Header className={classes.header} as='div'>
                        Чек{item.isReturn ? ' повернення' : ''} №{item.id} від{' '}
                        {formatDate(item.date)} Сума:{' '}
                        {formatMoney(item.sumTotal)} грн{' '}
                        {item?.sumPayment?.cash > 0 && <> <i className="bi bi-cash-coin" style={{ padding: '0 3px 0 10px' }} />{formatMoney(item.sumPayment.cash)} &nbsp;</>}
                        {item?.sumPayment?.nonCash > 0 && <> <i className="bi bi-credit-card" style={{ padding: '0 3px 0 3px' }} />{formatMoney(item.sumPayment.nonCash)} &nbsp;</>}
                      </Accordion.Header>
                      <Accordion.Body className='p-0'>
                        <Table className={classes.table} size='sm'>
                          <thead>
                            <tr key={item.id}>
                              {/* <th><input type="checkbox" className="form-check-input"/></th> */}
                              <th>
                                {' '}
                                {/* <Form.Check
                                inline
                                // label='1'
                                name='group1'
                                type={'checkbox'}
                                id={`inline-checkbox-1`}
                                defaultChecked={false}
                              /> */}
                              </th>
                              <th className='col-6'>Назва</th>
                              <th className='text-nowrap'>К-сть * Ціна</th>
                              <th>Сума</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(item.products).map(
                              (itProduct) => {
                                const checkProductItem =
                                  item.products[itProduct];
                                const checkProductInfo =
                                  item.products[itProduct];
                                return (
                                  <tr key={`${item.id}_${checkProductItem.id}`}>
                                    <td
                                      onClick={(e) =>
                                        checkUncheckProduct(
                                          `chb_${item.id}_${item.products[itProduct].productId}`,
                                          item.id,
                                          item.products[itProduct]
                                            .productId
                                        )
                                      }
                                    >
                                      {/* <input
                                      type='checkbox'
                                      className='form-check-input'
                                      onChange={() =>
                                        checkUncheckProduct(
                                          item.id,
                                          item.ReceiptProducts[itProduct]
                                            .Product.id
                                        )
                                      }
                                      // defaultChecked={false}
                                      checked={isProductChecked(
                                        item.id,
                                        item.ReceiptProducts[itProduct].Product
                                          .id
                                      )}
                                    /> */}
                                      <Form.Check
                                        inline
                                        // label='1'
                                        name='group1'
                                        type={'checkbox'}
                                        id={`chb_${item.id}_${item.products[itProduct].productId}`}
                                      // id={`inline-checkbox-1`}
                                      // onChange={(e) =>
                                      //   checkUncheckProduct(
                                      //     e,
                                      //     item.id,
                                      //     item.ReceiptProducts[itProduct]
                                      //       .Product.id
                                      //   )
                                      // }
                                      // checked={isProductChecked(
                                      //   item.id,
                                      //   item.ReceiptProducts[itProduct].Product
                                      //     .id
                                      // )}
                                      //checked={false}
                                      // readOnly={true}
                                      // onChange={(e) => {
                                      //   //e.preventDefault();
                                      //   console.log('b_e', e);
                                      //   e.target.checked = !e.target.checked;
                                      //   console.log('a_e', e);
                                      // }}
                                      />
                                    </td>
                                    <td className={classes.td}>
                                      {checkProductItem.name}
                                    </td>
                                    <td className={classes.td}>
                                      {checkProductInfo.count}{' '}
                                      {checkProductInfo.unit} *{' '}
                                      {formatMoney(checkProductItem.price)}
                                    </td>
                                    <td className={classes.price}>
                                      {formatMoney(checkProductInfo.sum)} грн
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                        <Button
                          className='ms-2 mb-2'
                          size='sm'
                          variant='outline-secondary'
                          onClick={(event) => {
                            handleReturnReceipt();
                            setModalShow(false);
                          }}
                        >
                          Повернути
                        </Button>
                        <Button
                          className='ms-2 mb-2'
                          size='sm'
                          variant='outline-secondary'
                          onClick={(event) => {
                            //dispatch(setReceiptPrintCopy(item));
                            showReceiptPDF(item, true);
                            //setModalShow(false);
                          }}
                        >
                          Повторний друк
                        </Button>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default NavReceiptlist;
