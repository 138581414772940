// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FiscalRegistrars_btn__Opmd\\+ {\n  width: auto;\n  font-size: var(--font-size-fs12);\n  padding: 5px 10px;\n}\n.FiscalRegistrars_rightTextElem__7-8yK {\n  text-align: end;\n}\n\n.FiscalRegistrars_headerTextEnd__bEo0Y {\n  text-align: end;\n}\n\n@media (min-width: 435px) {\n  .FiscalRegistrars_btn__Opmd\\+ {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/AdminPanel/FiscalRegistrars/FiscalRegistrars.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gCAAgC;EAChC,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".btn {\n  width: auto;\n  font-size: var(--font-size-fs12);\n  padding: 5px 10px;\n}\n.rightTextElem {\n  text-align: end;\n}\n\n.headerTextEnd {\n  text-align: end;\n}\n\n@media (min-width: 435px) {\n  .btn {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "FiscalRegistrars_btn__Opmd+",
	"rightTextElem": "FiscalRegistrars_rightTextElem__7-8yK",
	"headerTextEnd": "FiscalRegistrars_headerTextEnd__bEo0Y"
};
export default ___CSS_LOADER_EXPORT___;
