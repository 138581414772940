import { useState, useEffect } from 'react';
import { fetcherAsync } from '../../../helpers/fetcherAsync';
import { configApiLinks } from '../../../configApiLinks';

// import { useDispatch, useSelector, shallowEqual } from 'react-redux';

export const useFRsControls = (inShiftData = null) => {
  const [shiftData, setShiftData] = useState(inShiftData ? inShiftData : {});
  const [isLoading, setIsLoading] = useState(false);
  const [frData, setFrData] = useState(null);

  const setReceiptUpdParams = (updShiftData, frData, updData) => {
    for (const curFr of updShiftData.shift.fiscalRegistrars) {
      if (curFr.id === frData.id) {
        Object.keys(updData).map((objKey) => {
          curFr[objKey] = updData[objKey];
        });
        break;
      }
    }
  };

  useEffect(() => {
    getShiftData();
  }, []);

  const getShiftData = async () => {
    setIsLoading(true);
    let curShiftData = {};
    try {
      curShiftData = await fetcherAsync(configApiLinks.getCheckData, 'GET');
    } catch (error) {
      //shiftData = {};
    }
    setIsLoading(false);
    setShiftData(curShiftData);
  };

  const openShift = async (frData) => {
    setIsLoading(true);
    let curShiftData = { ...shiftData };
    setReceiptUpdParams(curShiftData, frData, {
      updOpenShiftIsError: false,
      updOpenShift: true,
    });
    try {
      curShiftData = await fetcherAsync(configApiLinks.openShift, 'POST', {
        fiscalRegistrarId: frData.id,
      });
    } catch (error) {
      curShiftData = { ...shiftData };
      setReceiptUpdParams(curShiftData, frData, {
        updOpenShiftIsError: true,
        errorMessage: error.message,
      });
    }
    setReceiptUpdParams(curShiftData, frData, {
      updOpenShift: false,
    });
    setIsLoading(false);
    setShiftData(curShiftData);
  };

  const closeShift = async (frData) => {
    setIsLoading(true);
    let curShiftData = { ...shiftData };
    setReceiptUpdParams(curShiftData, frData, {
      updCloseShiftIsError: false,
      updCloseShift: true,
    });
    try {
      curShiftData = await fetcherAsync(configApiLinks.closeShift, 'POST', {
        fiscalRegistrarId: frData.id,
      });
    } catch (error) {
      curShiftData = { ...shiftData };
      setReceiptUpdParams(curShiftData, frData, {
        updCloseShiftIsError: true,
        errorMessage: error.message,
      });
    }
    showZReportPDF(frData);
    setReceiptUpdParams(curShiftData, frData, {
      updCloseShift: false,
    });
    setIsLoading(false);
    setShiftData(curShiftData);
  };

  const xReport = async (frData) => {
    setIsLoading(true);
    let curShiftData = { ...shiftData };
    setReceiptUpdParams(curShiftData, frData, {
      updXReportIsError: false,
      updXReport: true,
    });
    try {
      const xReportResponse = await fetcherAsync(configApiLinks.xReport, 'POST', {
        fiscalRegistrarId: frData.id,
      });
      if (xReportResponse.is_error) {
        setReceiptUpdParams(curShiftData, frData, {
          updXReportIsError: true,
          errorMessage: xReportResponse.error,
        });
      } else {
        showXReportPDF(frData);
      }
    } catch (error) {
      setReceiptUpdParams(curShiftData, frData, {
        updXReportIsError: true,
        errorMessage: error.message,
      });
    }
    setReceiptUpdParams(curShiftData, frData, {
      updXReport: false,
    });
    setIsLoading(false);
    setShiftData(curShiftData);
  };

  const getFrData = async (elemId) => {
    setIsLoading(true);
    let curFrData = { ...frData };
    try {
      curFrData = await fetcherAsync(configApiLinks.frGetData, 'POST', {
        fiscalRegistrarId: elemId,
      });
    } catch (error) {
      curFrData = { ...frData };
    }
    setFrData(curFrData);
    setIsLoading(false);
  }

  const createUpdateFrData = async (newFrData) => {
    try {
      await fetcherAsync(configApiLinks.frUpdateData, 'POST', newFrData);
    } catch (error) {
      //curFrData = { ...frData };
    }
  }

  const showXReportPDF = async (frData) => {
    if (frData?.frPrintType === 'PDFFILE' && frData?.printer) {
      try {
        // const pdfWindow = window.open();
        // pdfWindow.location.href = `https://drive.google.com/viewerng/viewer?embedded=true&url=${config.apiUrl}${configApiLinks.printReceiptGetPdf}/${receiptData.id}`;
        // console.log('PDFFILE_START_RECEIPT_PRINT');
        const responseData = await fetcherAsync(configApiLinks.printXReportGetPdf, 'POST', { frId: frData.id }, true);
        const file = new Blob([await responseData.blob()], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "PRINT");
        
      } catch (error) {
        console.log('printError', error.message);
      }
      
    }
  }

  const showZReportPDF = async (frData) => {
    if (frData?.frPrintType === 'PDFFILE' && frData?.printer) {
      try {
        // const pdfWindow = window.open();
        // pdfWindow.location.href = `https://drive.google.com/viewerng/viewer?embedded=true&url=${config.apiUrl}${configApiLinks.printReceiptGetPdf}/${receiptData.id}`;
        // console.log('PDFFILE_START_RECEIPT_PRINT');
        const responseData = await fetcherAsync(configApiLinks.printZReportGetPdf, 'POST', { frId: frData.id }, true);
        const file = new Blob([await responseData.blob()], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "PRINT");
        
      } catch (error) {
        console.log('printError', error.message);
      }
      
    }
  }

  return [shiftData, isLoading, getShiftData, openShift, closeShift, xReport, frData, getFrData, createUpdateFrData];
};
