import React, { useState, useEffect } from 'react';
import classes from './nav_discount.module.css';
import { Button } from 'react-bootstrap';

import {
  DISPLAY_MODAL,
  // HIDE_MODAL,
  clearReceiptProducts,
} from '../../actions/checks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import ClearIcon from '../../images/clear-icon.svg';
import ModalClearProducts from '../modals/modal_clear_products';

function NavClearProducts() {
  const dispatch = useDispatch();
  const [clearProductsModalParams, setClearProductsModalParams] = useState({
    isActive: false,
  });
  console.log(clearProductsModalParams);
  return (
    <>
      <Button
        className={classes.button}
        size='sm'
        onClick={(e) => {
          e.target.parentElement.focus();
          e.target.parentElement.blur(); 
          setClearProductsModalParams({
            isActive: true,
          });
          dispatch({ type: DISPLAY_MODAL });
        }}
      >
        <img className={classes.nav_icon} src={ClearIcon} alt='' />
      </Button>
      <>{clearProductsModalParams.isActive}</>
      {clearProductsModalParams.isActive && (
        <ModalClearProducts
          show={clearProductsModalParams.isActive}
          onConfirm={() => {
            setClearProductsModalParams({
              isActive: false,
            });
            dispatch(clearReceiptProducts());
          }}
          // onHide={() => {
          //   setClearProductsModalParams({
          //     isActive: false,
          //   });
          // }}
          onCancel={() => {
            setClearProductsModalParams({
              isActive: false,
            });
          }}
          centered
        />
      )}
    </>
  );
}

export default NavClearProducts;
