import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button, Modal, Table, Form, Row, Col } from 'react-bootstrap';
import classes from './modal_frs.module.css';
// import { DISPLAY_MODAL, HIDE_MODAL, getCheckData } from '../../actions/checks';
// import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { formatDate, formatMoney } from '../../helpers/dataFormatter';
import { useFRsControls } from './hooks/useFRsControls';
import ModalConfirmSimple from './ModalConfirmSimple';

export default function ModalFrsControls(props) {
  const [fiscalRegistrarId, setFiscalRegistrarId] = useState(0);
  const [shiftData, isLoading, getShiftData, openShift, closeShift, xReport] = useFRsControls(props.shiftData);
  // const dispatch = useDispatch();
  const [modalConfirmParams, setModalConfirmParams] = useState({isActive: false, title: '', mainText: '', cancelButtonTitle: '', confirmButtonTitle: '', onConfirm: null, onCancel: null });
  return (
    <Fragment>
    <Modal
      show={!modalConfirmParams.isActive}
      onHide={() => {
        // setModalShow(false);
        // dispatch({ type: HIDE_MODAL });
        props.closeModal();
      }}
      size='md'
      centered
    >
      <Modal.Header>
        <Modal.Title>Управління ФР</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Table size='sm'>
            <thead>
              <tr key={'pay-list-head'} className={classes.head}>
                <th className='col-6'>Фіскальний реєстратор</th>
                <th className='text-end text-nowrap'>Зміна</th>
              </tr>
            </thead>
            <tbody>
              {shiftData &&
                shiftData.shift &&
                shiftData.shift.fiscalRegistrars &&
                shiftData.shift.fiscalRegistrars.length > 0 &&
                shiftData.shift.fiscalRegistrars.map(
                  (fiscalRegistrar) => {
                    return (
                      <Fragment key={fiscalRegistrar.id + 'pay-list-fr'}>
                        <tr className={classes.row}>
                          <td colSpan={1}>
                            <p className='mb-1'>{fiscalRegistrar.name}</p>
                          </td>
                          <td colSpan={1} className={classes.rightTextElem}>
                            {!(
                              fiscalRegistrar.frData &&
                              fiscalRegistrar.frData.cash_shift_info &&
                              fiscalRegistrar.frData.cash_shift_info.active
                            ) && (
                                <i
                                  className='bi bi-door-closed'
                                  style={{ fontSize: 28, color: 'grey' }}
                                ></i>
                              )}
                            {fiscalRegistrar.frData &&
                              fiscalRegistrar.frData.cash_shift_info &&
                              fiscalRegistrar.frData.cash_shift_info.active && (
                                <i
                                  className='bi bi-door-open'
                                  style={{ fontSize: 28, color: 'orange' }}
                                ></i>
                              )}
                          </td>
                        </tr>
                        {(fiscalRegistrar.updOpenShiftIsError ||
                          fiscalRegistrar.updCloseShiftIsError ||
                          fiscalRegistrar.updXReportIsError) && (
                            <tr className={classes.row}>
                              <td colSpan={2}>
                                <p className='mb-1 text-danger'>{fiscalRegistrar.errorMessage}</p>
                              </td>
                            </tr>
                          )}
                        <tr className={classes.row}>
                          <td colSpan={2}>
                            {/* <p className='mb-1'>{fiscalRegistrar.name}</p> */}
                            <Button
                              variant='secondary'
                              onClick={() => {
                                openShift(fiscalRegistrar);
                              }}
                              disabled={
                                (fiscalRegistrar.frData &&
                                  fiscalRegistrar.frData.cash_shift_info &&
                                  fiscalRegistrar.frData.cash_shift_info.active) || (isLoading && shiftData.updOpenShift)
                              }
                              size='sm'
                            >
                              Відкрити зміну
                            </Button>{' '}
                            <Button
                              variant='success'
                              onClick={() => {
                                setModalConfirmParams({
                                  isActive: true, 
                                  title: 'Закриття зміни', 
                                  mainText: 'Ви дійсно бажаєте зробити денний звіт?', 
                                  cancelButtonTitle: 'Відміна', 
                                  confirmButtonTitle: 'Ок', 
                                  onConfirm: ()=>{setModalConfirmParams({...modalConfirmParams, isActive: false}); closeShift(fiscalRegistrar)}, 
                                  onCancel: ()=>{setModalConfirmParams({...modalConfirmParams, isActive: false})} 
                                });
                                ;
                              }}
                              disabled={
                                !(
                                  fiscalRegistrar.frData &&
                                  fiscalRegistrar.frData.cash_shift_info &&
                                  fiscalRegistrar.frData.cash_shift_info.active
                                ) || (isLoading && shiftData.updCloseShift)
                              }
                              size='sm'
                            >
                              {`${(isLoading && shiftData.updCloseShift) ? '...' : ''}Z-звіт`}
                            </Button>{' '}
                            <Button
                              variant='info'
                              onClick={() => {
                                xReport(fiscalRegistrar);
                              }}
                              disabled={
                                !(
                                  fiscalRegistrar.frData &&
                                  fiscalRegistrar.frData.cash_shift_info &&
                                  fiscalRegistrar.frData.cash_shift_info.active
                                ) || (isLoading && shiftData.updXReport)
                              }
                              size='sm'
                            >
                              {`${(isLoading && shiftData.updXReport) ? '...' : ''}Х-звіт`}
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  }
                )}
            </tbody>
          </Table>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-secondary'
          onClick={() => {
            props.closeModal();
          }}
        >
          Закрити
        </Button>
      </Modal.Footer>
    </Modal>
    {modalConfirmParams.isActive && 
      <ModalConfirmSimple 
        title={modalConfirmParams.title} 
        mainText={modalConfirmParams.mainText} 
        cancelButtonTitle={modalConfirmParams.cancelButtonTitle} 
        confirmButtonTitle={modalConfirmParams.confirmButtonTitle} 
        onConfirm={modalConfirmParams.onConfirm} 
        onCancel={modalConfirmParams.onCancel}
      />
    }
    </Fragment>
  );
}