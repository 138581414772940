import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import classes from './footer.module.css';
import Payment from './footer_payment';
import Checkout from './footer_checkout';
import FooterTotal from './footer_total';
import FooterFrInOut from './footer_fr_in_out';
import FooterFrControls from './footer_fr_controls';
import ReturnInfo from './footer_return_info';
import { useWindowSize } from '../hooks/useWindowSize';

function Footer({ checkData }) {
  const { isNonMobile } = useWindowSize();
  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        {checkData && checkData.receipt && checkData.receipt.isReturn && (
          <Row >
            <ReturnInfo />
          </Row>
        )}
        <Row className={classes.rowBig}>
          {/* <Col xs={6} className={classes.colLeft} > */}
          <Col xs={6}>
            <div className={classes.colLeft} >
              {isNonMobile && (
                <Col xs={2} sm={2} md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <FooterFrControls checkData={checkData} />
                </Col>
              )}
              <Col xs={12} style={{ width: 'fit-content' }}>
                <FooterFrInOut checkData={checkData} >
                  {!isNonMobile && (
                    <FooterFrControls checkData={checkData} />
                  )}
                </FooterFrInOut>
                {/* {isNonMobile && (
                  <Row className=' mb-1'>
                    <p></p>
                  </Row>
                )} */}
                <Payment checkData={checkData} />
              </Col>
            </div>
          </Col>
          <Col xs={6} className={classes.colRight}>
            {/* <div className={classes.colRight}> */}
            <Col xs={6} className={classes.colBlock}>
              <FooterTotal checkData={checkData} />
              {/* <Checkout /> */}
            </Col>
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
