// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_discount_main_price__AqLKb{\n    font-size: var(--font-size-fs20);\n    font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/components/modals/modal_discount_main.module.css"],"names":[],"mappings":"AAEA;IACI,gCAAgC;IAChC,gBAAgB;AACpB","sourcesContent":["@import '../../indexVars.css';\n\n.price{\n    font-size: var(--font-size-fs20);\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price": "modal_discount_main_price__AqLKb"
};
export default ___CSS_LOADER_EXPORT___;
