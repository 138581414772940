import React, { useEffect, useState } from 'react';
import { fetcher } from '../../../helpers/fetcher';
import { configApiLinks } from '../../../configApiLinks';

export const useReceiptList = () => {
  const [modalShow, setModalShow] = useState(false);
  const [checkDataHistory, setCheckDataHistory] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    if (modalShow === true) {
      fetcher(configApiLinks.getChecksHistory, 'POST', {
        date: Date.parse(startDate),
        dateEnd: Date.parse(endDate),
      }).then((data) => {
        setCheckDataHistory(data);
        setShowLoader(false);
      });
    }
  }, [modalShow]);

  const triggerSearch = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);
    setShowLoader(true);
    fetcher(configApiLinks.getChecksHistory, 'POST', {
      sphrase: searchText,
    }).then((data) => {
      setShowLoader(false);
      setCheckDataHistory(data);
    });
  };

  const triggerSearchByDate = (date, dateEnd) => {
    setStartDate(date);
    setEndDate(dateEnd);
    setShowLoader(true);
    fetcher(configApiLinks.getChecksHistory, 'POST', {
      date: Date.parse(date),
      dateEnd: Date.parse(dateEnd),
    }).then((data) => {
      setShowLoader(false);
      setCheckDataHistory(data);
    });
  };

  // here we put
  const delay = (callback, ms) => {
    let timer = 0;
    // return function because keyup require function
    return function () {
      let context = this;
      let args = arguments;
      // if we put something again clear timer (do not wait for result function below) amd start timer again
      clearTimeout(timer);
      timer = setTimeout(function () {
        // runs function on key up ajax send format url etc...
        callback.apply(context, args);
      }, ms);
    };
  };
  return {
    delay,
    triggerSearchByDate,
    triggerSearch,
    modalShow,
    checkDataHistory,
    showLoader,
    searchText,
    startDate,
    endDate,
    setModalShow,
  };
};
