import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { config } from '../config';

/**
 * Scan on dom with keypress. Waiting keypress and execute methods with/without redux dispatch
 *
 * @param requeiredLength
 * @param isEnableScan
 * @param arrayConditions
 * @param methodToExecWithReceivedData
 * @return {string}
 */
export const useScanWaiting = (
  requeiredLength,
  isEnableScan,
  arrayConditions,
  ...methodToExecWithReceivedData
) => {
  const [scannedData, setScannedData] = useState(null);
  const dispatch = useDispatch();
  let code = null;
  let timeStart = null;
  let timeoutSet = false;
  let inputTime = null;
  arrayConditions.unshift(isEnableScan);
  useEffect(() => {
    const handleScannedCode = () => {
      code = code.substr(0, requeiredLength);
      console.log('useScanWaiting->debug->handleScannedCode->code', code);
      setScannedData(code);
      methodToExecWithReceivedData.forEach((mthd) => {
        if (mthd.dispatch) {
          dispatch(mthd.mthd(code));
        } else {
          mthd.mthd(code);
        }
      });
      console.log('useScanWaiting->debug->clearCode');
      code = null;
    };

    const scanWaiting = (event) => {
      //event.preventDefault();
      if (event.target.tagName.toLowerCase() !== 'input' && event.key?.length === 1)  {
        //event.key !== 'Enter'
        //console.log('useScanWaiting->debug->event.key', event.key);
        inputTime = timeStart ? Date.now() - timeStart : 1;
        if (!code || inputTime > config.scanCodeMax) {
          timeStart = Date.now();
          code = event.key;
          timeoutSet = false;
        } else {
          code += event.key;
        }
        if (code.length >= 8 && !timeoutSet) {
          timeoutSet = true;
          //console.log('useScanWaiting->debug->inputTime', { inputTime, timeStart });
          setTimeout(() => {
            //console.log('useScanWaiting->debug->setTimeout', event.key);
            console.log('useScanWaiting->debug->setTimeout->inputTime', { inputTime, timeStart });
            if (
              code &&
              code.length >= 8 &&
              inputTime <= config.scanCodeMax + 10
            ) {
              handleScannedCode();
            } else {
              console.log('useScanWaiting->debug->clearCode');
              code = null;
            }
            timeStart = null;
            inputTime = null;
          }, config.scanCodeMax + 200);
        }
        try {
          code = code.trim().replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
          code = code.replace(/[\r\n]+/g, '');
          code = code.replace('~', '');
          console.log('useScanWaiting->debug->ScannedCode', code);
        } catch (error) {}
        //console.log('inputTime', inputTime);
        //console.log({ code, length: code.length, inputTime, requeiredLength });
        if (
          code.length >= requeiredLength &&
          inputTime < config.scanCodeMax &&
          inputTime > config.scanCodeMin
        ) {
          inputTime = null;
          handleScannedCode();
        } else if (inputTime > config.scanCodeMax) {
          //console.log('useScanWaiting->debug->clearCode->inputTime', inputTime);
          console.log('useScanWaiting->debug->clearCode');
          code = null;
          inputTime = null;
          timeoutSet = true;
        }
      }
    };

    if (isEnableScan) {
      document.addEventListener('keypress', scanWaiting, false);
    } else {
      document.removeEventListener('keypress', scanWaiting, false);
    }

    return () => {
      document.removeEventListener('keypress', scanWaiting, false);
    };
  }, arrayConditions);
  return scannedData;
};
