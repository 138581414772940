import React, { useState, useEffect, useRef } from 'react';
import { Button, ButtonGroup, Modal, InputGroup, FormControl } from 'react-bootstrap';
import SearchIcon from '../../images/search-icon.svg';
import classes from './NumericKeyboard.module.css';

const NumericKeyboard = ({ setValue, handleSubmit, floatPoint }) => {
    const [currentValue, setCurrentValue] = useState('');
    return (<div role="group" aria-label="Basic example" className={`${classes.wrap} btn-group-vertical ml-4 mt-4`}>
        <ButtonGroup className={classes.btnGroup}>
            <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '1'); setValue(currentValue + '1'); }}>1</Button>
            <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '2'); setValue(currentValue + '2'); }}>2</Button>
            <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '3'); setValue(currentValue + '3'); }}>3</Button>
        </ButtonGroup>
        <ButtonGroup className={classes.btnGroup}>
            <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '4'); setValue(currentValue + '4'); }}>4</Button>
            <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '5'); setValue(currentValue + '5'); }}>5</Button>
            <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '6'); setValue(currentValue + '6'); }}>6</Button>
        </ButtonGroup>
        <ButtonGroup className={classes.btnGroup}>
            <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '7'); setValue(currentValue + '7'); }}>7</Button>
            <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '8'); setValue(currentValue + '8'); }}>8</Button>
            <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '9'); setValue(currentValue + '9'); }}>9</Button>
        </ButtonGroup>
        <ButtonGroup className={classes.btnGroup}>
            <Button variant="warning" className={`${classes.btnSpecial} py-3`} onClick={(e) => { setCurrentValue(currentValue.slice(0, -1)); setValue(currentValue.slice(0, -1)); }}><b>&lt;</b></Button>
            <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '0'); setValue(currentValue + '0'); }}>0</Button>
            <Button variant="primary" className={`${classes.btnSpecial} py-3`} onClick={(e) => { setCurrentValue(''); setValue(''); }}><b>C</b></Button>
            {/* <Button variant="outline-secondary" className="py-3" onClick={(e) => { setCurrentValue(''); setValue(''); }}>C</Button> */}
        </ButtonGroup>
        {floatPoint &&
            <ButtonGroup className={classes.btnGroup}>
                <Button variant="secondary" className={`${classes.btnElem} py-3`} disabled>{' '}</Button>
                <Button variant="outline-secondary" className={`${classes.btnElem} py-3`} onClick={(e) => { setCurrentValue(currentValue + '.'); setValue(currentValue + '.'); }}>.</Button>
                <Button variant="secondary" className={`${classes.btnElem} py-3`} disabled>{' '}</Button>
            </ButtonGroup>
        }
        {handleSubmit &&
            <ButtonGroup className={classes.btnGroup}>
                <Button variant="info" className={`${classes.btnElem3} py-3`} onClick={(e) => { handleSubmit(); setCurrentValue('') }}>
                    <img
                        className={classes.search_icon}
                        src={SearchIcon}
                        alt='search'
                    />
                </Button>
            </ButtonGroup>
        }
    </div>)
}

export default NumericKeyboard;