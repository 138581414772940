import { React, useState, useRef, useEffect } from 'react';
import classes from './modal_discount_main.module.css';
import {
  Modal,
  Row,
  InputGroup,
  FormControl,
  Button,
  Col,
} from 'react-bootstrap';
// import { HIDE_MODAL, setDiscountManual } from '../../actions/checks';
// import { useDispatch, useSelector, shallowEqual } from 'react-redux';

function ModalDiscountMain(props) {
  const [discountPercentage, setDiscountPercentage] = useState(200);
  //const [inputSum, setInputSum] = useState(200);
  const refInputPercentage = useRef('');
  const [discountSum, setDiscountSum] = useState(0);
  const [sumResult, setSumResult] = useState(0);
  useEffect(() => {
    if (refInputPercentage.current) {
      refInputPercentage.current.focus();
      refInputPercentage.current.select();
    }
  }, []);

  console.log('props', props);

  const handleDiscountPercentage = (event) => {
    console.log('handleDiscountPercentage', event);
    //event.preventDefault();
    const discountPercentage_ = parseFloat(
      !event.target.value || event.target.value.trim() === ''
        ? 0
        : event.target.value
    );
    const discountSum_ = parseFloat(
      (props.sum * (discountPercentage_ / 100)).toFixed(2)
    );
    setDiscountPercentage(discountPercentage_);
    setDiscountSum(discountSum_);
    setSumResult(props.sum - discountSum_);
  };

  return (
    <>
      <Modal {...props}>
        <Modal.Header closeButton>
          <Modal.Title>Знижка</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='align-items-center'>
            <Col className='fw-bold'>Сума</Col>
            <Col className={classes.price}> {props.sum.toFixed(2)} грн.</Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col className='fw-bold'>Відсоток знижки</Col>
            <Col>
              <InputGroup>
                <FormControl
                  ref={refInputPercentage}
                  type='text'
                  // min='0'
                  // max='100'
                  inputMode='numeric'
                  onChange={handleDiscountPercentage}
                  placeholder='0'
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      props.onConfirm(discountPercentage);
                    }
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col className='fw-bold'>Сума знижки</Col>
            <Col>
              {/* <InputGroup>
                <FormControl />
              </InputGroup> */}
              <Col className={classes.price}>
                {' '}
                {discountSum.toFixed(2)} грн.
              </Col>
            </Col>
          </Row>
          {/* <Row className='mt-3 align-items-center'>
            <Col className='fw-bold'>Сума зі знижкою</Col>
            <Col className={classes.price}> {sumResult} грн.</Col>
          </Row> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              console.log('Cancel');
              props.onCancel();
            }}
          >
            Закрити
          </Button>
          <Button
            variant='success'
            onClick={() => {
              props.onConfirm(discountPercentage);
            }}
          >
            Застосувати
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalDiscountMain;
