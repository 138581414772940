import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  //addProductToCheck,
  //DISPLAY_MODAL,
  getCheckData,
  REMOVE_ERROR,
} from '../../actions/checks';
// import ModalCount from './modals/Modal-Count';
// import CheckList from './Check.list';
// import ControlCheck from './ControlCheck';
// import { useScanWaiting } from '../syst/Scanning';
// import ModalAddCode from './modals/Modal-AddCode';
// import HeaderButtonWrapper from './HeaderButtonWrapper';
// import ModalUserInfo from './modals/Modal-UserInfo';
// import ModalCheckDone from './modals/Modal-CheckDone';
import { Container, Toast, ToastContainer, Nav, Navbar, ListGroup, Tab, Row, Col } from 'react-bootstrap';

// import classes from './check.module.css';
// import Header from './Header/header';
// import Footer from './Footer/footer';
// import CheckProductlist from './CheckList/checklist';

//import Loading from './Loading';
//import './style.css';
import classes from './AdminMain.module.css';
import NavProfile from '../Header/nav_profile';
import FiscalRegistrarWrap from './FiscalRegistrars/FiscalRegistrarWrap';
import Checks from '../Check';
//import NavAddGood from '../Header/nav_add_good';
import ProductsList from './Products/ProductsList';

const sectionsList = {
  FISCAL_REGISTRARS: { name: 'FISCAL_REGISTRARS', 'caption': 'Фіскальні реєстратори' },
  PRICE_CHANGE: { name: 'PRICE_CHANGE', 'caption': 'Зміна цін' },
  PRODUCTS_RETURN: { name: 'PRODUCTS_RETURN', 'caption': 'Продаж / Повернення' }
};

const AdminMain = () => {
  const dispatch = useDispatch();
  const checkData = useSelector(
    (state) => state.actions.checkData,
    shallowEqual
  );
  const isShowLoader = useSelector(
    (state) => state.actions.isShowLoader,
    shallowEqual
  );
  const user = useSelector((state) => state.users.user.token);
  const hideAnyModal = useSelector((state) => state.actions.hideAnyModal);
  const sysAPIerror = useSelector((state) => state.actions.sysAPIerror);

  const [activeSection, setActiveSection] = useState(sectionsList.FISCAL_REGISTRARS.name);

  // useScanWaiting(13, hideAnyModal, [], { dispatch, mthd: addProductToCheck });
  console.log('user', user);
  useEffect(() => {
    dispatch(getCheckData());
  }, [dispatch, user]);

  useEffect(() => {

  }, [activeSection]);

  return (
    <Container className={`p-0 ${classes.containerWrap}`}>
      {/* {isShowLoader && <Loading />} */}
      {sysAPIerror !== '' && (
        <ToastContainer className='p-3' position={'middle-center'}>
          <Toast
            onClose={() => {
              dispatch({ type: REMOVE_ERROR });
            }}
            bg={'danger'}
            show={sysAPIerror !== ''}
            delay={3000}
            autohide={true}
          >
            <Toast.Header closeButton>
              <strong className='me-auto'>Помилка</strong>
            </Toast.Header>
            <Toast.Body>{sysAPIerror}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}

      {/* <Header /> */}
      <div className={`${classes.wrapper} ${classes.dFlex}`}>
        {/* <!-- Sidebar--> */}
        <div className={`${classes.sidebarWrapper} ${classes.borderEnd} ${classes.bgWhite}`}>
          <ListGroup as="ul" variant="flush">
            <ListGroup.Item className={classes.listElem} as="li" active={activeSection === sectionsList.FISCAL_REGISTRARS.name} onClick={(e) => { setActiveSection(sectionsList.FISCAL_REGISTRARS.name) }}>{sectionsList.FISCAL_REGISTRARS.caption}</ListGroup.Item>
            <ListGroup.Item className={classes.listElem} as="li" active={activeSection === sectionsList.PRICE_CHANGE.name} onClick={(e) => { setActiveSection(sectionsList.PRICE_CHANGE.name) }}>{sectionsList.PRICE_CHANGE.caption}</ListGroup.Item>
            <ListGroup.Item className={classes.listElem} as="li" active={activeSection === sectionsList.PRODUCTS_RETURN.name} onClick={(e) => { setActiveSection(sectionsList.PRODUCTS_RETURN.name) }}>{sectionsList.PRODUCTS_RETURN.caption}</ListGroup.Item>
            {/* </div> */}
          </ListGroup>
        </div>
        {/* <!-- Page content wrapper--> */}
        <div className={`${classes.pageContentWrapper}`}>
          {/* <div className={`${classes.pageContentWrapper}`}> */}
          <Navbar bg="light">
            <Container fluid>
              <Nav>
                <h3>{sectionsList[activeSection].caption}</h3>
              </Nav>
              <NavProfile />
            </Container>
          </Navbar>
          {/* <!-- Page content--> */}
          <Container fluid>
            {(activeSection === sectionsList.FISCAL_REGISTRARS.name) && <FiscalRegistrarWrap />}
            {(activeSection === sectionsList.PRODUCTS_RETURN.name) && <Checks />}
            {(activeSection === sectionsList.PRICE_CHANGE.name) && <ProductsList priceEdit={true} />}
          </Container>
        </div>
      </div>
    </Container>
  );
}
export default AdminMain;