import { useState, useEffect } from 'react';
import { fetcherAsync } from '../../../helpers/fetcherAsync';
import { configApiLinks } from '../../../configApiLinks';

export const useGetDisplay2ReceiptData = () => {
    const [display2ReceiptData, setDisplay2ReceiptData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    let updInterval = 0;

    const startIntervalUpdate = () => {
        updInterval = setInterval(() => {
            getDisplay2ReceiptData();
        }, 500);
    };

    useEffect(() => {
        startIntervalUpdate();
        return () => { if (updInterval) { clearInterval(updInterval) } };
    }, []);

    const getDisplay2ReceiptData = async () => {
        setIsLoading(true);
        try {
            setDisplay2ReceiptData(await fetcherAsync(configApiLinks.display2ReceiptData, 'POST'));
        } catch (error) {
            clearInterval(updInterval);
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        }
        setIsLoading(false);
    };
    return [display2ReceiptData, getDisplay2ReceiptData, isLoading];
};
