import { config } from '../config';
import { handleResponse } from './responser';
import { store } from '../App';

/**
 *
 * @param url
 * @return Promise
 */
export const fetcher = (url, method, body = {}) => {
  // const formData = new FormData();
  // const reqData = {};
  // let hasBody = false;
  // console.log('formDataBody', Object.keys(body).length);
  // if (Object.keys(body).length > 0) {
  //   console.log('checkIn');
  //   Object.keys(body).forEach((propName) => {
  //     console.log('checkPropBT', propName);
  //     console.log('checkPropValue', body[propName]);
  //     if (typeof body[propName] !== 'object') {
  //       console.log('checkProp', propName);
  //       //formData.append(propName, body[propName]);
  //       reqData[propName] = body[propName];
  //     } else {
  //       console.log('checkObject');
  //       if (body[propName]) {
  //         Object.keys(body[propName]).forEach((prop) => {
  //           if (typeof body[propName][prop] !== 'object') {
  //             //formData.append(`${propName}[${prop}]`, body[propName][prop]);
  //             reqData[`${propName}[${prop}]`] = body[propName][prop];
  //           }
  //         });
  //       }
  //     }
  //   });
  //   hasBody = true;
  // }
  const hasBody = Object.keys(body).length;
  const user = store.getState().users.user;
  const headers =
    method == 'POST' || method == 'DELETE'
      ? {
          Authorization: 'Bearer ' + user.token,
          //'Content-Type': 'multipart/form-data',
          'Content-Type': 'application/json',
        }
      : {
          Authorization: 'Bearer ' + user.token,
          'Content-Type': 'application/json',
        };
  console.log('headers ', headers);
  const requestOptions = {
    method: method,
    headers: headers,
    //body: hasBody ? formData : null,
    //body: hasBody ? JSON.stringify(reqData) : null,
    body: hasBody ? JSON.stringify(body) : null,
  };

  return fetch(config.apiUrl + url, requestOptions).then((response) =>
    handleResponse(response, store.dispatch)
  );
};
