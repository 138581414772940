import React, { useState } from "react";
import classes from "./nav_profile.module.css";
import { NavDropdown } from 'react-bootstrap';
import UserIcon from "../../images/user-icon.svg";

import { useSelector, shallowEqual } from 'react-redux';
import {
  Link
} from "react-router-dom";


function NavProfile() {
  const user = useSelector(state => state.users.user, shallowEqual);
  return (
    <NavDropdown
      className={classes.nav_dropdown}
      align="end"
      title={
        <div className={classes.profile_icon}>
          <img className={classes.nav_icon} src={UserIcon} alt="barcode" />
        </div>
      }
      onClick={(e) => {
        e.target.blur();
      }}

    >
      <NavDropdown.Item>{user.login}</NavDropdown.Item>
      <NavDropdown.Item>{user.name}</NavDropdown.Item>
      <NavDropdown.Item>{user.shop}</NavDropdown.Item>
      <NavDropdown.Divider />

      <NavDropdown.Item href="#">
        <Link className="link-dark" to={"/logout"}>
          {"Вихід"}
        </Link>
      </NavDropdown.Item>
    </NavDropdown >
  );
}

export default NavProfile;
