import {useDispatch} from 'react-redux';
import React, {  useEffect } from 'react';
import {
    Navigate
} from "react-router-dom";
import {logout} from "../actions/users";


export default function Logout() {
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(logout());
    });

    return <Navigate to={"/"} />
}