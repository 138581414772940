import {handleResponse} from "../helpers/responser";
import {config} from '../config';

export const LOGGED_SUCCESS = 'LOGGED_SUCCESS';
export const LOGGED_OUT = 'LOGGED_OUT';
export const SET_ERROR = 'SET_ERROR';
export const LOGGED_IN = 'LOGGED_IN';
export const NOT_LOGGED = 'NOT_LOGGED';

export function login (username, password){
    return (dispatch, getState) => {
        let link = config.apiUrl + "users/login";
        // const formData  = new FormData();
        // formData.append("login", username);
        // formData.append("password",password);
        const reqData = { login: username, password };
        console.log("formData111111111 " , reqData, link);
        fetch(link, {
            method: 'POST',
            //body: formData,
            body: JSON.stringify(reqData),
            headers: {
                'Content-Type': 'application/json'
              }
        }).then( data => handleResponse(data,dispatch) ).
        then( user => {
            setUsersDataOnFront(user);
            dispatch({
                type: LOGGED_SUCCESS,
                user
            });
        }).
        catch( error => {
            dispatch({type: SET_ERROR, error})
        });
    }
}

export function logout() {
    localStorage.removeItem('user');
    return (dispatch, getState) => {
        dispatch({
            type: LOGGED_OUT,
        });
    }
}

function setUsersDataOnFront( data ) {
    localStorage.setItem('user', JSON.stringify(data));
}

/**
 * Restore auth if user data isn't present in global state.
 * Set data to global state.
 *
 * @return {function(...[*]=)}
 */
export function restoreAuth(){
    return (dispatch, getState) => {
        if ( Object.keys(getState().users.user).length === 0 ) {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user !== null){
                dispatch({type: LOGGED_IN, json:user});
            }else{
                dispatch({type: NOT_LOGGED});
            }
        }
    }
}