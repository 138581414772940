// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_count_edit_input__G6aFv{\n    font-size: var(--font-size-rm1);\n    width: var(--input-count-width);\n}\n", "",{"version":3,"sources":["webpack://./src/components/modals/modal_count_edit.module.css"],"names":[],"mappings":"AAEA;IACI,+BAA+B;IAC/B,+BAA+B;AACnC","sourcesContent":["@import '../../indexVars.css';\n\n.input{\n    font-size: var(--font-size-rm1);\n    width: var(--input-count-width);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "modal_count_edit_input__G6aFv"
};
export default ___CSS_LOADER_EXPORT___;
