// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nav_profile_nav_dropdown__acu2d a::after{\n    display: none;\n}\n.nav_profile_profile_icon__0bwcy{\n    width: var(--icon-button-size-26);\n}", "",{"version":3,"sources":["webpack://./src/components/Header/nav_profile.module.css"],"names":[],"mappings":"AAEA;IACI,aAAa;AACjB;AACA;IACI,iCAAiC;AACrC","sourcesContent":["@import '../../indexVars.css';\n\n.nav_dropdown a::after{\n    display: none;\n}\n.profile_icon{\n    width: var(--icon-button-size-26);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav_dropdown": "nav_profile_nav_dropdown__acu2d",
	"profile_icon": "nav_profile_profile_icon__0bwcy"
};
export default ___CSS_LOADER_EXPORT___;
