import { useState, useEffect } from "react";


export const useWindowSize = () => {
    const checkIsNoneMobile = () => { return (window.innerWidth > 768) };
    const [isNonMobile, setIsNonMobile] = useState(checkIsNoneMobile());
    const calcInnerWidth = () => {
        console.log('isNoneMobile', checkIsNoneMobile());
        setIsNonMobile(checkIsNoneMobile());
    };

    useEffect(() => {
        window.addEventListener('resize', calcInnerWidth);
        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return { isNonMobile };
}
