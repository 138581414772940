import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ModalConfirmSimple({title, mainText, cancelButtonTitle, confirmButtonTitle, onConfirm, onCancel}) {

    return (
        <Modal
        show={true}
        onHide={onCancel}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mainText}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            {cancelButtonTitle}
          </Button>
          <Button variant="primary" onClick={onConfirm}>{confirmButtonTitle}</Button>
        </Modal.Footer>
      </Modal>
    )
}