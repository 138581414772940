// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminMain_dFlex__LgY62 {\n  display: flex !important;\n}\n\n.AdminMain_wrapper__x6lsl {\n  /* overflow-x: hidden; */\n  width: 100%;\n}\n\n.AdminMain_containerWrap__Hz\\+u4 {\n  max-width: 100% !important;\n  max-height: 100% !important;\n}\n\n.AdminMain_sidebarWrapper__xmpRQ {\n  min-height: 100vh;\n  /* margin-left: -15rem; */\n  min-width: 28vh;\n  transition: margin 0.25s ease-out;\n}\n\n.AdminMain_borderEnd__fnc1A {\n  border-right: 1px solid #dee2e6 !important;\n}\n\n.AdminMain_bgWhite__5dXoe {\n  --bs-bg-opacity: 1;\n  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;\n}\n\n.AdminMain_pageContentWrapper__ah1fi {\n  width: 100%;\n}\n\n.AdminMain_mt4__IkYoX {\n  margin-top: 1.5rem !important;\n}\n\n.AdminMain_listElem__IsGmz:hover {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AdminPanel/AdminMain.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;EACxB,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,kBAAkB;EAClB,4EAA4E;AAC9E;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".dFlex {\n  display: flex !important;\n}\n\n.wrapper {\n  /* overflow-x: hidden; */\n  width: 100%;\n}\n\n.containerWrap {\n  max-width: 100% !important;\n  max-height: 100% !important;\n}\n\n.sidebarWrapper {\n  min-height: 100vh;\n  /* margin-left: -15rem; */\n  min-width: 28vh;\n  transition: margin 0.25s ease-out;\n}\n\n.borderEnd {\n  border-right: 1px solid #dee2e6 !important;\n}\n\n.bgWhite {\n  --bs-bg-opacity: 1;\n  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;\n}\n\n.pageContentWrapper {\n  width: 100%;\n}\n\n.mt4 {\n  margin-top: 1.5rem !important;\n}\n\n.listElem:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dFlex": "AdminMain_dFlex__LgY62",
	"wrapper": "AdminMain_wrapper__x6lsl",
	"containerWrap": "AdminMain_containerWrap__Hz+u4",
	"sidebarWrapper": "AdminMain_sidebarWrapper__xmpRQ",
	"borderEnd": "AdminMain_borderEnd__fnc1A",
	"bgWhite": "AdminMain_bgWhite__5dXoe",
	"pageContentWrapper": "AdminMain_pageContentWrapper__ah1fi",
	"mt4": "AdminMain_mt4__IkYoX",
	"listElem": "AdminMain_listElem__IsGmz"
};
export default ___CSS_LOADER_EXPORT___;
