import { useState } from 'react';
import { fetcherAsync } from '../../../helpers/fetcherAsync';
import { configApiLinks } from '../../../configApiLinks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DISPLAY_MODAL,
  getCheckData,
  REMOVE_ERROR,
} from '../../../actions/checks';

export const useExciseCodes = (parProductRow) => {

    const dispatch = useDispatch();
    const checkData = useSelector((state) => state.actions.checkData, shallowEqual);
    const [productRow, setProductRow] = useState(parProductRow);
    
    const reduxGetCheckData = () => {
        dispatch(getCheckData());
    }

    const findProductRowInReceipt = (shiftData, receiptId, productId) => {
        const receipt = shiftData.receipts.find(element => element.id == receiptId);
        if (!receipt) {
            return null;
        }
        const findedProductRow = receipt.products.find(element => (element.productId == productId));
        if (!findedProductRow) {
            return null;
        }
        console.log({ inData: {shiftData, receiptId, productId}, findedProductRow});
        return findedProductRow;
    }

    const addExciseCode = async (receiptId, productId, exciseCode) => {
        let curShiftData = {};
        try {
            curShiftData = await fetcherAsync(configApiLinks.receiptProductExciseAdd, 'POST', {
                receiptId,
                productId,
                exciseCode
            });
            let curProductRow = findProductRowInReceipt(curShiftData, receiptId, productId);
            if (curProductRow) {
                setProductRow(curProductRow);
            }
            reduxGetCheckData();
        } catch (error) {
          console.log('fiscReceiptError', error.message);
        }
    };

    const deleteExciseCode = async (receiptId, productId, exciseCode) => {
        let curShiftData = {};
        try {
            curShiftData = await fetcherAsync(configApiLinks.receiptProductExciseDelete, 'POST', {
                receiptId,
                productId,
                exciseCode
            });
            let curProductRow = findProductRowInReceipt(curShiftData, receiptId, productId);
            if (curProductRow) {
                setProductRow(curProductRow);
            }
            reduxGetCheckData();
        } catch (error) {
          console.log('fiscReceiptError', error.message);
        }
    };

    const setExciseCodes = async (receiptId, productId, exciseCodes) => {
        let curShiftData = {};
        try {
            curShiftData = await fetcherAsync(configApiLinks.receiptProductExciseSet, 'POST', {
                receiptId,
                productId,
                exciseCodes
            });
            let curProductRow = findProductRowInReceipt(curShiftData, receiptId, productId);
            if (curProductRow) {
                setProductRow(curProductRow);
            }
            reduxGetCheckData();
        } catch (error) {
          console.log('fiscReceiptError', error.message);
        }
    };

    return [productRow, addExciseCode, deleteExciseCode, setExciseCodes, reduxGetCheckData];

    //receiptProductExciseAdd: 'receipts/product/esciseadd',
    //receiptProductExciseDelete: 'receipts/product/escisedelete',
    //receiptProductExciseSet: 'receipts/product/esciseset'
}