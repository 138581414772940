import { React, useState, useEffect, useRef, Fragment } from 'react';
import {
  Col,
  Button,
  Modal,
  ListGroup,
  Form,
  Row,
  Table,
  Spinner,
} from 'react-bootstrap';
import { formatMoney } from '../../helpers/dataFormatter';
import classes from '../Footer/footer_payment.module.css';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {
  DISPLAY_MODAL,
  HIDE_MODAL,
  submitCheckPayment,
  getCheckData,
} from '../../actions/checks';
import { usePayProcessReceipt } from './hooks/usePayProcessReceipt';

const ModalPayment = (props) => {
  const refCash = useRef('');
  const [total, setTotal] = useState(parseFloat(0.1));
  const [cash, setCash] = useState(null);
  const [change, setChange] = useState(0);
  const [allowSubmitPayment, setAllowSubmitPayment] = useState(true);
  const dispatch = useDispatch();
  const checkData = useSelector(
    (state) => state.actions.checkData,
    shallowEqual
  );
  const { shiftData, isLoading, getShiftData, payReceipt, fiscReceipt } =
    usePayProcessReceipt();
  const isShowLoader = useSelector(
    (state) => state.actions.isShowLoader,
    shallowEqual
  );
  const handleClose = () => {
    props.closeForm();
  };
  const shiftDataUpd =
    shiftData && Object.keys(shiftData).length ? shiftData : checkData;
  // let shiftDataUpd;
  // if (shiftData && Object.keys(shiftData).length) {
  //   shiftDataUpd = shiftData;
  //   console.log('shiftDataUpd_shiftData', shiftData);
  // } else {
  //   shiftDataUpd = checkData;
  //   console.log('shiftDataUpd_checkData', checkData);

  // }
  const offlineTerminal = false;
  useEffect(() => {
    getShiftData();
    if (props.type === 'CASH' && refCash.current) {
      setTotal(checkData && checkData.receipt ? checkData.receipt.sumTotal : 0);
      refCash.current.focus();
      refCash.current.select();
    }
  }, []);

  useEffect(() => {
    if (cash >= total || cash == null) {
      setChange(cash === null ? 0 : (total - cash).toFixed(2));
      setAllowSubmitPayment(true);
    } else {
      setChange(0);
      setAllowSubmitPayment(false);
    }
  }, [cash]);

  const checkFixInputData = (inData) => {
    let resData;
    try {
      resData = parseFloat(inData);
    } catch (error) {
      resData = 0;
    }
    console.log('cash', resData ? resData : 0);
    return resData ? resData : 0;
  };

  const handleCash = (event) => {
    setCash(checkFixInputData(event.target.value));
  };

  const submitPaymentProcessReceipt = async () => {
    if (shiftDataUpd && shiftDataUpd.receipts.length) {
      for (const receipt of shiftDataUpd.receipts) {
        if (!receipt.paid || !receipt.fiscalized) {
          await payReceipt(receipt, props.type);
          await fiscReceipt(receipt);
        }
      }
      dispatch(getCheckData());
    }
  };

  return (
    <Modal
      show={true}
      onHide={props.closeForm}
      size='md'
      centered
      scrollable={true}
    >
      <Modal.Header>
        <Modal.Title>
          Оплата -{' '}
          {formatMoney(
            shiftDataUpd && shiftDataUpd.receipt
              ? shiftDataUpd.receipt.sumTotal
              : ''
          )}{' '}
          грн.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.body}>
        {props.type === 'CASH' && (
          <Fragment>
            <ListGroup>
              <ListGroup.Item>
                <Form>
                  <Form.Group as={Row} controlId='cash'>
                    <Form.Label className='fw-bold' column xs='5'>
                      Готівка
                    </Form.Label>
                    <Col xs='7'>
                      <Form.Control
                        ref={refCash}
                        onChange={handleCash}
                        //value={cash}
                        type='number'
                        min='0'
                        inputMode='numeric'
                        autoComplete='off'
                        placeholder={formatMoney(total)}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            if (shiftDataUpd &&
                              shiftDataUpd.receipt &&
                              !shiftDataUpd.receipt.fiscalized &&
                              shiftDataUpd.receipt.sumTotal > 0) {
                              submitPaymentProcessReceipt();
                              e.target.blur();
                              e.target.parentElement.focus();
                              e.target.parentElement.blur();
                            }
                          }
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col className='fw-bold' xs='5'>
                    Решта
                  </Col>
                  <Col xs='7'>{formatMoney(0 - change)}</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
            <br />
          </Fragment>
        )}
        <Table size='sm'>
          <thead>
            <tr key={'pay-list-head'} className={classes.head}>
              <th className='col-6'>Фіскальний реєстратор</th>
              <th className='text-end text-nowrap'>Сума,₴</th>
              <th className='text-center'>Сплачено</th>
              <th className='text-center'>Пробито</th>
            </tr>
          </thead>
          <tbody>
            {
              //!isShowLoader &&
              shiftDataUpd &&
              shiftDataUpd.receipts &&
              shiftDataUpd.receipts.length > 0 &&
              shiftDataUpd.receipts.map((receipt) => {
                if (receipt.sumTotal > 0) {
                  return (
                    <Fragment key={receipt.id + 'pay-list-fr'}>
                      <tr className={classes.row}>
                        <td colSpan={1}>
                          <p className='mb-1'>
                            {receipt.fiscalRegistrar.name}
                          </p>
                        </td>
                        <td colSpan={1}>
                          <p className='mb-1 text-end text-nowrap'>
                            {formatMoney(receipt.sumTotal)}
                          </p>
                        </td>
                        <td colSpan={1}>
                          <p className='mb-1 text-center'>
                            {!receipt.paid &&
                              !receipt.updPay &&
                              !receipt.updPayIsError && (
                                <i
                                  className='bi bi-circle'
                                  style={{ fontSize: 32, color: 'grey' }}
                                ></i>
                              )}
                            {!receipt.paid && receipt.updPay && (
                              <Spinner animation='border' variant='warning' />
                            )}
                            {!receipt.paid && receipt.updPayIsError && (
                              <i
                                className='bi bi-exclamation-circle-fill'
                                style={{ fontSize: 32, color: 'red' }}
                              ></i>
                            )}
                            {receipt.paid && (
                              <i
                                className='bi bi-check-circle'
                                style={{ fontSize: 32, color: 'green' }}
                              ></i>
                            )}
                          </p>
                        </td>
                        <td colSpan={1}>
                          <p className='mb-1 text-center'>
                            {!receipt.fiscalized &&
                              !receipt.updFisc &&
                              !receipt.updFiscIsError && (
                                <i
                                  className='bi bi-circle'
                                  style={{ fontSize: 32, color: 'grey' }}
                                ></i>
                              )}
                            {!receipt.fiscalized && receipt.updFisc && (
                              <Spinner animation='border' variant='warning' />
                            )}
                            {!receipt.fiscalized &&
                              receipt.updFiscIsError && (
                                <i
                                  className='bi bi-exclamation-circle-fill'
                                  style={{ fontSize: 32, color: 'red' }}
                                ></i>
                              )}
                            {receipt.fiscalized && (
                              <i
                                className='bi bi-check-circle'
                                style={{ fontSize: 32, color: 'green' }}
                              ></i>
                            )}
                          </p>
                        </td>
                      </tr>
                      {receipt.errorMessage && (
                        <tr className={classes.row}>
                          <td colSpan={4}>
                            <p className='mb-1 text-danger'>{receipt.errorMessage}</p>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                }
              })
            }
          </tbody>
        </Table>
        {props.type === 'CARD' &&
          offlineTerminal &&
          !shiftDataUpd.receipt.paid && (
            <ListGroup>
              <ListGroup.Item>
                <Form>
                  {/* <Form.Group as={Row} controlId='nonCash'>
                
              </Form.Group> */}
                  Оплата на терміналі пройшла успішно?
                </Form>
              </ListGroup.Item>
            </ListGroup>
          )}
      </Modal.Body>

      {props.type === 'CARD' && offlineTerminal && !shiftDataUpd.receipt.paid && (
        <Modal.Footer>
          <Button size='lg' variant='outline-secondary' onClick={handleClose}>
            Ні
          </Button>
          <Button
            className='w-50'
            size='lg'
            onClick={submitPaymentProcessReceipt}
            variant='success'
          >
            Так
          </Button>
        </Modal.Footer>
      )}
      {/* {props.type === 'CARD' &&
        (!offlineTerminal || shiftDataUpd.receipt.paid) && (
          <Modal.Footer>
            <Button size='lg' variant='outline-secondary' onClick={handleClose}>
              Закрити
            </Button>
            <Button
              className='w-50'
              size='lg'
              onClick={submitPaymentProcessReceipt}
              disabled={
                shiftDataUpd &&
                shiftDataUpd.receipt &&
                shiftDataUpd.receipt.fiscalized
                  ? true
                  : false
              }
              variant='success'
            >
              Оплата терм.
            </Button>
          </Modal.Footer>
        )} */}

      {props.type === 'CARD' &&
        (!offlineTerminal || shiftDataUpd.receipt.paid) && (
          <Modal.Footer>
            <Button size='lg' variant='outline-secondary' onClick={handleClose}>
              Закрити
            </Button>
            <Button
              className='w-50'
              size='lg'
              onClick={submitPaymentProcessReceipt}
              disabled={
                (shiftDataUpd &&
                  shiftDataUpd.receipt &&
                  shiftDataUpd.receipt.sumTotal > 0 &&
                  !shiftDataUpd.receipt.fiscalized
                  ? false
                  : true) || isLoading
              }
              variant='success'
            >
              {isLoading === undefined || (!isLoading && 'Оплата терм.')}
              {isLoading !== undefined && isLoading && (
                <Fragment>
                  <span
                    className='spinner-grow spinner-grow-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                  Оплата терм...
                </Fragment>
              )}
            </Button>
            {/* <button class='btn btn-primary' type='button' disabled>
              <span
                class='spinner-grow spinner-grow-sm'
                role='status'
                aria-hidden='true'
              ></span>
              Оплата терм....
            </button> */}
          </Modal.Footer>
        )}
      {props.type === 'CASH' && (
        <Modal.Footer>
          <Button size='lg' variant='outline-secondary' onClick={handleClose}>
            Закрити
          </Button>
          <Button
            className='w-50'
            size='lg'
            onClick={submitPaymentProcessReceipt}
            //disabled={!allowSubmitPayment}
            disabled={
              shiftDataUpd &&
                shiftDataUpd.receipt &&
                shiftDataUpd.receipt.sumTotal > 0 &&
                !shiftDataUpd.receipt.fiscalized
                ? false
                : true
            }
            variant='success'
          >
            Оплата
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalPayment;
