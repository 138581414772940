import { useState } from 'react';
import { fetcherAsync } from '../../../helpers/fetcherAsync';
import { configApiLinks } from '../../../configApiLinks';
import { config } from '../../../config';

// import { useDispatch, useSelector, shallowEqual } from 'react-redux';

export const usePayProcessReceipt = () => {
  const [shiftData, setShiftData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const setReceiptUpdParams = (updShiftData, receiptData, updData) => {
    for (const receipt of updShiftData.receipts) {
      if (receipt.id === receiptData.id) {
        Object.keys(updData).map((objKey) => {
          receipt[objKey] = updData[objKey];
        });
        break;
      }
    }
    setShiftData(updShiftData);
  };

  const getShiftData = async () => {
    let curShiftData = {};
    setIsLoading(true);
    try {
      curShiftData = await fetcherAsync(configApiLinks.getCheckData, 'GET');
    } catch (error) {
      //shiftData = {};
    }
    setIsLoading(false);
    setShiftData(curShiftData);
  };

  const payReceipt = async (receiptData, paymentType, isTestReceipt = false, cashSum = 0) => {
    setIsLoading(true);
    let curShiftData = { ...shiftData };
    setReceiptUpdParams(curShiftData, receiptData, {
      updPayIsError: false,
      updPay: true,
      errorMessagePayment: ''
    });
    setShiftData(curShiftData);
    console.log('curShiftData_updPay', curShiftData);
    let cash = 0,
      nonCash = 0,
      change = 0,
      total = receiptData.sumTotal;
    if (paymentType === 'CASH') {
      cash = receiptData.sumTotal;
    } else if (paymentType === 'CARD') {
      nonCash = receiptData.sumTotal;
    } else {
      cash = cashSum;
      nonCash = receiptData.sumTotal - cashSum;
    }
    try {
      curShiftData = await fetcherAsync(configApiLinks.makePayment, 'POST', {
        cash,
        nonCash,
        change,
        total,
        receiptId: receiptData.id,
        fiscalizeReceipt: false,
      });
      setReceiptUpdParams(curShiftData, receiptData, {
        updPay: false,
      });
      console.log('curShiftData_paid', curShiftData);
    } catch (error) {
      curShiftData = { ...shiftData };
      setReceiptUpdParams(curShiftData, receiptData, {
        updPayIsError: true,
        updPay: false,
        errorMessagePayment: error.message,
      });
    }
    setIsLoading(false);
    setShiftData(curShiftData);
  };

  const fiscReceipt = async (receiptData, isTestReceipt = false) => {
    setIsLoading(true);
    let curShiftData = { ...shiftData };
    setReceiptUpdParams(curShiftData, receiptData, {
      updFiscIsError: false,
      updFisc: true,
    });
    setShiftData(curShiftData);
    try {
      curShiftData = await fetcherAsync(configApiLinks.sendToApprove, 'POST', {
        receiptId: receiptData.id,
        testReceipt: isTestReceipt
      });
      setReceiptUpdParams(curShiftData, receiptData, {
        updFisc: false,
      });
      console.log('fiscReceiptSuccess');
      showReceiptPDF(receiptData);
    } catch (error) {
      console.log('fiscReceiptError', error.message);
      curShiftData = { ...shiftData };
      setReceiptUpdParams(curShiftData, receiptData, {
        updFiscIsError: true,
        updFisc: false,
        errorMessage: error.message,
      });
    }
    setIsLoading(false);
    setShiftData(curShiftData);
  };

  const showReceiptPDF = async (receiptData, isCopy = false) => {
    //console.log('PDFFILE_RECEIPT_DATA', receiptData);
    if (receiptData?.fiscalRegistrar?.frPrintType === 'PDFFILE' && receiptData?.fiscalRegistrar?.printer) {
      try {
        // const pdfWindow = window.open();
        // pdfWindow.location.href = `https://drive.google.com/viewerng/viewer?embedded=true&url=${config.apiUrl}${configApiLinks.printReceiptGetPdf}/${receiptData.id}`;
        // console.log('PDFFILE_START_RECEIPT_PRINT');
        const responseData = await fetcherAsync(configApiLinks.printReceiptGetPdf, 'POST', { receiptId: receiptData.id, isCopy }, true);
        const file = new Blob([await responseData.blob()], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "PRINT");

      } catch (error) {
        console.log('printError', error.message);
      }

    }
  }

  return { shiftData, isLoading, getShiftData, payReceipt, fiscReceipt, setIsLoading, showReceiptPDF };
};
