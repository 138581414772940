import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { config } from '../config';

/**
 * Scan on dom with keypress. Waiting keypress and execute methods with/without redux dispatch
 *
 * @param requeiredLength
 * @param isEnableScan
 * @param arrayConditions
 * @param methodToExecWithReceivedData
 * @return {string}
 */
export const useScanExsiceStamp = (
  requeiredLength,
  isEnableScan,
  arrayConditions,
  ...methodToExecWithReceivedData
) => {
  const [scannedData, setScannedData] = useState(null);
  const dispatch = useDispatch();
  let code = null;
  let timeStart = null;
  arrayConditions.unshift(isEnableScan);
  useEffect(() => {
    const handleScannedCode = () => {
      code = code.substr(0, requeiredLength);
      console.log('handleScannedCode->code', code);
      setScannedData(code);
      methodToExecWithReceivedData.forEach((mthd) => {
        if (mthd.dispatch) {
          dispatch(mthd.mthd(code));
        } else {
          mthd.mthd(code);
        }
      });
      code = null;
    };

    const scanWaiting = (event) => {
      //event.preventDefault();
      if (event.target.tagName.toLowerCase() !== 'input') {
        //console.log('useScanWaiting->event.key', event.key);
        let inputTime = Date.now() - timeStart;
        if (inputTime > config.scanCodeMax) {
          inputTime = 1;
          code = null;
          setTimeout(() => {
            if (
              code &&
              code.length >= 8 &&
              inputTime <= config.scanCodeMax + 10
            ) {
              code = code.substr(0, requeiredLength);
              //   code = code.length < requeiredLength
              //     ? code.substr(0, 8)
              //     : code.substr(0, requeiredLength);
              handleScannedCode();
            }
          }, config.scanCodeMax + 10);
        }
        if (!code) {
          timeStart = Date.now();
          code = event.key;
        } else {
          code += event.key;
        }
        //
        //console.log('code clean', code);
        try {
          code = code.trim().replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
          code = code.replace(/[\r\n]+/g, '');
          code = code.replace('~', '');
          // code = code.replace('E', '');
          // code = code.replace('En', '');
          code = code.replace('Ent', '');
          code = code.replace('Ente', '');
          code = code.replace('Enter', '');
          // code = code.replace('nter', '');
          // code = code.replace('ter', '');
          // code = code.replace('er', '');
          // code = code.replace('n', '');
          // code = code.replace('t', '');
          // code = code.replace('e', '');
          // code = code.replace('r', '');
          console.log('useScanWaiting->ScannedCode', code);
        } catch (error) {}
        //console.log('inputTime', inputTime);
        //console.log({ code, length: code.length, inputTime, requeiredLength });
        if (
          code.length >= requeiredLength &&
          inputTime < config.scanCodeMax &&
          inputTime > config.scanCodeMin
        ) {
          handleScannedCode();
        } else if (inputTime > config.scanCodeMax) {
          console.log('clearCode');
          code = null;
        }
      }
    };

    if (isEnableScan) {
      document.addEventListener('keypress', scanWaiting, false);
    } else {
      document.removeEventListener('keypress', scanWaiting, false);
    }

    return () => {
      document.removeEventListener('keypress', scanWaiting, false);
    };
  }, arrayConditions);
  return scannedData;
};
