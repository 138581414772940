import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {login} from "../actions/users";

function Secure(){
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const handleCredentials = (event)=>{
        const prop = event.target.name;
        if ( prop === 'username' ){
            setUsername(event.target.value);
        }else{
            setPassword(event.target.value);
        }
    }

    const submitLogin = (event)=>{
        event.preventDefault();
        dispatch(login(username, password));
    }
    return (<div>TEST!!!!!!!
        <form>
            <input type={'text'}
                   onChange={handleCredentials}
                   value={username} name={"username"} />
            <input type={'text'}
                   onChange={handleCredentials}
                   value={password} name={"password"} />
            <button onClick={submitLogin}>{'Submit'}</button>
        </form>
    </div>)
}

export default Secure;