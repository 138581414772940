// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_payment_body__KUOS9 {\n  font-size: var(--font-size-fs18);\n}\n.footer_payment_btn_lg__xAa5i {\n  font-size: var(--font-size-rm1);\n}\n.footer_payment_text-right__Gvtuv {\n  text-align: 'right';\n}\n.footer_payment_text-center__BBjAH {\n  text-align: 'center';\n}\n@media (min-width: 768px) {\n  .footer_payment_btn_lg__xAa5i {\n    font-size: var(--font-size-rm125);\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/footer_payment.module.css"],"names":[],"mappings":"AAEA;EACE,gCAAgC;AAClC;AACA;EACE,+BAA+B;AACjC;AACA;EACE,mBAAmB;AACrB;AACA;EACE,oBAAoB;AACtB;AACA;EACE;IACE,iCAAiC;EACnC;AACF","sourcesContent":["@import '../../indexVars.css';\n\n.body {\n  font-size: var(--font-size-fs18);\n}\n.btn_lg {\n  font-size: var(--font-size-rm1);\n}\n.text-right {\n  text-align: 'right';\n}\n.text-center {\n  text-align: 'center';\n}\n@media (min-width: 768px) {\n  .btn_lg {\n    font-size: var(--font-size-rm125);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "footer_payment_body__KUOS9",
	"btn_lg": "footer_payment_btn_lg__xAa5i",
	"text-right": "footer_payment_text-right__Gvtuv",
	"text-center": "footer_payment_text-center__BBjAH"
};
export default ___CSS_LOADER_EXPORT___;
