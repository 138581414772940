import React, { useState, useEffect, useRef, Fragment } from 'react';
import FiscalRegistrarsList from "./FiscalRegistrarsList";
import FiscalRegistrarElem from "./FiscalRegistrarElem";

const FiscalRegistrarWrap = () => {
    const [elemEditId, setElemEditId] = useState(null);

    return (
        <Fragment>
            {!elemEditId &&
                <FiscalRegistrarsList
                    elemEditId={elemEditId}
                    setElemEditId={setElemEditId}
                />}
            {elemEditId &&
                <FiscalRegistrarElem
                    elemEditId={elemEditId}
                    setElemEditId={setElemEditId}
                />}
        </Fragment>
    );
}

export default FiscalRegistrarWrap;