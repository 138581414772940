import React, { useState, useEffect } from "react";
import classes from "./nav_barcode.module.css";
import { Button, Modal, InputGroup, FormControl } from "react-bootstrap";
import BarcodeIcon from "../../images/barcode-icon.svg";
import SearchIcon from "../../images/search-icon.svg"
import { addProductToCheck, DISPLAY_MODAL, HIDE_MODAL } from "../../actions/checks";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Loading from "../Loading";


function NavBarcode() {
  const [showAddProductBarCode, setShowAddProductBarCode] = useState(false);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const hideAnyModal = useSelector(state => state.actions.hideAnyModal);
  const isShowLoader = useSelector(state => state.actions.isShowLoader, shallowEqual);
  const handleSubmit = () => {
    if (value.length >= 12) {
      dispatch(addProductToCheck(value));
    } else {
      alert("to short!");
    }
  }

  // listen that data should be updated and got response 200(ok) for hidding modal
  useEffect(() => {
    if (hideAnyModal === true) {
      setShowAddProductBarCode(false);
    }
  }, [hideAnyModal])

  return (
    <div>
      <Button
        className={`me-md-2 ${classes.button}`}
        size="sm"
        onClick={(e) => {
          e.target.parentElement.focus();
          e.target.parentElement.blur();
          setShowAddProductBarCode(true);
          dispatch({ type: DISPLAY_MODAL });
          setValue("");
        }}
      >
        <img className={classes.nav_icon} src={BarcodeIcon} alt="barcode" />
      </Button>
      {/* {isShowLoader&&<Loading/>} */}
      <Modal
        show={showAddProductBarCode}
        onHide={event => {
          dispatch({ type: HIDE_MODAL });
          setShowAddProductBarCode(false);
        }
        }
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Введіть штрихкод
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="штрихкод"
              aria-label="штрихкод"
              aria-describedby="barcode-search-btn"
              value={value}
              onChange={e => setValue(e.target.value)}
            />

            <Button id="barcode-search-btn" onClick={e => handleSubmit(e)} variant="outline-secondary" >
              <img className={classes.search_icon} src={SearchIcon} alt="search" />
            </Button>
          </InputGroup>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default NavBarcode;
