import React, { useEffect, Fragment } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { useGetDisplay2ReceiptData } from './hooks/useGetDisplay2ReceiptData';
import { formatCount, formatMoney } from '../../helpers/dataFormatter';
import styles from './ReceiptClient.module.css';

function ReceiptClient() {
    const [display2ReceiptData, getDisplay2ReceiptData, isLoading] = useGetDisplay2ReceiptData();
    const receipt = display2ReceiptData.receipt || {};

    const renderProducts = (products) => {
        return products.map((product, index) => {
            return (
                <tbody key={index} className={styles.rowBody}>
                    <tr className={styles.rowName}>
                        <td colSpan="7">{product.name}</td>
                    </tr>
                    <tr className={styles.rowNums}>
                        <td className={styles.colCpEmpty}></td>
                        <td className={styles.colCpRight}>{formatCount(product.count)}</td>
                        <td className={styles.colCpCenter}>x</td>
                        <td className={styles.colCpLeft}>{formatMoney(product.price)}</td>
                        <td className={styles.colCpDiscount}>
                            <Fragment>{product.discount !== 0 && (
                                <Fragment>{product.discount > 0 ? "знижка" : "окр."} {formatMoney(product.discount)}
                                </Fragment>
                            )}
                            </Fragment>
                        </td>
                        <td className={styles.colCpEmpty}></td>
                        <td className={styles.alignRightAndBigger}><strong>{formatMoney(product.sum)}</strong></td>
                    </tr>
                </tbody>
            );
        });
    };

    return (
        <Container fluid ClassName={styles.container}>
            <Row>
                <Col md={8}>
                    <Table className={styles.productTable} bordered hover>
                        {receipt.products && renderProducts(receipt.products)}
                    </Table>
                </Col>
                <Col md={4} className={styles.summary}>
                    <div className="d-flex justify-content-between align-items-baseline">
                        <h3>Всього:</h3>
                        <h3><span className={styles.total}>{formatMoney(receipt.sumTotal)}</span> грн.</h3>
                    </div>

                    {receipt.sumTotalDiscount > 0 && (
                        <Fragment>
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h3>Сума знижки:</h3>
                                <h3><span className={styles.totalDiscount}>{formatMoney(receipt.sumTotalDiscount)}</span> грн.</h3>
                            </div>
                        </Fragment>
                    )}
                    <div className="d-flex justify-content-between align-items-baseline">
                        <h3>Позицій:</h3>
                        <h3><span className={styles.totalCount}>{receipt.products?.length ?? 0}</span></h3>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ReceiptClient;
