// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_frs_btn__lMIdA {\n  width: auto;\n  font-size: var(--font-size-fs12);\n  padding: 5px 10px;\n}\n.modal_frs_rightTextElem__7fnGo {\n  text-align: end;\n}\n@media (min-width: 435px) {\n  .modal_frs_btn__lMIdA {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/modals/modal_frs.module.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,gCAAgC;EAChC,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;AACA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":["@import '../../indexVars.css';\n\n.btn {\n  width: auto;\n  font-size: var(--font-size-fs12);\n  padding: 5px 10px;\n}\n.rightTextElem {\n  text-align: end;\n}\n@media (min-width: 435px) {\n  .btn {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "modal_frs_btn__lMIdA",
	"rightTextElem": "modal_frs_rightTextElem__7fnGo"
};
export default ___CSS_LOADER_EXPORT___;
