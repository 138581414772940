import React, { useEffect, useState, Fragment } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import classes from './footer_fr_in_out.module.css';
import ModalCashInOut from '../modals/modal_cash_in_out_keyboard';
import { formatDate, formatMoney } from '../../helpers/dataFormatter';
import { useWindowSize } from '../hooks/useWindowSize';

function FooterFrInOut({ checkData, children }) {
  const [total, setTotal] = useState(0);
  const [modalFrInOut, setModalFrInOut] = useState({
    isActive: false,
    type: '',
  });
  const { isNonMobile } = useWindowSize();

  useEffect(() => {
    if (checkData && Object.keys(checkData).length > 0) {
      setTotal(checkData.shift.currentCash);
    }
  }, [checkData]);

  return (
    <Fragment>
      {/* <Col xs={6}> */}
      <Row className=' mb-1'>
        <Col xs={6}>
          <Button
            variant='outline-secondary'
            size='sm'
            className={classes.btn}
            style={{ width: isNonMobile ? '7.5rem' : '6rem' }}
            onClick={(e) => {
              e.target.blur();
              setModalFrInOut({
                isActive: true,
                type: 'IN',
              });
            }}
          >
            Внесення
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            variant='outline-secondary'
            size='sm'
            className={classes.btn}
            style={{ width: isNonMobile ? '7.5rem' : '6rem' }}
            onClick={(e) => {
              e.target.blur();
              setModalFrInOut({
                isActive: true,
                type: 'OUT',
              });
            }}
          >
            Вилучення
          </Button>
        </Col>
      </Row>
      {isNonMobile && (

        <p className='m-0 fw-bold text-nowrap'>
          <Row className=' mb-1' style={{ maxHeight: '0.15rem' }}>
            <p></p>
          </Row>

          В касі:
          <span className={classes.sum}>
            {' '}
            {formatMoney(total)}
            <span>&nbsp;</span>грн.
          </span>
          <Row className=' mb-1' style={{ maxHeight: '0.25rem' }}>
            <p></p>
          </Row>
        </p>)}
      {!isNonMobile && (
        <Row>
          <Col sx={6}>{children}</Col>
          <Col sx={6} style={{ paddingLeft: 0, paddingRight: 0, fontWeight: '600' }}>{formatMoney(total)} <span>&nbsp;</span>грн.</Col>
        </Row>
      )}
      {/* </Col> */}
      {modalFrInOut.isActive && (
        <ModalCashInOut
          shiftData={checkData}
          opType={modalFrInOut.type}
          closeModal={() => {
            setModalFrInOut({
              isActive: false,
              type: '',
            });
          }}
        />
      )}
    </Fragment>
  );
}

export default FooterFrInOut;
