import './App.css';
import { Suspense } from 'react';
import React, {  useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import {restoreAuth} from "./actions/users";
import {useDispatch, useSelector,shallowEqual} from "react-redux";

import Login from "./components/Login";
import {PrivateRoute, PublicRoute} from "./syst/CustomRoute";
import Secure from "./components/Secure";
import Check from "./components/Check";
import Logout from "./components/Logout";
import AdminMain from './components/AdminPanel/AdminMain';
import ReceiptClient from './components/Display2/ReceiptClient';

/**
 * Define routes with some redirect according presenting data about user (isLogged = true|false|null)
 * This data we get from redux store.
 *
 * Null - haven't info
 * true - logged
 * false - not logged
 *
 *
 * @return {*}
 * @constructor
 */
function Routing(){
    const dispatch = useDispatch();
    useEffect( ()=>{
        dispatch(restoreAuth());
    }, [dispatch])

    return(
        <Router>
            <Suspense fallback="loading">
                <Routes>
                    <Route exact={true} path={"/"} element={<PublicRoute><Login /></PublicRoute>} />
                    <Route exact={true} path={"/check"} element={<PrivateRoute><Check /></PrivateRoute>} />
                    <Route exact={true} path={"/armadmin"} element={<PrivateRoute><AdminMain /></PrivateRoute>} />
                    <Route exact={true} path={"/armdisplay2"} element={<ReceiptClient />} />
                    <Route exact={true} path={"/logout"} element={<PrivateRoute><Logout /></PrivateRoute>} />
                </Routes>
            </Suspense>
        </Router>
    )
}
export default Routing;