import React from "react";
import classes from "./header.module.css";
import { Container, Navbar, Nav } from "react-bootstrap";
import NavProfile from "./nav_profile";
import NavBarcode from "./nav_barcode";
import NavWeightCode from "./nav_weightcode";
import NavWeightCodeKeyboard from "./nav_weightcode_keyboard";
import NavAddGoodByBarcode from "./nav_add_good_by_barcode";
import NavAddGoodByBarcodeKeyboard from "./nav_add_good_by_barcode_keyboard";
import NavAddGood from "./nav_add_good";
import NavReceiptList from "./nav_receiptlist";
import CashList from "./nav_cashlist";
import NavDiscount from "./nav_discount";
import NavChecklistReturn from "./nav_checklist_return";
import NavClearProducts from "./nav_clear_products";
import NavPrices from "./nav_prices";
import { useSelector, shallowEqual } from 'react-redux';

function Header() {
  const checkData = useSelector(
    (state) => state.actions.checkData,
    shallowEqual
  );
  return (
    <header>
      <Navbar bg="light">
        <Container fluid>
          <Nav>
            {/* <NavBarcode /> */}
            {!checkData || !checkData.cashierWorkplace || checkData.cashierWorkplace.showNumericKeyborad !== 1 && <NavAddGoodByBarcode />}
            {checkData && checkData.cashierWorkplace && checkData.cashierWorkplace.showNumericKeyborad === 1 && <NavAddGoodByBarcodeKeyboard />}
            <NavAddGood />
            {(checkData?.cashierWorkplace?.showWeightCodeSearch && (!checkData || !checkData.cashierWorkplace || checkData.cashierWorkplace.showNumericKeyborad !== 1)) === true && <NavWeightCode />}
            {(checkData?.cashierWorkplace?.showWeightCodeSearch && (checkData && checkData.cashierWorkplace && checkData.cashierWorkplace.showNumericKeyborad === 1)) === true && <NavWeightCodeKeyboard />}
            <NavPrices />
            <NavReceiptList />
            <CashList checkData = {checkData} />
            {checkData && checkData.cashierWorkplace && checkData.cashierWorkplace.useManualDiscounts === 1 && <NavDiscount />}
            <NavClearProducts />
            {checkData && checkData.userData && checkData.userData.rolesSeniorCashier === 1 && <NavAddGood priceEdit={true} />}
            {/* <NavChecklistReturn /> */}
          </Nav>
          <NavProfile />
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
