import React, { useState, useEffect, useRef, Fragment } from 'react';
import {
  Container,
  Table,
  Form,
  Row,
  Col,
  Modal,
  Button,
} from 'react-bootstrap';
import classes from './checklist.module.css';
import RemoveIcon from '../../images/remove-icon.svg';
import {
  DISPLAY_MODAL,
  HIDE_MODAL,
  updateProductCount,
  removeProduct,
  setDiscountManual,
} from '../../actions/checks';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Loading';
import { formatDate, formatMoney } from '../../helpers/dataFormatter';
import DiscountIcon from '../../images/discount-icon.svg';
import StampGreen from '../../images/stamp-green.svg';
import StampRed from '../../images/stamp-red.svg';
import ModalDiscountMain from '../modals/modal_discount_main';
import ModalCountEditKeyboard from '../modals/modal_count_edit_keyboard';
import ModalAddExciseStamp from '../modals/ModalAddExciseStamp';

function CheckProductlist({ isShowLoader, checkData }) {
  const dispatch = useDispatch();
  const [curProduct, setCurProduct] = useState({});
  const [removeProductModal, setRemoveProductModal] = useState({});
  const hideAnyModal = useSelector((state) => state.actions.hideAnyModal);
  // const countRef = useRef('');
  // const setCurrentOpenedProduct = (e, product) => {
  //   console.log('setCurrentOpenedProduct', product);
  //   setCurProduct(product);
  //   dispatch({ type: DISPLAY_MODAL });
  //   setTimeout(() => {
  //     if (countRef.current) {
  //       countRef.current.focus();
  //       countRef.current.select();
  //       console.log('countRef.current', countRef.current);
  //     }
  //   }, 300);
  // };
  const [discountModalParams, setDiscountModalParams] = useState({
    isActive: false,
    productId: null,
    productRow: null
  });
  const [countEditModalParams, setCountEditModalParams] = useState({
    isActive: false,
    productId: null,
    count: 0,
    getWeightFromScales: false,
    isWeightProduct: false,
  });

  const [exsiceModalParams, setExciseModalParams] = useState({
    isActive: false,
    productId: null,
    productIdClosed: null,
    productCountClosed: null,
    count: 0,
  });

  const setShowRemoveModal = (product) => {
    setRemoveProductModal(product);
    dispatch({ type: DISPLAY_MODAL });
  };

  // const handleCount = (e, product, action) => {
  //   const clonedProduct = Object.assign({}, product);
  //   if (action === 'set') {
  //     clonedProduct.count = e.target.value;
  //   } else if (action === '+') {
  //     clonedProduct.count++;
  //   } else if (action === '-') {
  //     clonedProduct.count--;
  //   }
  //   setCurProduct(clonedProduct);
  // };

  // const submitNewCount = (event) => {
  //   event.preventDefault();
  //   dispatch(updateProductCount(curProduct));
  // };

  const submitEditedCount = (productData) => {
    setCountEditModalParams({
      isActive: false,
      productId: null,
      count: 0,
      getWeightFromScales: false,
      isWeightProduct: false,
    });
    dispatch(updateProductCount(productData));
  };

  useEffect(() => {
    if (hideAnyModal === true) {
      setCurProduct({});
      setRemoveProductModal({});
    }
  }, [hideAnyModal]);

  return (
    <Container className={classes.container}>
      {isShowLoader && <Loading />}
      <Table size='sm'>
        <thead>
          <tr className={classes.head}>
            <th className='col-6'>Назва</th>
            <th className='text-end text-nowrap'>Ціна,₴</th>
            <th className='text-end text-nowrap'>Всього,₴</th>
          </tr>
        </thead>
        <tbody>
          {
            //!isShowLoader &&
            checkData &&
            checkData.receipt &&
            Object.keys(checkData.receipt.products).map((inx) => {
              const productRowCt = checkData.receipt.products[inx];
              if (productRowCt.getWeightFromScales) {
                productRowCt.getWeightFromScales = false;
                setTimeout(() => {
                  // setCurrentOpenedProduct(
                  //   null,
                  //   checkData.receipt.products[inx]
                  // );
                  setCountEditModalParams({
                    isActive: true,
                    productId: productRowCt.productId,
                    count: productRowCt.count,
                    getWeightFromScales: true,
                    isWeightProduct:
                    productRowCt.isWeightProduct,
                  });
                }, 500);
              }
              if (productRowCt.changedQty && productRowCt.exciseCodesError && !productRowCt.isWeightProduct) {
                //console.log('ModalAddExciseStamp', {productRowCt, exsiceModalParams})
                //console.log('ModalAddExciseStampCheck', {isActive: exsiceModalParams.isActive, count: {productCountClosed: exsiceModalParams.productCountClosed, count: }, exsiceModalParams})
                if (!exsiceModalParams.isActive && (exsiceModalParams.productCountClosed !== productRowCt.count || exsiceModalParams.productIdClosed !== productRowCt.productId))
                setTimeout(() => {
                  setExciseModalParams({
                    isActive: true,
                    productId: productRowCt.productId,
                    productRow: productRowCt
                  });
                }, 500);
              }
              return (
                <Fragment key={inx}>
                  <tr key={inx + 'product-list'} className={classes.row}>
                    <td colSpan={3}>
                      <p className='mb-1'>
                        {checkData.cashierWorkplace.showProductCode && checkData.receipt.products[inx].artCode ? `[${checkData.receipt.products[inx].artCode}] `: ''}{checkData.receipt.products[inx].name}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <Row className='align-items-center'>
                          <Col xs={6}>
                            <div
                              className={`d-inline-block ${classes.icon}`}
                              onClick={(e) =>
                                setShowRemoveModal(
                                  checkData.receipt.products[inx]
                                )
                              }
                            >
                              <img
                                className={classes.remove_icon}
                                src={RemoveIcon}
                                alt=''
                              />
                            </div>
                            {checkData && checkData.cashierWorkplace && checkData.cashierWorkplace.useManualDiscounts === 1 &&
                              <div
                                className={`d-inline-block ${classes.icon}`}
                                onClick={() => {
                                  setDiscountModalParams({
                                    isActive: true,
                                    productId:
                                      checkData.receipt.products[inx].productId,
                                    sum: parseFloat(
                                      (
                                        checkData.receipt.products[inx].count *
                                        checkData.receipt.products[inx].price
                                      ).toFixed(2)
                                    ),
                                  });
                                }}
                              >
                                <img
                                  className={classes.discount_icon}
                                  src={DiscountIcon}
                                  alt=''
                                />
                              </div>}
                              {checkData?.receipt?.products[inx].excise &&   checkData?.receipt?.products[inx].hasExciseCode &&
                              <div
                                className={`d-inline-block ${classes.icon}`}
                                onClick={() => {
                                  setExciseModalParams({
                                    isActive: true,
                                    productId: checkData.receipt.products[inx].productId,
                                    productRow: checkData.receipt.products[inx]
                                  });
                                }}
                              >
                                <img
                                  className={classes.discount_icon}
                                  src={checkData.receipt.products[inx].exciseCodesError ? StampRed : StampGreen}
                                  alt=''
                                />
                              </div>}  
                          </Col>
                          <Col xs={6}>
                            <div
                              className={classes.count}
                              onClick={(e) =>
                                // setCurrentOpenedProduct(
                                //   e,
                                //   checkData.receipt.products[inx]
                                // )
                                setCountEditModalParams({
                                  isActive: true,
                                  productId:
                                    checkData.receipt.products[inx].productId,
                                  count:
                                    checkData.receipt.products[inx].count,
                                  getWeightFromScales: false,
                                  isWeightProduct:
                                    checkData.receipt.products[inx]
                                      .isWeightProduct,
                                })
                              }
                            >
                              {checkData.receipt.products[inx].count}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {checkData.receipt.products[inx].discount !== 0 && (
                        <Row className='justify-content-end'>
                          <Col className={classes.col_discount} xs={6}>
                            {checkData.receipt.products[inx].discount < 0
                              ? 'Надб.'
                              : 'Знижка'}
                          </Col>
                        </Row>
                      )}
                    </td>
                    <td className='fs-5'>
                      <Row
                        className={`justify-content-end pe-1 ${classes.price}`}
                      >
                        {
                          //!isShowLoader &&
                          formatMoney(checkData.receipt.products[inx].price)
                        }{' '}
                      </Row>
                      {checkData.receipt.products[inx].discount !== 0 && (
                        <Row className={classes.discount_val}>
                          {/* {checkData.receipt.products[inx].discount < 0
                            ? 'Надб.'
                            : 'Знижка'} */}
                          {(-checkData.receipt.products[inx]
                            .discount).toFixed(2)}
                        </Row>
                      )}
                    </td>
                    <td className='text-end fs-5 fw-bold' style={{padding: '.25rem .5rem'}}>
                      <Row
                        className={`justify-content-end pe-1 ${classes.price}`}
                      >
                        {
                          //!isShowLoader &&
                          formatMoney(checkData.receipt.products[inx].sum)
                        }
                      </Row>
                      {/* {checkData.receipt.products[inx].discount !== 0 && (
                        <Row className={classes.discount_val}>{`${
                          checkData.receipt.products[inx].discount > 0
                            ? '-'
                            : ''
                        }${checkData.receipt.products[inx].discount.toFixed(
                          2
                        )}`}</Row>
                      )} */}
                    </td>
                  </tr>
                </Fragment>
              );
            })
          }
        </tbody>
      </Table>
      {discountModalParams.isActive && (
        <ModalDiscountMain
          show={discountModalParams.isActive}
          onHide={() => {
            setDiscountModalParams({
              isActive: false,
              productId: null,
              sum: 0,
            });
            dispatch({ type: HIDE_MODAL });
          }}
          sum={discountModalParams.sum}
          productId={discountModalParams.productId}
          onConfirm={(discountPercentage) => {
            setDiscountModalParams({
              isActive: false,
              productId: null,
              sum: 0,
            });
            dispatch(
              setDiscountManual(
                discountPercentage,
                discountModalParams.productId
              )
            );
          }}
          onCancel={() => {
            setDiscountModalParams({
              isActive: false,
              productId: null,
              sum: 0,
            });
          }}
          centered
        />
      )}
      {countEditModalParams.isActive && (
        <ModalCountEditKeyboard
          productId={countEditModalParams.productId}
          count={countEditModalParams.count}
          getWeightFromScales={countEditModalParams.getWeightFromScales}
          onSubmit={submitEditedCount}
          isWeightProduct={countEditModalParams.isWeightProduct}
          onHide={() => {
            setCountEditModalParams({
              isActive: false,
              productId: null,
              count: 0,
              getWeightFromScales: false,
              isWeightProduct: false,
            });
          }}
        />
      )}
      {exsiceModalParams.isActive && (
        <ModalAddExciseStamp
          productId={exsiceModalParams.productId}
          productRow={exsiceModalParams.productRow}
          onSubmit={submitEditedCount}
          onHide={() => {
            setExciseModalParams({
              isActive: false,
              productId: null,
              productIdClosed: exsiceModalParams.productId,
              productCountClosed: exsiceModalParams.productRow.count,
              productRow: null
            });
          }}
        />
      )}
      {/* <Modal
        show={Object.keys(curProduct).length > 0}
        onHide={() => {
          setCurProduct({});
        }}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Кількість</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='justify-content-center align-items-center'>
            <Col className='text-end'>
              <Button
                onClick={(e) => handleCount(e, curProduct, '-')}
                variant='secondary'
                size='lg'
              >
                -
              </Button>
            </Col>
            <Form as={Col}>
              <Form.Group>
                <Form.Control
                  ref={countRef}
                  className='text-center'
                  type='text'
                  onChange={(e) => handleCount(e, curProduct, 'set')}
                  value={curProduct.count}
                  inputMode='numeric'
                  autoComplete='off'
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      submitNewCount(e);
                    }
                  }}
                />
              </Form.Group>
            </Form>
            <Col>
              <Button
                onClick={(e) => handleCount(e, curProduct, '+')}
                variant='secondary'
                size='lg'
              >
                +
              </Button>
            </Col>
          </Row>
          <Row className={'mt-3 justify-content-center'}>
            <Button
              onClick={(e) => submitNewCount(e)}
              className={'w-50'}
              variant='success'
            >
              {'OK'}
            </Button>
          </Row>
        </Modal.Body>
      </Modal> */}
      <Modal
        show={Object.keys(removeProductModal).length > 0}
        onHide={() => {
          setRemoveProductModal({});
        }}
        size='sm'
        centered
      >
        <Modal.Body>Видалити - {removeProductModal.name}?</Modal.Body>
        <Modal.Footer>
          <Button
            variant='outline-secondary'
            className='w-25'
            onClick={() => {
              setRemoveProductModal({});
            }}
          >
            Ні
          </Button>
          <Button
            variant='success'
            className='w-25'
            onClick={() => {
              setRemoveProductModal({});
              dispatch(removeProduct(removeProductModal.productId));
            }}
          >
            Так
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
export default CheckProductlist;
