import { useState, useEffect } from 'react';
import { fetcherAsync } from '../../../helpers/fetcherAsync';
import { configApiLinks } from '../../../configApiLinks';

export const useGetWeight = (useScalesParam) => {
  //const [useScales, setUseScales] = useState(useScalesParam);
  const [weightData, setWeightData] = useState({
    is_error: false,
    error: '',
    result: 0,
  });
  const [updIntervalId, setUpdIntervalId] = useState(null);

  const startStopScalesUpdate = (isStart) => {
    //setUseScales(isStart);
    if (isStart) {
      getWeightData(true);
      const currentIntervalId = setInterval(async () => {
        await getWeightData();
      }, 800);
      //console.log('useGetWeight-START', currentIntervalId);
      setUpdIntervalId(currentIntervalId);
      return () => {
        clearInterval(currentIntervalId);
      };
    } else {
      //console.log('useGetWeight-STOP', updIntervalId);
      clearInterval(updIntervalId);
      setUpdIntervalId(null);
    }
  };

  useEffect(() => {
    startStopScalesUpdate(useScalesParam);
  }, []);

  const getWeightData = async (updateAlways = false) => {
    let curWeightData;
    try {
      curWeightData = await fetcherAsync(
        configApiLinks.getWeightFromScales,
        'POST'
      );
    } catch (error) {
      curWeightData = { ...weightData };
      curWeightData.is_error = true;
      curWeightData.error = error.message;
    }
    if (
      updateAlways ||
      curWeightData.is_error !== weightData.is_error ||
      curWeightData.error !== weightData.error ||
      curWeightData.result !== weightData.result
    ) {
      setWeightData(curWeightData);
    }
  };

  return [weightData, getWeightData, startStopScalesUpdate, updIntervalId];
};
