import React, { useState, useEffect } from "react";
import classes from "./nav_checklist_return.module.css";
import { Button } from "react-bootstrap";

import { DISPLAY_MODAL, HIDE_MODAL } from "../../actions/checks";
import { useDispatch } from "react-redux";
import ModalChecklistReturn from "../modals/modal_checklist_return";
import ReturnIcon from "../../images/return-icon.svg";

function NavChecklistReturn() {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <Button
        className={`me-md-2 ${classes.button}`}
        size="sm"
        onClick={(e) => {
          e.target.parentElement.focus();
          e.target.parentElement.blur();
          setModalShow(true);
          dispatch({ type: DISPLAY_MODAL });
        }}
      >
        <img className={classes.nav_icon} src={ReturnIcon} alt="" />
      </Button>
      <ModalChecklistReturn
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
      />
    </>
  );
}

export default NavChecklistReturn;
