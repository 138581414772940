import React from 'react';
import { formatDate, formatMoney } from "../helpers/dataFormatter";

export default function CheckList({isShowLoader,checkData, setCurrentOpenedProduct}){
    return (<div>
        <table>
            <thead>
            <tr>
                <td>{"Name"}</td>
                <td>{"Count"}</td>
                <td>{"Price"}</td>
                <td>{"SUM"}</td>
            </tr>
            </thead>
            {!isShowLoader&&Object.keys(checkData.receipt.products).map(inx=>{
                return <tr key={inx}>
                    <td>{checkData.receipt.products[inx].name}</td>
                    <td onClick={e=>setCurrentOpenedProduct(e,checkData.receipt.products[inx])}>
                        {checkData.receipt.products[inx].count}
                    </td>
                    <td>{formatMoney(checkData.receipt.products[inx].price)}</td>
                    <td>{formatMoney(checkData.receipt.products[inx].sum)}</td>
                </tr>
            })}
        </table>
        {isShowLoader === false&&<div>
            <div>{"total_without_discount"}{checkData.receipt.sumTotalWithoutDiscount}</div>
            <div>{"total_with_discount"}{checkData.receipt.sumTotal}</div>
        </div>}
    </div>);
}