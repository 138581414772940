import {logout} from '../actions/users';
// export function handleResponse(dispatch,response) {
//     const statusOk = [200,201,304];
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (statusOk.includes(response.status)  ){
//             return data;
//         }else{
//             if ( data.statusCode !== undefined && data.statusCode === 401 ){
//                 dispatch(logout());
//             }
//             return Promise.reject(data);
//         }
//     });
// }


export function handleResponse(response, dispatcher) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log("handle st", response.ok);
        console.log("handle response", response);

        if (!response.ok) {
            if (response.status === 401) {
                dispatcher(logout());
                window.location.reload();
            }
            const error = (data && data.message) || response.statusText;
            console.log("errror 14", error);
            return Promise.reject(error);
        }
        console.log("data response", data);
        return data;
    });
}