import React, { useState, useEffect } from 'react';
import classes from './nav_discount.module.css';
import { Button } from 'react-bootstrap';

import {
  DISPLAY_MODAL,
  HIDE_MODAL,
  setDiscountManual,
} from '../../actions/checks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import DiscountIcon from '../../images/discount-icon.svg';
import ModalDiscountMain from '../modals/modal_discount_main';

function NavDiscount() {
  const dispatch = useDispatch();
  const checkData = useSelector(
    (state) => state.actions.checkData,
    shallowEqual
  );
  const [discountModalParams, setDiscountModalParams] = useState({
    isActive: false,
    productId: null,
    sum: 0,
  });
  return (
    <>
      <Button
        className={`me-md-2 ${classes.button}`}
        size='sm'
        onClick={(e) => {
          e.target.parentElement.focus();
          e.target.parentElement.blur(); 
          setDiscountModalParams({
            isActive: true,
            productId: null,
            sum: checkData.receipt.sumTotalWithoutDiscount,
          });
          dispatch({ type: DISPLAY_MODAL });
        }}
      >
        <img className={classes.nav_icon} src={DiscountIcon} alt='' />
      </Button>
      {discountModalParams.isActive && (
        <ModalDiscountMain
          show={discountModalParams.isActive}
          sum={discountModalParams.sum}
          onHide={() => {
            setDiscountModalParams({
              isActive: false,
              productId: null,
              sum: 0,
            });
            dispatch({ type: HIDE_MODAL });
          }}
          onConfirm={(discountPercentage) => {
            setDiscountModalParams({
              isActive: false,
              productId: null,
              sum: 0,
            });
            dispatch(setDiscountManual(discountPercentage));
          }}
          onCancel={() => {
            setDiscountModalParams({
              isActive: false,
              productId: null,
              sum: 0,
            });
          }}
          centered
        />
      )}
    </>
  );
}

export default NavDiscount;
