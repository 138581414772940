// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_checkout_header_info__Ff3AS{\n    width: 100%;\n    margin-bottom: 3px;\n    font-size: var(--font-size-fs14);\n    line-height: 14px;\n}\n.footer_checkout_body__G551M{\n    font-size: var(--font-size-fs14);\n    line-height: 14px;\n    padding-left: 0;\n    padding-right: 0;\n}\n.footer_checkout_small_text__NwRPg{\n    font-size: var(--font-size-fs14);\n    line-height: 14px;;\n}\n.footer_checkout_close__1\\+FJa{\n    position: absolute;\n    top: 10px;\n    right: 10px;\n}\n.footer_checkout_btn_lg__\\+3mBB{\n    font-size: var(--font-size-rm1);\n}\n@media (min-width: 768px){\n    .footer_checkout_btn_lg__\\+3mBB{\n        font-size: var(--font-size-rm125);\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/Footer/footer_checkout.module.css"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,gCAAgC;IAChC,iBAAiB;AACrB;AACA;IACI,gCAAgC;IAChC,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gCAAgC;IAChC,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;AACA;IACI,+BAA+B;AACnC;AACA;IACI;QACI,iCAAiC;IACrC;AACJ","sourcesContent":["@import '../../indexVars.css';\n\n.header_info{\n    width: 100%;\n    margin-bottom: 3px;\n    font-size: var(--font-size-fs14);\n    line-height: 14px;\n}\n.body{\n    font-size: var(--font-size-fs14);\n    line-height: 14px;\n    padding-left: 0;\n    padding-right: 0;\n}\n.small_text{\n    font-size: var(--font-size-fs14);\n    line-height: 14px;;\n}\n.close{\n    position: absolute;\n    top: 10px;\n    right: 10px;\n}\n.btn_lg{\n    font-size: var(--font-size-rm1);\n}\n@media (min-width: 768px){\n    .btn_lg{\n        font-size: var(--font-size-rm125);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header_info": "footer_checkout_header_info__Ff3AS",
	"body": "footer_checkout_body__G551M",
	"small_text": "footer_checkout_small_text__NwRPg",
	"close": "footer_checkout_close__1+FJa",
	"btn_lg": "footer_checkout_btn_lg__+3mBB"
};
export default ___CSS_LOADER_EXPORT___;
