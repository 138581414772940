import React, { useState, useRef, useEffect } from 'react';
import {
  Col,
  Button,
  Modal,
  CloseButton,
  Row,
  Container,
} from 'react-bootstrap';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import ReactToPrint from 'react-to-print';
import QRCode from 'react-qr-code';

import classes from '../Footer/footer_checkout.module.css';
import {
  HIDE_DONE_CHECK,
  SET_RECEIPT_COPY,
  SET_RECEIPT_COPY_HIDE,
} from '../../actions/checks';
//import Loading from '../Loading';
import {
  formatDate,
  formatMoney,
  getFiscalReceiptLink,
} from '../../helpers/dataFormatter';

function ReceiptPrint() {
  const dispatch = useDispatch();
  //const showMe = useSelector((state) => state.actions.showDialogWithCheck);
  const checkData = {
    receipt: useSelector((state) => state.actions.receiptDataPrint),
  };
  const showModalPrintReceipt = useSelector(
    (state) => state.actions.showModalPrintReceipt
  );
  const isShowLoader = useSelector(
    (state) => state.actions.isShowLoader,
    shallowEqual
  );
  const [printHeigth, setPrintHeight] = useState(0);
  const hideDoneCheckData = (event) => {
    dispatch({ type: SET_RECEIPT_COPY_HIDE });
    //dispatch(getCheckData());
    setPrintHeight(0);
    
  };
  const printRef = useRef();

  let receiptPaid = false,
    receiptFiscalized = false;

  if (checkData && checkData.receipt) {
    receiptPaid = checkData.receipt.paid;
    receiptFiscalized = checkData.receipt.fiscalized;
  }

  const modalHeight = useRef(0);
  useEffect(() => {
    if (modalHeight.current) {
      setPrintHeight(
        modalHeight ? (modalHeight.current.clientHeight * 265) / 1000 : '0'
      );
    }
    //dispatch(get))
  });
  const pageStyle = `
@media print {
  @page {
    
    margin: 0;
  }

}
`;
  console.log('checkData', checkData);
  console.log('showModalPrintReceipt', showModalPrintReceipt);
  return (
    <>
      {showModalPrintReceipt && checkData.receipt && (
        <Modal show={true} onHide={hideDoneCheckData} size='sm'>
          <Row
            id={'productRaw_header'}
            key={'header'}
            className='pt-2 justify-content-center'
          >
            <ReactToPrint
              trigger={() => (
                <Button className='col-6' variant='secondary' size='sm'>
                  Надрукувати чек
                </Button>
              )}
              content={() => printRef.current}
              pageStyle={pageStyle}
            />
            <CloseButton
              className={classes.close}
              onClick={hideDoneCheckData}
            />
          </Row>
          <div ref={printRef}>
            <div ref={modalHeight}>
              <Modal.Header className='text-center flex-wrap ps-0 pe-0'>
                {/* {console.log('checkData.receipt', checkData.receipt)} */}
                {checkData.receipt &&
                  checkData.receipt.shop &&
                  checkData.receipt.shop.printDetails &&
                  checkData.receipt.shop.printDetails.map((pValue) => {
                    return <p className={classes.header_info}>{pValue}</p>;
                  })}
                {checkData.receipt && checkData.receipt.isReturn && (
                  <>
                    <p className={classes.header_info}>{''}</p>
                    <p className={classes.header_info}>{'ЧЕК ПОВЕРНЕННЯ'}</p>
                  </>
                )}
                <p className={classes.header_info}>{'КОПІЯ'}</p>
              </Modal.Header>
              <Modal.Body className={classes.body}>
                <Container>
                  {checkData.receipt &&
                    checkData.receipt.id > 0 &&
                    Object.keys(checkData).length > 0 &&
                    Object.keys(checkData.receipt.products).map((inx) => {
                      return (
                        <Row
                          id={`productRaw_${inx}`}
                          key={inx}
                          className='mb-2 align-items-end'
                        >
                          <Col xs='8'>
                            <p className='mb-0'>
                              {checkData.receipt.products[inx].count}{' '}
                              <span>&nbsp;&nbsp;</span>x
                              <span>&nbsp;&nbsp;</span>
                              {/* грн./ */}
                              {/* {checkData.receipt.products[inx].unit} {' '} */}
                              {formatMoney(
                                checkData.receipt.products[inx].price
                              )}{' '}
                            </p>
                            <p className='mb-0'>
                              {checkData.receipt.products[inx].name}
                            </p>
                            {/* CHECKOUT DISCOUNT !!!! */}
                            {checkData.receipt.products[inx].discount !== 0 && (
                              <p className='mb-0'>
                                {checkData.receipt.products[inx].discount < 0
                                  ? 'Надб.'
                                  : 'Знижка'}
                              </p>
                            )}
                          </Col>
                          <Col className='text-end' xs='4'>
                            <p className='mb-0'>
                              <span>&nbsp;</span>
                            </p>
                            <p className='mb-0'>
                              <span>&nbsp;</span>
                              {formatMoney(checkData.receipt.products[inx].sum)}
                            </p>
                            {/* CHECKOUT DISCOUNT !!!! */}
                            {checkData.receipt.products[inx].discount !== 0 && (
                              <p className='mb-0'>
                                {' '}
                                {(-checkData.receipt.products[inx]
                                  .discount).toFixed(2)}
                              </p>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                </Container>
              </Modal.Body>
              {checkData.receipt && Object.keys(checkData).length > 0 && (
                <Modal.Footer className='ps-0 pe-0'>
                  <Container>
                    <Row>
                      <Col className='fw-bold fs-3' xs='6'>
                        Сума, грн.
                      </Col>
                      <Col className='text-end fw-bold fs-2' xs='6'>
                        {formatMoney(checkData.receipt.sumPayment.total)}
                      </Col>
                    </Row>
                    {checkData.receipt.sumPayment.nonCash > 0 && (
                      <Row className='fw-bold'>
                        <Col xs='5'>Картка</Col>
                        <Col className='text-end' xs='7'>
                          {formatMoney(checkData.receipt.sumPayment.nonCash)}
                        </Col>
                      </Row>
                    )}
                    {checkData.receipt.sumPayment.cashReceived > 0 && (
                      <Row className='fw-bold'>
                        <Col xs='5'>Готівка</Col>
                        <Col className='text-end' xs='7'>
                          {formatMoney(
                            checkData.receipt.sumPayment.cashReceived
                          )}
                        </Col>
                      </Row>
                    )}
                    {/* CHECKOUT DISCOUNT !!!! */}
                    {checkData.receipt.sumTotalDiscount !== 0 && (
                      <Row className='fw-bold'>
                        <Col xs='5'>
                          {checkData.receipt.sumTotalDiscount < 0
                            ? 'Надб.'
                            : 'Знижка'}
                        </Col>
                        <Col className='text-end' xs='7'>
                          {formatMoney(
                            checkData.receipt.sumTotalDiscount < 0
                              ? -checkData.receipt.sumTotalDiscount
                              : checkData.receipt.sumTotalDiscount
                          )}
                        </Col>
                      </Row>
                    )}
                    {checkData.receipt.sumPayment.change > 0 && (
                      <Row className='fw-bold'>
                        <Col xs='5'>Решта</Col>
                        <Col className='text-end' xs='7'>
                          {formatMoney(checkData.receipt.sumPayment.change)}
                        </Col>
                      </Row>
                    )}
                  </Container>
                  <Container>
                    <Row className={classes.small_text}>
                      <Col xs='5'>Дата та час</Col>
                      <Col className='text-end' xs='7'>
                        {formatDate(checkData.receipt.date)}
                      </Col>
                    </Row>
                    {/* <Row className={classes.small_text}>
                    <Col xs='3'>Чек №</Col>
                    <Col className='text-end' xs='9'>
                      {checkData.receipt.id}
                    </Col>
                  </Row> */}
                    <Row className={classes.small_text}>
                      <Col xs='3'>ФН</Col>
                      <Col className='text-end' xs='9'>
                        {checkData.receipt.fiscalNumber}
                      </Col>
                    </Row>
                    <Row className={classes.small_text}>
                      <Col xs='3'>ФР</Col>
                      <Col className='text-end' xs='9'>
                        {checkData.receipt.frNumber}
                      </Col>
                    </Row>
                    <Row>
                      <Col className='fw-bold text-center' xs='12'>
                        Фіскальний чек
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col className='text-center'>
                        <QRCode
                          value={getFiscalReceiptLink(
                            checkData.receipt.fiscalNumber,
                            checkData.receipt.frNumber,
                            checkData.receipt.date
                          )}
                          size={164}
                        />
                      </Col>
                    </Row>
                    {/* <img
                  className='w-100'
                  src='https://qrkodgenerator.ru/default.png'
                /> */}
                  </Container>
                </Modal.Footer>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ReceiptPrint;
