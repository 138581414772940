import { React, useState } from 'react';
import { returnReceipt, setReceiptPrintCopy } from '../../actions/checks';
import classes from './nav_receiptlist.module.css';
import {
  Button,
  Modal,
  Table,
  Accordion,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import ChecklistIcon from '../../images/cash-icon.svg';
import Loader from '../../images/loader.gif';
import SearchIcon from '../../images/search-icon.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useCashList } from './hooks/cash_list_hooks';
import {
  formatDate,
  formatMoney,
  cashInOutTypes,
} from '../../helpers/dataFormatter';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

const Cashlist = (props) => {
  const dispatch = useDispatch();
  const [checkedList, setCheckedList] = useState({ id: null, products: [] });
  const {
    delay,
    triggerSearchByDate,
    modalShow,
    cashHistory,
    showLoader,
    startDate,
    endDate,
    setModalShow,
  } = useCashList();
  console.log('Cashlist->props', props);
  return (
    <div>
      <Button
        className={`me-md-2 ${classes.button}`}
        size='sm'
        onClick={(e) => {
          e.target.parentElement.focus();
          e.target.parentElement.blur();
          setModalShow(true)
        }}
      >
        <img className={classes.nav_icon} src={ChecklistIcon} alt='checklist' />
      </Button>
      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size='lg'
          centered
          scrollable={true}
        >
          {showLoader === true && (
            <img
              className='position-absolute top-50 start-50 translate-middle'
              src={Loader}
              style={{ width: '100px' }}
              alt=''
            />
          )}
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Кошти в касі
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={classes.modal_body}>
            <Container>
              <Row>
                <Col>
                  <DatePicker
                    className='form-control'
                    dateFormat='dd.MM.yyyy'
                    selected={startDate}
                    onChange={(date) => {
                      triggerSearchByDate(date, endDate);
                    }}
                  />
                </Col>
                <Col>
                  <DatePicker
                    className='form-control'
                    dateFormat='dd.MM.yyyy'
                    selected={endDate}
                    onChange={(date) => {
                      triggerSearchByDate(startDate, date);
                    }}
                  />
                </Col>
              </Row>
            </Container>
            <Accordion className='mt-1 container' flush>
              {cashHistory && Object.keys(cashHistory).length > 0 && (
                <Accordion.Item eventKey={'history-total'}>
                  {/* <Accordion.Header className={classes.header} as='div'> */}
                  {'     '}Готівки в касі: <b>{formatMoney(cashHistory.currentCash)}</b> грн
                  {`${props.checkData?.cashierWorkplace?.enableTestReceipt ? ', тест: ' + formatMoney(cashHistory.currentCash) + ' грн' : ''}`}
                  {/* </Accordion.Header> */}
                </Accordion.Item>
              )}

              {cashHistory &&
                Object.keys(cashHistory).length > 0 &&
                cashHistory.cashData.length > 0 && (
                  //const item = checkDataHistory.receipts[itm];

                  // return (
                  <Table className={classes.table} size='sm'>
                    <thead>
                      <tr key={777}>
                        <th className='col-6'> Дата</th>
                        <th className='text-nowrap'>Операція</th>
                        <th>Сума, грн</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cashHistory.cashData.map((cashItem) => {
                        if (cashItem.testReceipt === 0 || props.checkData?.cashierWorkplace?.enableTestReceipt > 0) {
                          return (
                            <tr key={cashItem.id}>
                              <td className={classes.td}>
                                {' '}
                                {formatDate(cashItem.date)}
                              </td>
                              <td className={classes.td}>
                                {`${cashInOutTypes[cashItem.type]}${cashItem.testReceipt > 0 ? ', тест' : ''}`}
                              </td>
                              <td className={classes.price}>
                                {formatMoney(cashItem.sum)}
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                )}
            </Accordion>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Cashlist;
