export const cashInOutTypes = {
  SALE: 'Продажа',
  REFUND: 'Повернення',
  CORRECT: 'Ручне коригування',
  IN: 'Внесення в касу',
  OUT: 'Вилучення з каси',
};

const numberLeadZero = (inputNumber, zCount = 2) => {
  return inputNumber.toString().padStart(zCount, '0');
};

export const formatCount = (sumValue) => {
  let resValue = sumValue;
  if (Number.isInteger(sumValue)) {
    resValue = sumValue;  
  } else
    try {
      resValue = sumValue.toFixed(3);
    } catch (error) { }
  return resValue;
};

export const formatMoney = (sumValue) => {
  let resValue = sumValue;
  try {
    resValue = sumValue.toFixed(2);
  } catch (error) { }
  return resValue;
};

export const formatDate = (dateValue) => {
  let resValue = dateValue;
  try {
    const dateObj = new Date(dateValue);
    resValue =
      numberLeadZero(dateObj.getDate()) +
      '.' +
      numberLeadZero(dateObj.getMonth() + 1) +
      '.' +
      dateObj.getFullYear().toString() +
      ' ' +
      numberLeadZero(dateObj.getHours()) +
      ':' +
      numberLeadZero(dateObj.getMinutes()) +
      ':' +
      numberLeadZero(dateObj.getSeconds());
  } catch (error) {
    console.log('formatError', error);
  }
  return resValue;
};

export const formatFiscalReceiptDate = (dateValue) => {
  let resValue = dateValue;
  try {
    const dateObj = new Date(dateValue);
    resValue =
      dateObj.getFullYear().toString() +
      numberLeadZero(dateObj.getMonth() + 1) +
      numberLeadZero(dateObj.getDate());
  } catch (error) {
    console.log('formatError', error);
  }
  return resValue;
};

export const getFiscalReceiptLink = (fiscalNumber, frNumber, date) => {
  return `https://cabinet.tax.gov.ua/cashregs/check?id=${fiscalNumber}&fn=${frNumber}&date=${formatFiscalReceiptDate(
    date
  )}`;
};
