import React, { useState, useEffect } from 'react';
import classes from './nav_discount.module.css';
import { Button } from 'react-bootstrap';
import ModalPrices from '../modals/modal_prices';

import { DISPLAY_MODAL, HIDE_MODAL } from '../../actions/checks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PricesIcon from '../../images/price-tag.svg';

function NavPrices() {
  const dispatch = useDispatch();
  const checkData = useSelector(
    (state) => state.actions.checkData,
    shallowEqual
  );
  const [discountModalParams, setDiscountModalParams] = useState({
    isActive: false,
    productId: null,
    sum: 0,
  });
  const hideModalHandler = () => {
    setDiscountModalParams({
      isActive: false,
    });
  };
  return (
    <>
      <Button
        className={`me-md-2 ${classes.button}`}
        size='sm'
        onClick={(e) => {
          e.target.parentElement.focus();
          e.target.parentElement.blur(); 
          setDiscountModalParams({
            isActive: true,
            productId: null,
            sum: checkData && checkData.receipt ? checkData.receipt.sumTotalWithoutDiscount : 0,
          });
          dispatch({ type: DISPLAY_MODAL });
        }}
      >
        <img className={classes.nav_icon} src={PricesIcon} alt='' />
      </Button>
      {discountModalParams.isActive && (
        <ModalPrices
          show={discountModalParams.isActive}
          onHide={() => {
            hideModalHandler();
            dispatch({ type: HIDE_MODAL });
          }}
          onConfirm={hideModalHandler}
          onCancel={hideModalHandler}
          centered
        />
      )}
    </>
  );
}

export default NavPrices;
