import { React, useState, useEffect, useRef } from 'react';
import classes from './modal_prices.module.css';
import {
  Button,
  Modal,
  Table,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
  Form
} from 'react-bootstrap';

import PlusIcon from '../../images/plus-icon.svg';
import SearchIcon from '../../images/search-icon.svg';
import FolderIcon from '../../images/folder-icon.svg';
import BackIcon from '../../images/back-icon.svg';
import { DISPLAY_MODAL, HIDE_MODAL } from '../../actions/checks';
import { useAddGoods } from '..//Header/hooks/add_goods_hooks';
import { usePrintLabels } from './hooks/usePrintLabels';
import Loading from '../Loading';
import { formatDate, formatMoney } from '../../helpers/dataFormatter';
import DatePicker from 'react-datepicker';

function ModalPrices(props) {
  const {
    handleAddProductToCheck,
    handleCategory,
    modalShow,
    setModalShow,
    productsList,
    showLoader,
    hideAnyModal,
    dispatch,
    trigSearch,
    delay,
    setSearchByBarcode,
    noTreeView,
    setNoTreeView
  } = useAddGoods(false);

  const [productIds, isLoading, addSetProductIds, printProductLabels] = usePrintLabels();

  const [priceDate, setPriceDate] = useState(() => {
    let currentDate = new Date();
    currentDate.setUTCDate(currentDate.getUTCDate() - 1);
    return currentDate;
  });
  const [currentCategory, setCurrentCategory] = useState(null);
  const [productsNoPriceDateFilter, setProductsNoPriceDateFilter] = useState(false);
  const refSearchField = useRef('');

  useEffect(() => {
    setSearchByBarcode(true);
    setModalShow(true);
    handleCategory(currentCategory, priceDate);
  }, []);

  const handleDisableDataFilter = (disableValue) => {
    setProductsNoPriceDateFilter(disableValue);
    setCurrentCategory(null);
    handleCategory(null,
      disableValue ? null : priceDate,
      refSearchField.current.value ? refSearchField.current.value : null);
  }

  const handleNoTreeView = (disableValue) => {
    console.log('noTreeView->disableValue', disableValue);
    setNoTreeView(disableValue);
    setCurrentCategory(null);
    handleCategory(null,
      productsNoPriceDateFilter ? null : priceDate,
      refSearchField.current.value ? refSearchField.current.value : null, disableValue);
    // let searchFn = delay(handleCategory, 1000);
    // searchFn(null,
    //   productsNoPriceDateFilter ? null : priceDate,
    //   refSearchField.current.value ? refSearchField.current.value : null, disableValue);
  }

  const handelCheckAllProducts = (checkedValue) => {
    let newProductsIds = [];
    if (checkedValue) { newProductsIds = productsList.products.map((elem => elem.id)) }
    addSetProductIds(newProductsIds, 'set');
  }

  const handelCheckProduct = (productId, checkedValue) => {
    addSetProductIds(productId, checkedValue ? 'add' : 'del');
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
      aria-labelledby='good-modal-btn'
      centered
      scrollable={true}
      contentClassName={classes.modal_content}
    >
      <Modal.Header closeButton>
        <Container>
          <Row className={classes.rowHeader}>
            <Col className={classes.colDatePicker}>
              <DatePicker
                className='form-control'
                // className={classes.inputField}
                dateFormat='dd.MM.yyyy'
                selected={priceDate}
                onChange={(date) => {
                  setPriceDate(date);
                  handleCategory(noTreeView ? null : currentCategory,
                    productsNoPriceDateFilter ? null : date,
                    refSearchField.current.value ? refSearchField.current.value : null);
                }}
              />
            </Col>
            <Col className={classes.colCheckBoxShowAllProducts}>
              <Form.Check
                type={'checkbox'}
                id={`checkbox-show-all-products`}
                label={`Без фільтра по даті`}
                onChange={(e) => {
                  console.log('checkbox-no-pricedate-filter', e.target.checked)
                  handleDisableDataFilter(e.target.checked);
                }}
              />
            </Col>
          </Row>
          <Row className={classes.rowHeader}>
            <Col className={classes.colSearchInput}>
              <FormControl
                ref={refSearchField}
                //onChange={delay.bind(this, priceDate)(trigSearch, 500)}
                onChange={(e) => {
                  let searchFn = delay(handleCategory, 500);
                  addSetProductIds([], 'set');
                  //searchFn(e, productsNoPriceDateFilter ? null : priceDate);
                  console.log('e.target.value', e.target.value);
                  searchFn(noTreeView ? null : currentCategory,
                    productsNoPriceDateFilter ? null : priceDate,
                    e.target.value ? e.target.value : null)
                }}
                className={classes.inputField}
                placeholder='Штрихкод \ Код'
                // type='number'
                type='text'
                min='0'
                // inputMode='numeric'
                autoComplete='off'
                onFocus={(e) => {
                  e.target.select();
                }}
              />
            </Col>
            <Col className={classes.colCheckBoxShowAllProducts}>
              <Form.Check
                type={'checkbox'}
                id={`checkbox-show-without-tree`}
                label={`Без ієрархії`}
                onChange={(e) => {
                  handleNoTreeView(e.target.checked);
                  console.log('checkbox-show-without-tree', e.target.checked);
                }}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        {/* <Loading /> uncommit  */}
        {console.log('show Loader ', showLoader)}
        {console.log('show Loader products', productsList)}
        {showLoader === false &&
          Object.keys(productsList.currentCategory).length > 0 && (
            <div
              className={classes.back}
              onClick={(e) => {
                //handleCategory(productsList.parentCategory.id, priceDate);
                handleCategory(noTreeView ? null : productsList.parentCategory.id,
                  productsNoPriceDateFilter ? null : priceDate,
                  refSearchField.current.value ? refSearchField.current.value : null);
                setCurrentCategory(
                  Object.keys(productsList.parentCategory).length > 0
                    ? productsList.parentCategory.id
                    : null
                );
              }}
            >
              <img className={classes.back_icon} src={BackIcon} alt='' />{' '}
              {productsList.currentCategory.name}
            </div>
          )}

        <ul className={classes.category_list}>
          {showLoader === false &&
            Object.keys(productsList).length > 0 &&
            productsList.categories.map((categoryCur) => {
              return (
                <li
                  className={classes.category}
                  onClick={(e) => {
                    //handleCategory(categoryCur.id, priceDate);
                    handleCategory(noTreeView ? null : categoryCur.id,
                      productsNoPriceDateFilter ? null : priceDate,
                      refSearchField.current.value ? refSearchField.current.value : null);
                    setCurrentCategory(categoryCur.id);
                  }}
                >
                  <img
                    className={classes.category_icon}
                    src={FolderIcon}
                    alt=''
                  />
                  {categoryCur.name}
                </li>
              );
            })}
        </ul>

        {showLoader === false &&
          Object.keys(productsList).length > 0 &&
          productsList.products.length > 0 && (
            <Table
              className={
                showLoader === false &&
                  Object.keys(productsList.categories).length === 0
                  ? ''
                  : 'd-none'
              }
              hover
            >
              <thead className={classes.table_head}>
                <tr>
                  <th>
                    <Form.Check
                      // inline
                      // label='1'
                      name='group1'
                      type={'checkbox'}
                      id={`chb_all`}
                      onChange={(e) => {
                        handelCheckAllProducts(e.target.checked);
                      }}
                    />
                  </th>
                  <th>Код</th>
                  <th>Назва</th>
                  <th className='text-end'>Ціна, грн.</th>
                </tr>
              </thead>
              <tbody className={classes.table_body}>
                {productsList.products.map((productCur) => {
                  //const productCur = productsList.products[key];
                  return (
                    <tr
                      //onClick={(e) => handleAddProductToCheck(productCur.id)}
                      className={classes.tr}
                    >
                      <td>
                        <Form.Check
                          // inline
                          // label='1'
                          name='group1'
                          type={'checkbox'}
                          id={`chb_${productCur.id}`}
                          checked={productIds.includes(productCur.id)}
                          onChange={(e) => {
                            handelCheckProduct(productCur.id, !productIds.includes(productCur.id));
                          }}
                        />
                      </td>
                      <td>{productCur.weightCode}</td>
                      <td>{productCur.name}</td>
                      <td className='col-3 text-end'>
                        {formatMoney(productCur.price)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        {showLoader === false &&
          Object.keys(productsList.currentCategory).length > 0 && (
            <div
              className={classes.back}
              onClick={(e) => {
                //handleCategory(productsList.parentCategory.id, priceDate);
                handleCategory(noTreeView ? null : productsList.parentCategory.id,
                  productsNoPriceDateFilter ? null : priceDate,
                  refSearchField.current.value ? refSearchField.current.value : null);
                setCurrentCategory(
                  Object.keys(productsList.parentCategory).length > 0
                    ? productsList.parentCategory.id
                    : null
                );
              }}
            >
              <img className={classes.back_icon} src={BackIcon} alt='' />{' '}
              {productsList.currentCategory.name}
            </div>
          )}
        {/* <ul className={classes.product_list}>
            {showLoader === false &&
              Object.keys(productsList).length > 0 &&
              Object.keys(productsList.products).map((key) => {
                const productCur = productsList.products[key];
                return (
                  <li
                    onClick={(e) => handleAddProductToCheck(productCur.barcode)}
                  >
                    {productCur.name} - {productCur.price}
                  </li>
                );
              })}
          </ul> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            printProductLabels();
            //putCash();
            //closeModal();
          }}
          variant='success'
          disabled={!(productIds && productIds.length > 0)}
        >
          {'Надрукувати'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalPrices;
