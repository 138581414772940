import {
  SET_CHECK_DATA,
  DISPLAY_MODAL,
  HIDE_MODAL,
  SET_CHECK_DONE_DATA,
  HIDE_DONE_CHECK,
  SHOW_LOADER_ON_CHECK,
  SET_SYST_ERROR,
  REMOVE_ERROR,
  SET_RECEIPT_COPY,
  SET_RECEIPT_COPY_HIDE,
} from '../actions/checks';

const initialState = {
  isShowLoader: true,
  checkData: {},
  hideAnyModal: false,
  showDialogWithCheck: false,
  sysAPIerror: '',
  showModalPrintReceipt: false,
  receiptDataPrint: {},
};

export default function checkActions(state = initialState, action) {
  switch (action.type) {
    case SET_CHECK_DATA:
      return {
        ...state,
        isShowLoader: false,
        checkData: action.data,
        hideAnyModal: true,
      };
      break;
    case DISPLAY_MODAL:
      return {
        ...state,
        hideAnyModal: false,
      };
      break;
    case HIDE_MODAL:
      return {
        ...state,
        hideAnyModal: true,
      };
      break;
    case SET_CHECK_DONE_DATA:
      return {
        ...state,
        checkData: action.data,
        isShowLoader: false,
        showDialogWithCheck: true,
      };
      break;
    case HIDE_DONE_CHECK:
      return {
        ...state,
        showDialogWithCheck: false,
      };
      break;
    case SHOW_LOADER_ON_CHECK:
      return {
        ...state,
        isShowLoader: true,
      };
      break;
    case SET_SYST_ERROR:
      return {
        ...state,
        hideAnyModal: true,
        sysAPIerror: action.data,
        isShowLoader: false,
      };
      break;
    case REMOVE_ERROR:
      return {
        ...state,
        sysAPIerror: initialState.sysAPIerror,
        isShowLoader: false,
      };
    case SET_RECEIPT_COPY:
      return {
        ...state,
        showModalPrintReceipt: true,
        receiptDataPrint: action.data,
      };
    case SET_RECEIPT_COPY_HIDE:
      return {
        ...state,
        showModalPrintReceipt: false,
        receiptDataPrint: {},
      };
    default:
      return state;
  }
}
