// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../indexVars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_cash_in_out_btn__kLUIn{\n    width: auto;\n    font-size: var(--font-size-fs12);\n    padding: 5px 10px;\n}\n@media(min-width: 435px){\n    .modal_cash_in_out_btn__kLUIn{\n        width: 100%;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/modals/modal_cash_in_out.module.css"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,gCAAgC;IAChC,iBAAiB;AACrB;AACA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":["@import '../../indexVars.css';\n\n.btn{\n    width: auto;\n    font-size: var(--font-size-fs12);\n    padding: 5px 10px;\n}\n@media(min-width: 435px){\n    .btn{\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "modal_cash_in_out_btn__kLUIn"
};
export default ___CSS_LOADER_EXPORT___;
