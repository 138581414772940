import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

const PrivateEmtpy = () => <div>"loading users data..."</div>;

export const PrivateRoute = ({ children }) => {
  const isLogged = useSelector((state) => state.users.isLogged, shallowEqual);
  if (isLogged === true) {
    return children;
  } else if (isLogged === false) {
    return <Navigate to={'/'} />;
  } else {
    return <PrivateEmtpy />;
  }
};

export const PublicRoute = ({ children }) => {
  const isLogged = useSelector((state) => state.users.isLogged, shallowEqual);
  const isAdmin = useSelector((state) => state.users.isAdmin);
  console.log('is logged ', isLogged);
  if (isLogged === false) {
    return children;
  } else if (isLogged === true && isAdmin === true) {
    return <Navigate to={'/armadmin'} />;
  } else if (isLogged === true) {
    return <Navigate to={'/check'} />;
  } else {
    return <PrivateEmtpy />;
  }
};
