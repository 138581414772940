import React, { useState } from 'react';
import { Alert, Button, Col } from 'react-bootstrap';
import ModalCancelReturn from '../modals/modal_cancel_return';
import { useDispatch } from 'react-redux';
import { cancelReceiptReturn } from '../../actions/checks';

function ReturnInfo() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Col>
        <Alert className='p-1 d-flex justify-content-between' variant='danger'>
          Чек повернення
          <Button
            onClick={() => setShowModal(true)}
            className='btn-close'
            variant='light'
          ></Button>
        </Alert>
      </Col>
      <ModalCancelReturn
        show={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={() => {
          dispatch(cancelReceiptReturn());
          setShowModal(false);
        }}
        centered
      />
    </>
  );
}

export default ReturnInfo;
