import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetcher } from '../../../helpers/fetcher';
import { SET_CHECK_DATA, SET_SYST_ERROR } from '../../../actions/checks';
import { configApiLinks } from '../../../configApiLinks';

export const useFooterMaking = (isAdd = true, correctSum = false) => {
  const [puttedCash, setPuttedCash] = useState(0);
  const [fiscalRegistrarId, setFiscalRegistrarId] = useState(0);
  const dispatch = useDispatch();
  const hideAnyModal = useSelector((state) => state.actions.hideAnyModal);
  const [modalShow, setModalShow] = useState(false);
  const putCash = (isTestReceipt = false) => {
    fetcher(
      isAdd ? configApiLinks.addCashLink : configApiLinks.removeCashLink,
      'POST',
      { cash: puttedCash, fiscalRegistrarId, correctSum, testReceipt: isTestReceipt }
    )
      .then((data) => {
        dispatch({ type: SET_CHECK_DATA, data });
      })
      .catch((error) => {
        dispatch({ type: SET_SYST_ERROR, error });
      });
  };

  useEffect(() => {
    if (hideAnyModal === true) {
      setModalShow(false);
    }
  }, [hideAnyModal]);

  return {
    puttedCash,
    setPuttedCash,
    fiscalRegistrarId,
    setFiscalRegistrarId,
    putCash,
    modalShow,
    setModalShow,
    dispatch,
  };
};
