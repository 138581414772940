import { createStore, combineReducers , applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import users from "../reducers/users";
import checkActions from "../reducers/check";

const middleware = [ thunkMiddleware ];

export default function configureStore(preloadedState) {
    return createStore(
        combineReducers({
            users:users,
            actions: checkActions
        }),
        applyMiddleware(
            ...middleware
        ),
    )
}