import React from "react";
import Loader from "../images/loader.gif";

function Loading() {
  return (
    <>
      <img
        className="position-absolute top-50 start-50 translate-middle"
        src={Loader}
        style={{ width: "100px" }}
        alt=""
      />
    </>
  );
}

export default Loading;
